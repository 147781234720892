import { Component } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { ToastService } from './toast.service';

@Component({
  selector: 'cloud-cost-calculation-tool-story-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  private toastSubscription: Subscription;
  private clearSubscription: Subscription;

  constructor(private toastService: ToastService, private messageService: MessageService) {
    this.toastSubscription = this.toastService.toast.subscribe((toastMessage: Message) => {
      this.messageService.add(toastMessage);
    });
    this.clearSubscription = this.toastService.close.subscribe(() => {
      this.messageService.clear();
    });
  }

  ngOnDestroy(): void {
    this.toastSubscription.unsubscribe();
    this.clearSubscription.unsubscribe();
    this.messageService.clear();
  }
}
