import { DEFAULT_CLOUD_SERVICES_PROVIDERS, ICloudServiceInjection } from '@cloud-cost-calculation-tool/base-cloud-services';

import {
  ASSET_HUB_CONFIGURATION,
  D_STUDIO_CONFIGURATION,
  DYNAMIC_DATA_DISPLAY_CONFIGURATION,
  LIVE_CONNECT_CONFIGURATION,
  MONITORING_BOX_CONFIGURATION,
} from './service-configurations';
import {
  AppPoolService,
  AssetHubService,
  DStudioService,
  DynamicDataDisplayService,
  LiveConnectService,
  MonitoringBoxService,
  RoleManagementService,
} from './services';
import { SICK_BASIC_REGIONS } from './types';

const provider = DEFAULT_CLOUD_SERVICES_PROVIDERS['SICKCOM'];

export const APP_POOL: ICloudServiceInjection = {
  defaultConfig: {
    name: 'App Pool',
    id: 'app-pool',
    description: 'AppPool is a repository for applications for sensor devices.',
    provider,
    sections: [],
    regions: SICK_BASIC_REGIONS,
  },
  cloudService: AppPoolService,
};

export const ASSET_HUB: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Asset Hub',
    id: 'asset-hub',
    description: 'AssetHub is a repository for assets for sensor devices.',
    provider,
    sections: ASSET_HUB_CONFIGURATION,
    regions: SICK_BASIC_REGIONS,
  },
  cloudService: AssetHubService,
};

export const D_STUDIO: ICloudServiceInjection = {
  defaultConfig: {
    name: 'D-Studio',
    id: 'd-studio',
    description: 'D-Studio is a development environment for sensor devices.',
    provider,
    sections: D_STUDIO_CONFIGURATION,
    regions: SICK_BASIC_REGIONS,
  },
  cloudService: DStudioService,
};

export const LIVE_CONNECT: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Live Connect',
    id: 'live-connect',
    description: 'Edge to Cloud Communication System for SICK Sensors',
    provider,
    sections: LIVE_CONNECT_CONFIGURATION,
    regions: SICK_BASIC_REGIONS,
  },
  cloudService: LiveConnectService,
};

export const MONITORING_BOX: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Monitoring Box',
    id: 'monitoring-box',
    description: 'Monitoring Sensor Data',
    provider,
    sections: MONITORING_BOX_CONFIGURATION,
    regions: SICK_BASIC_REGIONS,
  },
  cloudService: MonitoringBoxService,
};

export const ROLE_MANAGEMENT: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Role Management',
    id: 'role-management',
    description: 'Role Management for SICK Software Solutions',
    provider,
    sections: [],
    regions: SICK_BASIC_REGIONS,
  },
  cloudService: RoleManagementService,
};

export const DYNAMIC_DATA_DISPLAY: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Dynamic Data Display',
    id: 'sd3',
    description: 'Dashboard for Sensor Monitoring and Data Analysis',
    provider,
    sections: DYNAMIC_DATA_DISPLAY_CONFIGURATION,
    regions: SICK_BASIC_REGIONS,
  },
  cloudService: DynamicDataDisplayService,
};
