import { Component } from '@angular/core';
import { INotificationAlarm, IProjectTopic, ProjectNotificationService } from '@cloud-cost-calculation-tool/project-analysis';
import { OAuthService } from 'angular-oauth2-oidc';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { v4 } from 'uuid';

@Component({
  selector: 'cloud-cost-calculation-tool-manage-notification-alarm',
  templateUrl: './manage-notification-alarm.component.html',
  styleUrls: ['./manage-notification-alarm.component.scss'],
})
export class ManageNotificationAlarmComponent {
  notificationAlarm: INotificationAlarm;
  topics: Array<IProjectTopic> = [];
  userMail: string;
  newMail = '';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private projectNotificationService: ProjectNotificationService,
    private authService: OAuthService,
  ) {
    this.topics = this.config.data.topics;
    this.notificationAlarm = config.data.notificationAlarm
      ? config.data.notificationAlarm
      : {
          id: v4(),
          name: '',
          topic: undefined,
          alarm: {
            id: v4(),
            belowActivated: false,
            belowThreshold: 0,
            aboveActivated: false,
            aboveThreshold: 0,
            daysRange: 10,
          },
          subscribers: [],
        };
    const user: { email?: string } = this.authService.getIdentityClaims();
    this.userMail = user.email ? user.email : '';
  }

  cancel(): void {
    this.ref.close(false);
  }

  addAlarm(): void {
    this.projectNotificationService.addOrUpdateNotification(this.notificationAlarm);
    this.ref.close(true);
  }

  inputValid(): boolean {
    return (
      (this.notificationAlarm.alarm.belowActivated || this.notificationAlarm.alarm.aboveActivated) &&
      this.notificationAlarm.topic &&
      this.notificationAlarm.name !== ''
    );
  }

  changeNickname(event: Event): void {
    this.notificationAlarm.name = (event as CustomEvent).detail;
  }

  changeAboveActivation(event: Event): void {
    this.notificationAlarm.alarm.aboveActivated = (event as CustomEvent).detail;
  }

  changeAboveThreshold(event: Event): void {
    this.notificationAlarm.alarm.aboveThreshold = (event as CustomEvent).detail;
  }

  changeBelowActivation(event: Event): void {
    this.notificationAlarm.alarm.belowActivated = (event as CustomEvent).detail;
  }

  changeBelowThreshold(event: Event): void {
    this.notificationAlarm.alarm.belowThreshold = (event as CustomEvent).detail;
  }

  changeDaysRange(event: Event): void {
    this.notificationAlarm.alarm.daysRange = (event as CustomEvent).detail;
  }

  changeTopic(event: Event): void {
    const topic = this.topics.find((topic: IProjectTopic) => topic.id === (event as CustomEvent).detail);
    if (topic) {
      this.notificationAlarm.topic = topic;
    }
  }

  updatedMailName(event: Event): void {
    this.newMail = (event as CustomEvent).detail;
  }

  removeSubscriber(mail: string): void {
    const index = this.notificationAlarm.subscribers.indexOf(mail);
    if (index > -1) {
      this.notificationAlarm.subscribers.splice(index, 1);
    }
  }

  addSubscriber(): void {
    if (this.notificationAlarm.subscribers.indexOf(this.newMail) === -1) {
      this.notificationAlarm.subscribers.push(this.newMail);
      this.newMail = '';
    }
  }
}
