import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';

export class SimpleEmailServiceService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AmazonSES';
    return {
      recipientEc2Values: {
        awsServiceCode: serviceCode,
        filter: [{ Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Recipients-EC2' }],
      },
      recipientClient2Values: {
        awsServiceCode: serviceCode,
        filter: [{ Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Recipients' }],
      },
      attachmentValues: {
        awsServiceCode: serviceCode,
        filter: [{ Field: 'usagetype', Type: 'TERM_MATCH', Value: 'AttachmentsSize-Bytes' }],
      },
      messagesReceivedValues: {
        awsServiceCode: serviceCode,
        filter: [{ Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Message' }],
      },
      messagesReceivedSizeValues: {
        awsServiceCode: serviceCode,
        filter: [{ Field: 'usagetype', Type: 'TERM_MATCH', Value: 'ReceivedChunk' }],
      },
      dedicatedIpValues: {
        awsServiceCode: serviceCode,
        filter: [{ Field: 'usagetype', Type: 'TERM_MATCH', Value: 'USE1-DIP-Hours' }],
      },
    };
  }

  protected async calculatePrices(): Promise<void> {
    const recipientEc2Price = this.calculatePrice('recipientEc2Values', this.retrieveRecipientEc2());
    const ec2AttachmentPrice = this.calculatePrice('attachmentValues', this.retrieveEc2Attachment());
    const recipientClient2Price = this.calculatePrice('recipientClient2Values', this.retrieveRecipientClient());
    const clientAttachmentPrice = this.calculatePrice('attachmentValues', this.retrieveClientAttachment());
    const messagesReceivedPrice = this.calculatePrice('messagesReceivedValues', this.retrieveMessagesReceived());
    const messagesReceivedSizePrice = this.calculatePrice('messagesReceivedSizeValues', this.retrieveMessagesReceivedSize());
    const dedicatedIpPrice = this.calculatePrice('dedicatedIpValues', this.retrieveDedicatedIp());

    this.updatePrices([
      recipientEc2Price +
        ec2AttachmentPrice +
        recipientClient2Price +
        clientAttachmentPrice +
        messagesReceivedPrice +
        messagesReceivedSizePrice +
        dedicatedIpPrice,
    ]);
    return;
  }

  private retrieveRecipientEc2(): number {
    return this.getSelected('service-settings', 'email-messages-sent-from-ec2') as number;
  }

  private retrieveEc2Attachment(): number {
    return this.getSelected('service-settings', 'data-sent') as number;
  }

  private retrieveRecipientClient(): number {
    return this.getSelected('service-settings', 'email-messages-sent-from-client') as number;
  }

  private retrieveClientAttachment(): number {
    return this.getSelected('service-settings', 'data-sent-from-email-client') as number;
  }

  private retrieveMessagesReceived(): number {
    return this.getSelected('service-settings', 'email-messages-received') as number;
  }

  private retrieveMessagesReceivedSize(): number {
    // value / 256 chunks (round Down!)
    const chunks = Math.floor((this.getSelected('service-settings', 'average-size') as number as number) / 256);
    // cuncks * messages
    return chunks * (this.getSelected('service-settings', 'email-messages-received') as number as number);
  }

  private retrieveDedicatedIp(): number {
    return this.getSelected('service-settings', 'dedicated-ip-addresses') as number as number;
  }
}
