<div class='notification-container'>
  <div class='notification-container__actions'>
    <davinci-button (click)='addEditAlarm()'>Create new alarm notification</davinci-button>
  </div>
  <div class='notification-container__content'>
    <div *ngFor='let notificationAlarm of notificationAlarms'>
      <cloud-cost-calculation-tool-notification-alarm-list-item
        [notificationAlarm]='notificationAlarm' (editAlarm)='addEditAlarm(notificationAlarm)'
        (deleteAlarm)='deleteAlarm(notificationAlarm)'></cloud-cost-calculation-tool-notification-alarm-list-item>
    </div>
  </div>
</div>
