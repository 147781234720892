import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';
import { AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER, inboundIds, outboundIds } from '../service-configurations';

export class SimpleNotificationServiceService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AmazonSNS';
    return {
      requests: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usageType', Type: 'TERM_MATCH', Value: 'Requests-Tier1' }],
      },
      https: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'DeliveryAttempts-HTTP' }],
      },
      email: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'DeliveryAttempts-SMTP' }],
      },
      sqs: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'DeliveryAttempts-SQS' }],
      },
      lambda: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'DeliveryAttempts-LAMBDA' }],
      },
      kinesis: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'DeliveryAttempts-FIREHOSE' }],
      },
      mobilePush: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'DeliveryAttempts-ADM' }],
      },
      ...AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER,
    };
  }

  protected async calculatePrices(): Promise<void> {
    const requestsPrice = this.calculatePrice('requests', this.retrieveRequests());
    const httpsPrice = this.calculatePrice('https', this.retrieveHttpHttpsNotifications());
    const emailPrice = this.calculatePrice('email', this.retrieveEmailJsonNotifications());
    const sqsPrice = this.calculatePrice('sqs', this.retrieveSqsNotifications());
    const lambdaPrice = this.calculatePrice('lambda', this.retrieveAwsLambda());
    const kinesisPrice = this.calculatePrice('kinesis', this.retrieveKinesisDataFirehose());
    const mobilePushPrice = this.calculatePrice('mobilePush', this.retrieveMobilePushNotifications());

    const dataTransferInPrice = this.calculatePrice('inboundTransfer', this.retrieveDataTransferIn());
    const dataTransferOutPrice = this.calculatePrice('outboundTransfer', this.retrieveDataTransferOut());

    this.updatePrices([
      requestsPrice + httpsPrice + emailPrice + sqsPrice + lambdaPrice + kinesisPrice,
      mobilePushPrice,
      dataTransferInPrice + dataTransferOutPrice,
    ]);
  }

  private retrieveRequests(): number {
    return this.getSelected('service-settings', 'requests') as number;
  }

  private retrieveHttpHttpsNotifications(): number {
    return this.getSelected('service-settings', 'http-https-notifications') as number;
  }

  private retrieveEmailJsonNotifications(): number {
    return this.getSelected('service-settings', 'email-json-notifications') as number;
  }

  private retrieveSqsNotifications(): number {
    return this.getSelected('service-settings', 'sqs-notifications') as number;
  }

  private retrieveAwsLambda(): number {
    return this.getSelected('service-settings', 'aws-lambda') as number;
  }

  private retrieveKinesisDataFirehose(): number {
    return this.getSelected('service-settings', 'kinesis-data-firehose') as number;
  }

  private retrieveMobilePushNotifications(): number {
    return this.getSelected('additional-notification-settings', 'mobile-push-notifications') as number;
  }

  private retrieveDataTransferIn(): number {
    return this.getSelected(inboundIds[0], inboundIds[1]) as number;
  }

  private retrieveDataTransferOut(): number {
    return this.getSelected(outboundIds[0], outboundIds[1]) as number;
  }
}
