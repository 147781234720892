import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INotificationAlarm } from '@cloud-cost-calculation-tool/project-analysis';

@Component({
  selector: 'cloud-cost-calculation-tool-notification-alarm-list-item',
  templateUrl: './notification-alarm-list-item.component.html',
  styleUrls: ['./notification-alarm-list-item.component.scss'],
})
export class NotificationAlarmListItemComponent {
  @Input() notificationAlarm!: INotificationAlarm;

  @Output()
  deleteAlarm = new EventEmitter<void>();

  @Output()
  editAlarm = new EventEmitter<void>();

  delete(): void {
    this.deleteAlarm.emit();
  }

  edit(): void {
    this.editAlarm.emit();
  }
}
