import { DEFAULT_CLOUD_SERVICES_PROVIDERS, ICloudServiceInjection } from '@cloud-cost-calculation-tool/base-cloud-services';

import { FUNCTIONS_CONFIGURATION } from './service-configurations';
// import { DEFAULT_DATABASE_CONFIGURATION } from './service-configurations/default-databa÷se.configuration';
import { FunctionsService } from './services';
import { AZURE_BASIC_REGIONS } from './types/basic-regions';

const provider = DEFAULT_CLOUD_SERVICES_PROVIDERS['AZURE'];

export const FUNCTIONS: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Functions',
    id: 'functions',
    description:
      'Azure Functions is a serverless compute service that enables you to run code on-demand without having to explicitly provision or manage infrastructure. Use Azure Functions to run a script or piece of code in response to a variety of events.',
    provider,
    sections: FUNCTIONS_CONFIGURATION,
    regions: AZURE_BASIC_REGIONS,
  },
  cloudService: FunctionsService,
};
