import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf } from '@angular/core';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AppInitializerService } from './services/app-initializer.service';
import { ToastModule } from './toast/toast.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ToastModule],
  exports: [ToastModule],
  providers: [AppInitializerService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
