import { Inject, Injectable } from '@angular/core';
import {
  CLOUD_SERVICE_INJECTION,
  CloudServicesProvider,
  DEFAULT_CLOUD_SERVICES_PROVIDERS,
  DefaultCloudService,
  ICloudServiceConfiguartionInformation,
  ICloudServiceInjection,
  ICloudServiceMenuItem,
} from '@cloud-cost-calculation-tool/base-cloud-services';

@Injectable()
export class CloudServicesFetcherService {
  protected defaultConfigurationMap: Map<string, ICloudServiceConfiguartionInformation> = new Map();
  protected cloudServicesMap: Map<string, DefaultCloudService> = new Map();
  cloudServices: Array<ICloudServiceMenuItem> = [];
  cloudProviders: Array<CloudServicesProvider> = [];

  constructor(@Inject(CLOUD_SERVICE_INJECTION) cloudServices: Array<ICloudServiceInjection>) {
    this.defaultConfigurationMap = new Map();
    this.cloudServicesMap = new Map();
    cloudServices.forEach((cloudService) => {
      this.defaultConfigurationMap.set(cloudService.defaultConfig.id, cloudService.defaultConfig);
      this.cloudServicesMap.set(cloudService.defaultConfig.id, cloudService.cloudService);
      this.cloudServices.push({
        id: cloudService.defaultConfig.id,
        name: cloudService.defaultConfig.name,
        provider: cloudService.defaultConfig.provider,
      });
    });
    // Map CLOUD_SERVICE_PROVIDER to and CloudServicesProvider Array
    Object.keys(DEFAULT_CLOUD_SERVICES_PROVIDERS).forEach((key) => {
      this.cloudProviders.push(DEFAULT_CLOUD_SERVICES_PROVIDERS[key]);
    });
  }
}
