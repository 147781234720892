import { Injectable } from '@angular/core';

@Injectable()
export class DscCostsService {
  // constructor(private httpClient: HttpClient) {
  //   const body = new HttpParams()
  //     .set('grant_type', 'client_credentials')
  //     .set('client_id', 'sis-service-catalog-serviceaccount-pricing')
  //     .set('client_secret', '1N6HHZU6XYNDQTGSUTC2D9GIU05M6DCB');
  //   const req = this.httpClient.post(
  //     'https://id.test.sick.com/auth/realms/sickservices/protocol/openid-connect/token',
  //     'client_id=sis-service-catalog-serviceaccount-pricing&client_secret=1N6HHZU6XYNDQTGSUTC2D9GIU05M6DCB&grant_type=client_credentials',
  //     {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //     },
  //   );
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   req.subscribe((data: any) => {
  //   });
  // }

  getPrice(id: string): {
    id: string;
    name: string;
    products: Array<{ id: string; name: string; plans: Array<{ id: string; name: string; phases: Array<unknown> }> }>;
  } {
    const prices = this.getPrices();
    return prices.find((price) => price.id === id) as {
      id: string;
      name: string;
      products: Array<{ id: string; name: string; plans: Array<{ id: string; name: string; phases: Array<unknown> }> }>;
    };
  }

  getPrices(): Array<{
    id: string;
    name: string;
    products: Array<{ id: string; name: string; plans: Array<{ id: string; name: string; phases: Array<unknown> }> }>;
  }> {
    return [
      {
        id: 'a6bd89b0-debf-11e9-9168-a3982b7c93c1',
        name: 'AppPool',
        products: [
          {
            id: 'SICKAppPool-Download',
            name: 'SICKAppPool-Download',
            plans: [
              {
                id: 'SICKAppPool-Download-unlimited',
                name: 'SICKAppPool-Download-unlimited',
                phases: [
                  {
                    cancellableAt: 'IMMEDIATELY',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '41413e50-8bad-11eb-a9ed-9bc1a01fda54',
        name: 'Role Management',
        products: [
          {
            id: 'RoleManagement-Demo-Free',
            name: 'RoleManagement-Demo-Free',
            plans: [
              {
                id: 'RoleManagement-Demo-FREE-unlimited',
                name: 'RoleManagement-Demo-FREE-unlimited',
                phases: [
                  {
                    cancellableAt: 'IMMEDIATELY',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        id: 'c9d247b0-d852-11e9-8c19-6747084ad0e8',
        name: 'Monitoring Box – Outdoor Technology Center',
        products: [
          {
            id: 'MonitoringBox-OTC-Demo',
            name: 'MonitoringBox-OTC-Demo',
            plans: [
              {
                id: 'MonitoringBox-OTC-Demo-unlimited',
                name: 'MonitoringBox-OTC-Demo-unlimited',
                phases: [
                  {
                    cancellableAt: 'IMMEDIATELY',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'd3671f00-e9e7-11eb-9bbc-ab9034d09eb3',
        name: 'SICK Monitoring Box for process automation (PA)',
        products: [
          {
            id: 'MonitoringBox-PA-Demo',
            name: 'Demo',
            plans: [
              {
                id: 'MonitoringBox-PA-Demo-unlimited',
                name: 'Demo',
                phases: [
                  {
                    cancellableAt: 'IMMEDIATELY',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '970bb650-68e8-11ea-922e-d91c68b66393',
        name: 'dStudio',
        products: [
          {
            id: 'dStudio-3Users',
            name: 'dStudio-3Users',
            plans: [
              {
                id: 'dStudio-3Users-monthly',
                name: 'dStudio-3Users-monthly',
                phases: [
                  {
                    billingPeriod: 'P1M',
                    recurringPrice: {
                      EUR: 500,
                      USD: 550,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
          {
            id: 'dStudio',
            name: 'Basic',
            plans: [
              {
                id: 'dStudio-monthly',
                name: 'dStudio-monthly',
                phases: [
                  {
                    billingPeriod: 'P1M',
                    recurringPrice: {
                      EUR: 500,
                      USD: 550,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'd44e42f0-a221-11e9-b853-0d2d2586cf89',
        name: 'SICK AssetHub',
        products: [
          {
            id: 'SICKAssetHub-Standard',
            name: 'Standard',
            plans: [
              {
                id: 'SICKAssetHub-Standard-monthly',
                name: 'monthly subscription',
                phases: [
                  {
                    billingPeriod: 'P1M',
                    recurringPrice: {
                      EUR: 199,
                      USD: 199,
                      TRY: 1234,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
          {
            id: 'SICKAssetHub-Free',
            name: 'Basic',
            plans: [
              {
                id: 'SICKAssetHub-Free-unlimited',
                name: 'free',
                phases: [
                  {
                    cancellableAt: 'IMMEDIATELY',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
          {
            id: 'SICKAssetHub-Enterprise',
            name: 'Enterprise',
            plans: [
              {
                id: 'SICKAssetHub-Enterprise-monthly',
                name: 'monthly subscription',
                phases: [
                  {
                    billingPeriod: 'P1M',
                    recurringPrice: {
                      EUR: 999,
                      USD: 999,
                      TRY: 99999,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1819e270-a225-11e9-85fe-b30996951705',
        name: 'Monitoring Box',
        products: [
          {
            id: 'MonitoringBox-UpTo10',
            name: '10 Sensors',
            plans: [
              {
                id: 'MonitoringBox-UpTo10-monthly',
                name: 'Monthly',
                phases: [
                  {
                    billingPeriod: 'P1M',
                    recurringPrice: {
                      EUR: 375,
                      USD: 375,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
          {
            id: 'MonitoringBox-UpTo5',
            name: '5 Sensors',
            plans: [
              {
                id: 'MonitoringBox-UpTo5-monthly',
                name: 'Monthly',
                phases: [
                  {
                    billingPeriod: 'P1M',
                    recurringPrice: {
                      EUR: 225,
                      USD: 225,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
          {
            id: 'MonitoringBox-UpTo3',
            name: '3 Sensors',
            plans: [
              {
                id: 'MonitoringBox-UpTo3-monthly',
                name: 'Monthly',
                phases: [
                  {
                    billingPeriod: 'P1M',
                    recurringPrice: {
                      EUR: 150,
                      USD: 150,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '14646c60-044d-11ea-abfc-79c4f8a65a4d',
        name: 'SICK AssetHub - LiveConnect',
        products: [
          {
            id: 'SICKAssetHub-LiveConnect-Core',
            name: 'Standard',
            plans: [
              {
                id: 'SICKAssetHub-LiveConnect-Core-monthly',
                name: 'monthly subscription',
                phases: [
                  {
                    billingPeriod: 'P1M',
                    recurringPrice: {
                      EUR: 50.0,
                      USD: 50.0,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
          {
            id: 'LiveConnect-Free',
            name: 'Basic',
            plans: [
              {
                id: 'LiveConnect-Free-unlimited',
                name: 'Free',
                phases: [
                  {
                    cancellableAt: 'IMMEDIATELY',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        id: '72f85d36-9f97-11ec-801c-d11a4cd1f07c',
        name: 'SICK DynamicDataDisplay',
        products: [
          {
            id: 'DynamicDataDisplay-FREE',
            name: 'DynamicDataDisplay - Free',
            plans: [
              {
                id: 'DynamicDataDisplay-FREE-unlimited',
                name: 'Free',
                phases: [
                  {
                    cancellableAt: 'IMMEDIATELY',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
          {
            id: 'DynamicDataDisplay-BUSINESS',
            name: 'DynamicDataDisplay - Business',
            plans: [
              {
                id: 'DynamicDataDisplay-BUSINESS-yearly',
                name: 'Business',
                phases: [
                  {
                    billingPeriod: 'P1Y',
                    recurringPrice: {
                      EUR: 1200,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
          {
            id: 'DynamicDataDisplay-MULTIUSER',
            name: 'DynamicDataDisplay - Multiuser',
            plans: [
              {
                id: 'DynamicDataDisplay-MULTIUSER-yearly',
                name: 'Multiuser',
                phases: [
                  {
                    billingPeriod: 'P1Y',
                    recurringPrice: {
                      EUR: 1200,
                    },
                    cancellableAt: 'END_OF_PERIOD',
                    recurringPaymentType: 'UPFRONT',
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
  }
}
