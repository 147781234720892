import { DEFAULT_CLOUD_SERVICES_PROVIDERS, ICloudServiceInjection } from '@cloud-cost-calculation-tool/base-cloud-services';

import {
  API_GATEWAY_CONFIGURATION,
  AURORA_INSTANCE_CONFIGURATION,
  AURORA_SERVERLESS_CONFIGURATION,
  AWS_DEFAULT_DATA_TRANSFER_CONFIGURATION,
  AWS_SIMPLE_ESTIMATION_REGIONS,
  CLOUD_FRONT_CONFIGURATION,
  CLOUD_FRONT_REGIONS,
  CLOUD_WATCH_CONFIGURATION,
  DOCUMENT_DB_CONFIGURATION,
  IOT_CORE_CONFIGURATION,
  LAMBDA_CONFIGURATION,
  S3_BUCKET_CONFIGURATION,
  SIMPLE_EMAIL_SERVICE_CONFIGURATION,
  SIMPLE_ESTIMATION_CONFIGURATION,
  SIMPLE_NOTIFICATION_SERVICE_CONFIGURATION,
  SIMPLE_QUEUE_SERVICE_CONFIGURATION,
} from './service-configurations';
import { DEFAULT_DATABASE_CONFIGURATION } from './service-configurations/default-database.configuration';
import {
  ApiGatewayService,
  AuroraInstanceService,
  AuroraServerlessService,
  CloudFrontService,
  CloudWatchService,
  DocumentDbService,
  IotCoreService,
  LambdaService,
  S3BucketService,
  SimpleEmailServiceService,
  SimpleEstimationService,
  SimpleNotificationServiceService,
  SimpleQueueServiceService,
} from './services';
import { AWS_BASIC_REGIONS } from './types/basic-regions';

const provider = DEFAULT_CLOUD_SERVICES_PROVIDERS['AWS'];
const simpleProvider = DEFAULT_CLOUD_SERVICES_PROVIDERS['SIMPLE'];

export const API_GATEWAY: ICloudServiceInjection = {
  defaultConfig: {
    name: 'API Gateway',
    id: 'apiGateway',
    description:
      'API Gateway is a fully managed service that makes it easy for developers to create, publish, maintain, monitor, and secure APIs at any scale.',
    provider,
    sections: API_GATEWAY_CONFIGURATION,
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: ApiGatewayService,
};

export const DOCUMENT_DB: ICloudServiceInjection = {
  defaultConfig: {
    name: 'DocumentDB',
    id: 'document-db',
    description:
      'Amazon DocumentDB is a web service that provides a global content delivery network (CDN) that can cache and distribute content across multiple edge locations.',
    provider,
    sections: DOCUMENT_DB_CONFIGURATION,
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: DocumentDbService,
};

export const IOT_CORE: ICloudServiceInjection = {
  defaultConfig: {
    name: 'IoT Core',
    id: 'iot-core',
    description:
      'AWS IoT Core is a web service that provides resizable compute capacity in the cloud. It is designed to make web-scale cloud computing easier for developers.',
    provider,
    sections: IOT_CORE_CONFIGURATION,
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: IotCoreService,
};

export const CLOUD_WATCH: ICloudServiceInjection = {
  defaultConfig: {
    name: 'CloudWatch',
    id: 'cloud-watch',
    description:
      'Amazon CloudWatch is a web service that provides real-time monitoring of your AWS resources and their utilization. You can use CloudWatch to collect and track metrics, and to monitor application performance.',
    provider,
    sections: CLOUD_WATCH_CONFIGURATION,
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: CloudWatchService,
};

export const SIMPLE_QUEUE_SERVICE: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Simple Queue Service',
    id: 'simple-queue-service',
    description:
      'Amazon Simple Queue Service (Amazon SQS) is a web service that enables any computer to communicate with another computer over an Internet connection. It is designed to be very simple to use and requires no special skills.',
    provider,
    sections: [...SIMPLE_QUEUE_SERVICE_CONFIGURATION, ...AWS_DEFAULT_DATA_TRANSFER_CONFIGURATION],
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: SimpleQueueServiceService,
};

export const SIMPLE_NOTIFICATION_SERVICE: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Simple Notification Service',
    id: 'simple-notification-service',
    description:
      'Amazon Simple Notification Service (Amazon SNS) is a web service that enables you to send push notifications to mobile devices and other devices in the AWS cloud. With Amazon SNS, you can use a simple and cost-effective method to send push notifications.',
    provider,
    sections: [...SIMPLE_NOTIFICATION_SERVICE_CONFIGURATION, ...AWS_DEFAULT_DATA_TRANSFER_CONFIGURATION],
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: SimpleNotificationServiceService,
};

export const SIMPLE_EMAIL_SERVICE: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Simple Email Service',
    id: 'simple-email-service',
    description:
      'Amazon Simple Email Service (Amazon SES) is a web service that enables you to send email. It provides the flexibility to send email from any computer in your organization, in a secure way, and with built-in bounce handling, and it makes it easy to maintain your email infrastructure.',
    provider,
    sections: SIMPLE_EMAIL_SERVICE_CONFIGURATION,
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: SimpleEmailServiceService,
};

export const S3_BUCKET: ICloudServiceInjection = {
  defaultConfig: {
    name: 'S3 Bucket',
    id: 's3-bucket',
    description:
      'Amazon Simple Storage Service (Amazon S3) is a web service that provides secure, durable, highly available data storage for the AWS cloud. It is designed to make web content delivery simple and cost effective.',
    provider,
    sections: [...S3_BUCKET_CONFIGURATION, ...AWS_DEFAULT_DATA_TRANSFER_CONFIGURATION],
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: S3BucketService,
};

export const LAMBDA: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Lambda',
    id: 'lambda',
    description:
      'AWS Lambda is a compute service that runs your code in response to events. It supports a variety of programming models, including Java, Python, Ruby, Node.js, and more.',
    provider,
    sections: LAMBDA_CONFIGURATION,
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: LambdaService,
};

export const CLOUD_FRONT: ICloudServiceInjection = {
  defaultConfig: {
    name: 'CloudFront',
    id: 'cloud-front',
    description:
      'Amazon CloudFront is a web service that enables developers and businesses to distribute content to end users via the Internet. It enables you to distribute content to end users quickly and securely, and minimize the cost of web hosting.',
    provider,
    sections: CLOUD_FRONT_CONFIGURATION,
    regions: CLOUD_FRONT_REGIONS,
  },
  cloudService: CloudFrontService,
};

export const AURORA_SERVERLESS: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Aurora Serverless',
    id: 'aurora-serverless',
    description:
      'Amazon Aurora Serverless is a fully-managed, highly available, scalable, and secure relational database service that makes it easy to build, deploy, and manage relational databases in the cloud.',
    provider,
    sections: [...AURORA_SERVERLESS_CONFIGURATION, ...DEFAULT_DATABASE_CONFIGURATION],
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: AuroraServerlessService,
};

export const AURORA_INSTANCE: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Aurora Instance',
    id: 'aurora-instance',
    description:
      'Amazon Aurora is a fully-managed, highly available, scalable, and secure relational database service that makes it easy to build, deploy, and manage relational databases in the cloud.',
    provider,
    sections: [...AURORA_INSTANCE_CONFIGURATION, ...DEFAULT_DATABASE_CONFIGURATION],
    regions: AWS_BASIC_REGIONS,
  },
  cloudService: AuroraInstanceService,
};

export const SIMPLE_ESTIMATION: ICloudServiceInjection = {
  defaultConfig: {
    name: 'Simple Cloud Cost Estimation',
    id: 'simple-aws-estimation',
    description:
      'This Estimation creates a very rought pricing estimate. You Should always look at specific Services if you want to have a detailled and more reliable Estimation. This Estimation is good for starting with a customer project!',
    provider: simpleProvider,
    sections: SIMPLE_ESTIMATION_CONFIGURATION,
    regions: AWS_SIMPLE_ESTIMATION_REGIONS,
  },
  cloudService: SimpleEstimationService,
};
