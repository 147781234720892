import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastModule as PrimeToast } from 'primeng/toast';

import { SharedModule } from '../../shared/shared.module';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { ToastComponent } from './toast.component';
import { ToastService } from './toast.service';

@NgModule({
  declarations: [ToastComponent],
  imports: [SharedModule, PrimeToast],
  exports: [ToastComponent],
  providers: [ToastService, MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToastModule {
  constructor(@Optional() @SkipSelf() parentModule: ToastModule) {
    throwIfAlreadyLoaded(parentModule, 'ToastModule');
  }
}
