import { AbstractCloudServiceBase } from '../default-cloud-service.class';
import { IServiceConfigurationElement } from './cloud-service';

export interface PriceElement {
  uuid: string;
  name: string;
  description?: string;
  id: string;
  price: number;
  service: AbstractCloudServiceBase;
}

export interface IPriceEstimationProject {
  name: string;
  id: string;
  elements: Array<IPriceElement>;
}

export interface IPriceElement {
  uuid: string;
  name: string;
  description?: string;
  cloudService: AbstractCloudServiceBase;
}

export interface IPricingBackendResponse {
  name: string;
  id: string;
  elements: IServiceConfigurationElement;
}

export interface IPricingBackendObject {
  name: string;
  id: string;
  services: Array<IServiceConfigurationElement>;
}
