import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cloud-cost-calculation-tool-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss'],
})
export class FooterBarComponent {
  @Input()
  left!: { disabled: boolean; text: string };

  @Input()
  right!: { disabled: boolean; text: string };

  @Input()
  priceAmount!: number;

  @Input()
  showPrice!: boolean;

  @Output()
  leftClickedEvent = new EventEmitter<void>();

  @Output()
  rightClickedEvent = new EventEmitter<void>();

  leftClicked(): void {
    this.leftClickedEvent.emit();
  }

  rightClicked(): void {
    this.rightClickedEvent.emit();
  }
}
