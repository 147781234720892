import { Injectable } from '@angular/core';
import {
  AbstractCloudServiceBase,
  CloudServicesProvider,
  ICloudServiceMenuItem,
  IServiceConfiguration,
} from '@cloud-cost-calculation-tool/base-cloud-services';
import { BehaviorSubject, Observable } from 'rxjs';

import { CloudServicesFactoryService } from './cloud-services-factory.service';

@Injectable()
export class CloudServicesHandlerService {
  private $service = new BehaviorSubject<AbstractCloudServiceBase | undefined>(undefined);

  private get service(): AbstractCloudServiceBase | undefined {
    return this.$service.getValue();
  }

  private set service(service: AbstractCloudServiceBase | undefined) {
    this.$service.next(service);
  }

  get cloudServices(): Array<ICloudServiceMenuItem> {
    return this.cloudServicesFactoryService.cloudServices;
  }

  get cloudProviders(): Array<CloudServicesProvider> {
    return this.cloudServicesFactoryService.cloudProviders;
  }

  get serviceObservable(): Observable<AbstractCloudServiceBase | undefined> {
    return this.$service.asObservable();
  }

  constructor(private cloudServicesFactoryService: CloudServicesFactoryService) {}

  async changeService(serviceId?: string, serviceConfiguration: Array<IServiceConfiguration> = []): Promise<void> {
    if (!serviceId) {
      this.service = undefined;
      return;
    }
    this.service = this.cloudServicesFactoryService.createCloudServiceFactory(serviceId, serviceConfiguration);
    await this.service.updatePriceInformations();
  }
}
