<div class='estimation-card'>
  <div class='estimation-card-header'>
    <div class='estimation-card-header-title'>
      <h4>{{ element.name }}</h4>
      <p>{{ element.description }}</p>
    </div>
    <div class='estimation-card-header--actions'>
      <davinci-button
        type='bare'
        icon='action/settings'
        (click)='onEdit()'
        pTooltip='Change Estimation'
        tooltipPosition='bottom'
      ></davinci-button>
      <davinci-button
        type='bare'
        icon='action/delete'
        (click)='onDelete()'
        pTooltip='Delete Estimation'
        tooltipPosition='bottom'
      ></davinci-button>
    </div>
  </div>

  <davinci-collapse
    class='estimation-card-description ec-content'
    *ngFor='let option of element.cloudService.sections'
    [collapsedTitle]='option.name'
    [expandedTitle]='option.name'
  >
    <div class='ec-content__content' *ngFor='let input of option.input'>
      <p class='ec-content__key'>{{ input.name }}</p>
      <p class='ec-content__value'>{{ input.selected }}</p>
    </div>
  </davinci-collapse>
  <div class='estimation-card-estimation'>
    <h6 class='estimation-card-estimation--heading'>Price</h6>
    <p class='estimation-card-estimation--value'>{{ element.cloudService.price | currency }}</p>
  </div>
</div>
