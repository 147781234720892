<div class='header'>
  <div class='header__first-row'>
    <davinci-drop-down class='header__first-row--dropdown' (input)='changeSelectionInput($event)'
                       [value]='dropDownValue'
                       (change)='cloudServiceSelectedHandler($event)'
                       id='serviceSelection' label='Select Service' editable>
      <davinci-option-group *ngFor='let provider of filteredServiceList' [label]='provider.label'>
        <davinci-option *ngFor='let service of provider.services' [value]='service.id'>{{service.name}}</davinci-option>
      </davinci-option-group>
    </davinci-drop-down>
    <!--    <davinci-toggle-group (change)='changeVerticalModeSelected($event)'>-->
    <!--      <davinci-radio-button [value]='false'>List View</davinci-radio-button>-->
    <!--      <davinci-radio-button [value]='true'>Stacked View</davinci-radio-button>-->
    <!--    </davinci-toggle-group>-->
    <davinci-toggle-switch icon-on='action/view_column' icon-off='action/view_list'
                           [value]='verticalModeSelected'
                           (change)='changeVerticalModeSelected($event)'
                           [pTooltip]='verticalModeSelected ? "Switch to Seperate View" : "Switch to Stacked View"'></davinci-toggle-switch>
  </div>
  <p-steps *ngIf='!verticalModeSelected' class='header__second-row' [model]='items' [readonly]='true'
           [activeIndex]='step'></p-steps>

</div>
