<div class="content">
  <div class="content__section">
    <div class="content__section--input-area">
      <davinci-text-field
        class="content__section--input"
        label="Custom Provider Name"
        [value]="nickname"
        (change)="changeNickname($event)"
      ></davinci-text-field>
      <davinci-text-field
        class="content__section--input"
        label="Account Key"
        [value]="accountKey"
        (change)="changeKey($event)"
      ></davinci-text-field>
      <davinci-text-field
        class="content__section--input"
        label="Account Secret"
        [value]="secret"
        (change)="changeSecret($event)"
      ></davinci-text-field>
    </div>
    <davinci-button type="bare" (click)="deleteProvider()" icon="action/delete"></davinci-button>
  </div>
  <div class="content__tags" *ngFor="let tag of tags; let i = index">
    <davinci-text-field
      class="content__tags--input"
      [value]="tag.key"
      label="Key"
      (change)="changeTagKey($event, i)"
    ></davinci-text-field>
    <davinci-text-field
      class="content__tags--input"
      [value]="tag.value"
      label="Value"
      (change)="changeTagValue($event, i)"
    ></davinci-text-field>
    <davinci-button type="bare" (click)="removeTag(i)" icon="action/delete"></davinci-button>
  </div>
  <davinci-button class="content__add-tag" type="bare" (click)="newTag()">+ Tag</davinci-button>
</div>
