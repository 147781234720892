import { RootNode } from '@sick-curie/dashboard-builder';

export const alibaba: RootNode = {
  type: 'root',
  element: undefined,
  children: [
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'AliHistoric costs by Cloud Provider',
          lock: false,
          x: 12,
          y: 0,
          rows: 6,
          cols: 9,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-bar-chart-grouped',
          properties: {
            valueAxisMaxTickCount: 10,
            barLabels: ['Bar 1', 'Bar 2'],
            labels: ['Bar Group 1'],
            values: [[50, 70]],
            valueRange: {
              start: 0,
              end: 100,
            },
            thresholdValues: [],
            thresholdLabels: [],
            colors: [
              {
                palette: 'general',
                index: 0,
              },
              {
                palette: 'general',
                index: 1,
              },
            ],
            showLegend: true,
            palette: 'primary',
            horizontal: false,
            shorthandLabels: false,
            valueAxisTitle: '',
            categoryAxisTitle: '',
            valueRangeAuto: false,
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Current Cost Distribution Cloud Provider',
          lock: false,
          x: 23,
          y: 6,
          rows: 12,
          cols: 7,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-donut-classic',
          properties: {
            unit: '%',
            gap: 3,
            decimalPlaces: 1,
            values: [10, 8],
            labels: ['Prod.E', 'Prod.F'],
            colors: [
              {
                palette: 'general',
                index: 4,
              },
              {
                palette: 'general',
                index: 5,
              },
            ],
            showLegend: true,
            palette: 'primary',
            labelShorthand: false,
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Total historic and future costs (monthly)',
          lock: false,
          x: 0,
          y: 6,
          rows: 6,
          cols: 16,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-line-chart',
          properties: {
            labels: ['Line 1'],
            valueAxisMaxTickCount: 10,
            grid: 'value',
            lineWidths: [2],
            lineColors: [
              {
                palette: 'general',
                index: 0,
              },
            ],
            valueRange: {
              start: 0,
              end: 100,
            },
            thresholdValues: [],
            thresholdLabels: [],
            xaxis: [],
            values: [[10, 20]],
            showLegend: true,
            palette: 'primary',
            shorthandLabels: false,
            valueAxisTitle: '',
            categoryAxisTitle: '',
            valueRangeAuto: false,
            interactiveLegend: false,
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Costs this month',
          lock: false,
          x: 4,
          y: 0,
          rows: 6,
          cols: 4,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-numeric-chart',
          properties: {
            size: 'auto',
            textColor: {
              palette: 'general',
              index: 1,
            },
            palette: 'primary',
            value: 0,
            decimalPlaces: 0,
            unit: '',
            prefix: '',
            suffix: '',
            subText: '',
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Estimated costs by Cloud Provider',
          lock: false,
          x: 21,
          y: 0,
          rows: 6,
          cols: 9,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-bar-chart-grouped',
          properties: {
            valueAxisMaxTickCount: 10,
            barLabels: ['Bar 1', 'Bar 2'],
            labels: ['Bar Group 1'],
            values: [[50, 70]],
            valueRange: {
              start: 0,
              end: 100,
            },
            thresholdValues: [],
            thresholdLabels: [],
            colors: [
              {
                palette: 'general',
                index: 0,
              },
              {
                palette: 'general',
                index: 1,
              },
            ],
            showLegend: true,
            palette: 'primary',
            horizontal: false,
            shorthandLabels: false,
            valueAxisTitle: '',
            categoryAxisTitle: '',
            valueRangeAuto: false,
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Costs last month',
          lock: false,
          x: 0,
          y: 0,
          rows: 6,
          cols: 4,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-numeric-chart',
          properties: {
            size: 'auto',
            textColor: {
              palette: 'general',
              index: 1,
            },
            palette: 'primary',
            value: 0,
            decimalPlaces: 0,
            unit: '',
            prefix: '',
            suffix: '',
            subText: '',
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Costs next month',
          lock: false,
          x: 8,
          y: 0,
          rows: 6,
          cols: 4,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-numeric-chart',
          properties: {
            size: 'auto',
            textColor: {
              palette: 'general',
              index: 1,
            },
            palette: 'primary',
            value: 0,
            decimalPlaces: 0,
            unit: '',
            prefix: '',
            suffix: '',
            subText: '',
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Future Cost Distribution Cloud Provider',
          lock: false,
          x: 16,
          y: 6,
          rows: 12,
          cols: 7,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-donut-classic',
          properties: {
            unit: '%',
            gap: 3,
            decimalPlaces: 1,
            values: [10, 8],
            labels: ['Prod.E', 'Prod.F'],
            colors: [
              {
                palette: 'general',
                index: 4,
              },
              {
                palette: 'general',
                index: 5,
              },
            ],
            showLegend: true,
            palette: 'primary',
            labelShorthand: false,
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Total historic and future costs (daily)',
          lock: false,
          x: 0,
          y: 12,
          rows: 6,
          cols: 16,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-line-chart',
          properties: {
            labels: ['Line 1'],
            valueAxisMaxTickCount: 10,
            grid: 'value',
            lineWidths: [2],
            lineColors: [
              {
                palette: 'general',
                index: 0,
              },
            ],
            valueRange: {
              start: 0,
              end: 100,
            },
            thresholdValues: [],
            thresholdLabels: [],
            xaxis: [],
            values: [[10, 20]],
            showLegend: true,
            palette: 'primary',
            shorthandLabels: false,
            valueAxisTitle: '',
            categoryAxisTitle: '',
            valueRangeAuto: false,
            interactiveLegend: false,
          },
        },
        children: [],
      },
    },
  ],
};
