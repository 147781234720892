import { CloudServiceSection } from '@cloud-cost-calculation-tool/base-cloud-services';

export const D_STUDIO_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Subscription Type',
    id: 'subscription-tier',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Type',
        description: '',
        id: 'tier',
        type: 'selection',
        selected: '',
        options: [
          {
            name: 'Free',
            value: 'DynamicDataDisplay-Free',
          },
          {
            name: 'Business',
            value: 'DynamicDataDisplay-BUSINESS',
          },
          {
            name: 'Multiuser',
            value: 'DynamicDataDisplay-MULTIUSER',
          },
        ],
      },
    ],
  },
];
