<div class='content'>
  <davinci-text-field
    class='content__section--input'
    label='Estimation Project Name'
    [value]='name'
    (change)='changeNickname($event)'
  ></davinci-text-field>
</div>
<div class='actions'>
  <davinci-button (click)='cancel()'>Cancel</davinci-button>
  <davinci-button (click)='saveName()'>Add/Update</davinci-button>
</div>
