import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';

export class CloudWatchService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AmazonCloudWatch';
    return {
      complete: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER],
      },
    };
  }

  async calculatePrices(): Promise<void> {
    const metricPrice = this.findPriceItemAndCalculatePrice('usagetype', 'CW:MetricMonitorUsage', this.retrieveMetrics());

    const gmdPrice = this.findPriceItemAndCalculatePrice('usagetype', 'CW:GMD-Metrics', this.retrieveGmd());
    const gmwiPrice = this.findPriceItemAndCalculatePrice('usagetype', 'CW:GMWI-Metrics', this.retrieveGmwi());
    const requestPrice = this.findPriceItemAndCalculatePrice('usagetype', 'CW:Requests', this.retrieveRequests());

    const standardLogsPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'DataProcessing-Bytes',
      this.retrieveStandardLogs(),
    );
    const vendedLogsPrice = this.findPriceItemAndCalculatePrice('usagetype', 'VendedLog-Bytes', this.retrieveVendedLogs());
    const archivalPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'TimedStorage-ByteHrs',
      this.retrieveArchivalStorage(),
    );
    const s3DataPrice = this.findPriceItemAndCalculatePrice('usagetype', 'S3-Egress-Bytes', this.retrieveS3Data());
    const s3DataConversionPrice = this.findPriceItemAndCalculatePrice('usagetype', 'S3-Egress-InputBytes', this.retrieveS3Data());

    const dashboardPrice = this.retrieveDashboardPrice();
    const alarmPrice = this.findPriceItemAndCalculatePrice('usagetype', 'CW:AlarmMonitorUsage', this.retrieveAlarms());
    const highResAlarmPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'W:HighResAlarmMonitorUsage',
      this.retrieveHighResAlarms(),
    );
    const compositeAlarmPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'CW:CompositeAlarmMonitorUsage',
      this.retrieveCompositeAlarms(),
    );

    const canaryPrice = this.findPriceItemAndCalculatePrice('usagetype', 'DataScanned-Bytes', this.retrieveCanaries());

    const lambdaMetricPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'CW:MetricMonitorUsage',
      this.retrieveLambdaMetrics(),
    );
    const lambdaRequestPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'DataProcessing-Bytes',
      this.retrieveLambdaRequests(),
    );

    const storageArchival = this.getSelected('logs', 'archive-logs');
    const s3Conversion = this.getSelected('logs', 'logs-converted-to-apache-parquet');

    const apiPrice = gmdPrice + gmwiPrice + requestPrice;
    let logsPrice = standardLogsPrice + vendedLogsPrice + s3DataPrice;
    if (storageArchival === '1') {
      logsPrice += archivalPrice;
    }
    if (s3Conversion === '1') {
      logsPrice += s3DataConversionPrice;
    }

    this.updatePrices([
      metricPrice,
      apiPrice,
      logsPrice,
      dashboardPrice + alarmPrice + highResAlarmPrice + compositeAlarmPrice,
      canaryPrice,
      lambdaMetricPrice + lambdaRequestPrice,
    ]);
    return;
  }

  private retrieveMetrics(): number {
    return this.getSelected('metrics', 'number-of-metrics') as number;
  }

  private retrieveGmd(): number {
    return this.getSelected('apis', 'getmetricdata-number-of-metrics-requested') as number;
  }

  private retrieveGmwi(): number {
    return this.getSelected('apis', 'getmetricwidgetimage-number-of-metrics-requested') as number;
  }

  private retrieveRequests(): number {
    return this.getSelected('apis', 'number-of-other-api-requests') as number;
  }

  private retrieveStandardLogs(): number {
    return this.getSelected('logs', 'standard-logs-data-ingested') as number;
  }

  private retrieveVendedLogs(): number {
    return this.getSelected('logs', 'logs-delivered-to-cloudwatch-logs-data-ingested') as number;
  }

  private retrieveArchivalStorage(): number {
    // (vended + data) * 0.15 compression factor
    return (this.retrieveStandardLogs() + this.retrieveVendedLogs()) * 0.15;
  }

  private retrieveS3Data(): number {
    return this.getSelected('logs', 'logs-delivered-to-s3-data-ingested') as number;
  }

  private retrieveAlarms(): number {
    return this.getSelected('dashboards-and-alarms', 'standard-resolution-alarm-metrics') as number;
  }

  private retrieveHighResAlarms(): number {
    return this.getSelected('dashboards-and-alarms', 'high-resolution-alarm-metrics') as number;
  }

  private retrieveCompositeAlarms(): number {
    return this.getSelected('dashboards-and-alarms', 'composite-alarms') as number;
  }

  private retrieveCanaries(): number {
    return this.getSelected('canaries', 'canary-runs') as number;
  }

  private retrieveLambdaMetrics(): number {
    // functions * 8 metrics per function
    return (this.getSelected('lambda-insights', 'lambda-functions') as number) * 8;
  }

  private retrieveLambdaRequests(): number {
    // functions * requests & 1.1kb per average log event
    const kbLogEvents =
      this.retrieveLambdaMetrics() * (this.getSelected('lambda-insights', 'requests-per-function') as number) * 1.1;
    // kb to gb
    return kbLogEvents / 1024 / 1024;
  }

  private retrieveDashboardPrice(): number {
    const dashboard = this.getSelected('dashboards-and-alarms', 'dashboards') as number;
    if (dashboard < 3) {
      return 0;
    }
    return dashboard * 3 - 9;
  }
}
