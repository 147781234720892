import {
  CloudServiceSection,
  UNIT_AMOUNT_PER_MONTH,
  UNIT_DURATION_AS_SECONDS,
  UNIT_SIZE_AS_KB,
} from '@cloud-cost-calculation-tool/base-cloud-services';

export const API_GATEWAY_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'HTTP APIs',
    id: 'http-api',
    calculation: true,
    price: 0,
    description:
      'Select the units, number, and frequency for HTTP API requests based on expected volume. The calculations below exclude free tier discounts.',
    info: 'With HTTP APIs, you only pay when your APIs are in use. There are no minimum fees or upfront commitments. You pay for the API calls you receive and the amount of data transferred out. Requests are metered in 512 KB increments of data. So, a request with 513 KB of data is metered as two requests.',
    input: [
      {
        name: 'Number of requests',
        id: 'requests',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'Average size of each request',
        id: 'duration',
        description: 'Requests are defined as having 512 KB of data; a request with 513 KB of data is metered as two requests.',
        type: 'number',
        selected: 34,
        unitSelected: UNIT_SIZE_AS_KB[0].value,
        numberSelected: 34,
        options: {
          min: 0,
          max: 10240,
          step: 1,
        },
        units: UNIT_SIZE_AS_KB,
      },
    ],
  },
  {
    name: 'REST APIs',
    id: 'rest-api',
    calculation: true,
    price: 0,
    description:
      'Select the units, number, and frequency for REST API requests based on expected volume. The calculations below exclude free tier discounts.',
    info: 'With REST APIs, you only pay when your APIs are in use. There are no minimum fees or upfront commitments. You pay for the API calls you receive and the amount of data transferred out. There are no data transfer out charges for Private APIs. However, AWS PrivateLink charges apply when using Private APIs in API Gateway. API Gateway also provides optional data caching charged at an hourly rate that varies based on the cache size you select.',
    input: [
      {
        name: 'Number of requests',
        id: 'requests',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
    ],
  },
  {
    name: 'WebSocket APIs',
    id: 'websocket-api',
    calculation: true,
    price: 0,
    description:
      'Select the units, number, and frequency for WebSocket messages (sent and received) based on expected volume. The calculations below exclude free tier discounts.',
    info: 'With WebSocket APIs, you only pay when your APIs are in use. There are no minimum fees or upfront commitments. You pay for messages sent and received and the total number of connection minutes. You may send and receive messages up to 128 kilobytes (KB) in size. Messages are metered in 32 KB increments. So, a 33 KB message is metered as two messages.',
    input: [
      {
        name: 'Number of Messages',
        id: 'messages',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'Average message size',
        id: 'size',
        description:
          'You may send and receive messages up to 128 kilobytes (KB) in size. Messages are metered in 32 KB increments. So, a 33 KB message is metered as two messages.',
        type: 'number',
        selected: 32,
        unitSelected: UNIT_SIZE_AS_KB[0].value,
        numberSelected: 32,
        options: {
          min: 0,
          max: 128,
          step: 1,
        },
        units: UNIT_SIZE_AS_KB,
      },
      {
        name: 'Average connection',
        id: 'duration',
        description:
          'Enter the average duration for each new connection (if the duration is less than 1 second then enter 1 second).',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_DURATION_AS_SECONDS[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_DURATION_AS_SECONDS,
      },
      {
        name: 'Average connection rate',
        id: 'duration-rate',
        description: 'How often is there a new connection?',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
    ],
  },
];
