import { CloudServiceSection, UNIT_SIZE_AS_GB } from '@cloud-cost-calculation-tool/base-cloud-services';

export const DOCUMENT_DB_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Instance',
    id: 'instance',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Instance Class',
        id: 'instance-class',
        type: 'selection',
        selected: '',
        options: [],
      },
      {
        name: 'Quantity',
        id: 'quantity',
        description: 'Enter the number of instances in your cluster including replica instances',
        type: 'number',
        selected: 1,
        unitSelected: 1,
        numberSelected: 1,

        options: {
          min: 1,
          max: 15,
          step: 1,
        },
      },
    ],
  },
  {
    name: 'Storage and IOs',
    id: 'storage-and-ios',
    calculation: true,
    price: 0,
    info: 'Storage consumed by your Amazon DocumentDB cluster is billed in per GB-month increments and IOs consumed are billed in per million request increments. You pay only for the storage and IOs your Amazon DocumentDB cluster consumes and do not need to provision in advance.',
    input: [
      {
        name: 'Storage',
        id: 'storage',
        description: 'Enter the amount of storage for the cluster in a given month (GB)',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
      {
        name: 'I/Os',
        id: 'ios',
        description: 'Enter the number of IOs (in millions) for the cluster in a given month',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000000,
          step: 1,
        },
        units: undefined,
      },
    ],
  },
  {
    name: 'Backup storage',
    id: 'backup-storage',
    calculation: true,
    price: 0,
    info: 'Backup storage is allocated by region. Total backup storage space is equivalent to the sum of the storage for all backups in that region.',
    input: [
      {
        name: 'Backup storage',
        id: 'storage',
        description:
          'Enter the amount of backup storage that is in excess of 100% of the storage size for the cluster in a given month. (',
        type: 'number',
        selected: 9,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 9,
        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
    ],
  },
];
