import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ProjectAnalysisModule } from '@cloud-cost-calculation-tool/project-analysis';
import { DashboardBuilderModule } from '@sick-curie/dashboard-builder';
import { CalendarModule } from 'primeng/calendar';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';

import { SharedModule } from '../../shared/shared.module';
import { AnalysisComponent } from './analysis.component';
import { AddProjectDialogComponent } from './components/add-project-dialog/add-project-dialog.component';
import { AnalysisNavigationComponent } from './components/analysis-navigation/analysis-navigation.component';

@NgModule({
  declarations: [AnalysisComponent, AddProjectDialogComponent, AnalysisNavigationComponent],
  imports: [SharedModule, DynamicDialogModule, ProjectAnalysisModule, DashboardBuilderModule, CalendarModule],
  providers: [DialogService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnalysisModule {}
