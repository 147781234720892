import { DisplayInformationElement } from '@cloud-cost-calculation-tool/project-analysis';
import { RootNode } from '@sick-curie/dashboard-builder';

import { setDashboardElementValues, setHeaderText, timeToDayString, timeToMonthString } from '../helper';
import { getIndexForHeaderText, setDashboardElementValue, setDashboardElementXAxis } from '../helper/dashboard-helper';

export const aws: RootNode = {
  type: 'root',
  element: undefined,
  children: [
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Total historic and future costs (monthly)',
          lock: false,
          x: 0,
          y: 6,
          rows: 6,
          cols: 30,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-line-chart',
          properties: {
            labels: ['Costs'],
            valueAxisMaxTickCount: 10,
            grid: 'value',
            lineWidths: [2],
            lineColors: [
              {
                palette: 'general',
                index: 0,
              },
            ],
            valueRange: {
              start: 0,
              end: 100,
            },
            thresholdValues: [],
            thresholdLabels: [],
            xaxis: [],
            values: [[10, 20]],
            showLegend: true,
            palette: 'primary',
            shorthandLabels: false,
            valueAxisTitle: '$',
            categoryAxisTitle: '',
            valueRangeAuto: true,
            interactiveLegend: false,
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Costs this month',
          lock: false,
          x: 5,
          y: 0,
          rows: 6,
          cols: 5,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-numeric-chart',
          properties: {
            size: 'auto',
            textColor: {
              palette: 'general',
              index: 1,
            },
            palette: 'primary',
            value: 0,
            decimalPlaces: 0,
            unit: '$',
            prefix: '',
            suffix: '',
            subText: '',
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Total historic and future costs (week)',
          lock: false,
          x: 15,
          y: 0,
          rows: 6,
          cols: 15,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-line-chart',
          properties: {
            labels: ['Costs'],
            valueAxisMaxTickCount: 10,
            grid: 'value',
            lineWidths: [2],
            lineColors: [
              {
                palette: 'general',
                index: 0,
              },
            ],
            valueRange: {
              start: 0,
              end: 100,
            },
            thresholdValues: [],
            thresholdLabels: [],
            xaxis: [],
            values: [[10, 20]],
            showLegend: true,
            palette: 'primary',
            shorthandLabels: false,
            valueAxisTitle: '$',
            categoryAxisTitle: '',
            valueRangeAuto: true,
            interactiveLegend: false,
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Costs last month',
          lock: false,
          x: 0,
          y: 0,
          rows: 6,
          cols: 5,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-numeric-chart',
          properties: {
            size: 'auto',
            textColor: {
              palette: 'general',
              index: 1,
            },
            palette: 'primary',
            value: 0,
            decimalPlaces: 0,
            unit: '$',
            prefix: '',
            suffix: '',
            subText: '',
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Costs next month',
          lock: false,
          x: 10,
          y: 0,
          rows: 6,
          cols: 5,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-numeric-chart',
          properties: {
            size: 'auto',
            textColor: {
              palette: 'general',
              index: 1,
            },
            palette: 'primary',
            value: 0,
            decimalPlaces: 0,
            unit: '$',
            prefix: '',
            suffix: '',
            subText: '',
          },
        },
        children: [],
      },
    },
    {
      type: 'grid',
      element: {
        tag: 'dashboard-grid-tile',
        properties: {
          header: 'Total historic and future costs (daily)',
          lock: false,
          x: 0,
          y: 12,
          rows: 6,
          cols: 30,
        },
      },
      child: {
        type: 'element',
        element: {
          tag: 'dashboard-line-chart',
          properties: {
            labels: ['Costs'],
            valueAxisMaxTickCount: 10,
            grid: 'value',
            lineWidths: [2],
            lineColors: [
              {
                palette: 'general',
                index: 0,
              },
            ],
            valueRange: {
              start: 0,
              end: 100,
            },
            thresholdValues: [],
            thresholdLabels: [],
            xaxis: [],
            values: [[10, 20]],
            showLegend: true,
            palette: 'primary',
            shorthandLabels: false,
            valueAxisTitle: '$',
            categoryAxisTitle: '',
            valueRangeAuto: true,
            interactiveLegend: false,
          },
        },
        children: [],
      },
    },
  ],
};

export const calculateAwsDashboard = (informationElement: DisplayInformationElement): RootNode => {
  const dashboard = informationElement.dashboard;
  const prices = informationElement.costs;
  const todayOneMonthAgo = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).getTime();
  const todayInOneMonth = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).getTime();
  // Last Month Info
  let index = getIndexForHeaderText(dashboard, 'Costs last month');
  setDashboardElementValue(
    dashboard,
    index,
    Math.round(
      prices.monthly.find(
        (element) => timeToMonthString(element.startTime, element.estimated) === timeToMonthString(todayOneMonthAgo),
      )?.costs as number,
    ),
  );
  setHeaderText(dashboard, index, `Costs ${timeToMonthString(todayOneMonthAgo)}`);
  // Next Month Info
  index = getIndexForHeaderText(dashboard, 'Costs next month');
  setDashboardElementValue(
    dashboard,
    index,
    Math.round(
      prices.monthly.find(
        (element) => timeToMonthString(element.startTime, element.estimated) === timeToMonthString(todayInOneMonth, true),
      )?.costs as number,
    ),
  );
  setHeaderText(dashboard, index, `Costs ${timeToMonthString(todayInOneMonth)}*`);
  // This month info
  index = getIndexForHeaderText(dashboard, 'Costs this month');
  setDashboardElementValue(
    dashboard,
    index,
    Math.round(
      prices.monthly.find(
        (element) => timeToMonthString(element.startTime, element.estimated) === timeToMonthString(new Date().getTime(), true),
      )?.costs as number,
    ),
  );
  setHeaderText(dashboard, index, `Costs ${timeToMonthString(new Date().getTime())}*`);
  // Monthly Graph
  index = getIndexForHeaderText(dashboard, 'Total historic and future costs (monthly)');
  setDashboardElementValues(dashboard, index, [prices.monthly.map((element) => Math.round(element.costs as number))]);
  setDashboardElementXAxis(
    dashboard,
    index,
    prices.monthly.map((element) => timeToMonthString(element.startTime, element.estimated)),
  );
  // Daily Graph
  index = getIndexForHeaderText(dashboard, 'Total historic and future costs (daily)');
  setDashboardElementValues(dashboard, index, [prices.daily.map((element) => Math.round(element.costs as number))]);
  setDashboardElementXAxis(
    dashboard,
    index,
    prices.daily.map((element) => timeToDayString(element.startTime, element.estimated)),
  );
  // Hourly Graph
  index = getIndexForHeaderText(dashboard, 'Total historic and future costs (week)');
  setDashboardElementValues(dashboard, index, [prices.weekly.map((element) => Math.round(element.costs as number))]);
  setDashboardElementXAxis(
    dashboard,
    index,
    prices.weekly.map((element) => timeToDayString(element.startTime, element.estimated)),
  );
  return dashboard;
};
