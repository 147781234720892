import { CloudServiceSection } from '@cloud-cost-calculation-tool/base-cloud-services';

export const MONITORING_BOX_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Subscription Type',
    id: 'subscription-type',
    calculation: true,
    price: 375,
    info: '',
    input: [
      {
        name: 'Devices',
        description: '',
        id: 'type',
        type: 'selection',
        selected: '',
        options: [
          {
            name: 'Up to 10',
            value: 'MonitoringBox-UpTo10',
          },
          {
            name: 'Up to 5',
            value: 'MonitoringBox-UpTo5',
          },
          {
            name: 'Up to 3',
            value: 'MonitoringBox-UpTo3',
          },
        ],
      },
    ],
  },
];
