import {
  CloudServiceSection,
  ICloudServiceRegion,
  UNIT_AMOUNT_PER_HOUR,
  UNIT_AMOUNT_PER_MONTH,
  UNIT_DURATION_AS_DAYS,
  UNIT_SIZE_AS_KB,
} from '@cloud-cost-calculation-tool/base-cloud-services';

export const AWS_SIMPLE_ESTIMATION_REGIONS: Array<ICloudServiceRegion> = [
  {
    name: 'US East',
    value: 'us-east-1',
  },
  {
    name: 'Europe',
    value: 'eu-central-1',
  },
  {
    name: 'Asia South',
    value: 'ap-southeast-1',
  },
  {
    name: 'Asia North',
    value: 'ap-northeast-1',
  },
  {
    name: 'US West',
    value: 'us-west-1',
  },
  {
    name: 'South America',
    value: 'sa-east-1',
  },
  // {
  //   name: 'Australia',
  //   value: 'australia',
  // },
  // {
  //   name: 'Africa',
  //   value: 'africa',
  // },
  // {
  //   name: 'Asia Pacific',
  //   value: 'asia-pacific',
  // },
];

export const SIMPLE_ESTIMATION_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Edge',
    info: '',
    id: 'edge',
    calculation: false,
    price: 0,
    input: [
      {
        name: 'How many devices send data to your application?',
        id: 'devices',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 1,
        options: {
          min: 1,
          step: 1,
        },
      },
      {
        name: 'How often do they send data to the cloud?',
        id: 'frequency',
        type: 'number',

        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_HOUR[0].value,
        numberSelected: 1,
        options: {
          min: 1,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_HOUR,
      },
      {
        name: 'How much data do you send per request in average?',
        id: 'data',
        type: 'number',

        selected: 0,
        unitSelected: UNIT_SIZE_AS_KB[0].value,
        numberSelected: 0,
        options: {
          min: 1,
          step: 1,
        },
        units: UNIT_SIZE_AS_KB,
      },
    ],
  },
  {
    name: 'Data',
    info: '',
    id: 'data',
    calculation: true,
    price: 0,
    input: [
      {
        name: 'What format will the data be stored in?',
        id: 'format',
        type: 'selection',
        selected: 'sql',
        options: [
          {
            name: 'SQL',
            value: 'sql',
          },
          {
            name: 'JSON / NoSQL',
            value: 'json',
          },
          {
            name: 'Other / Files',
            value: 'other',
          },
        ],
      },
      {
        name: 'How long will the data be stored?',
        id: 'data',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_DURATION_AS_DAYS[0].value,
        numberSelected: 0,
        options: {
          min: 1,
          step: 1,
        },
        units: UNIT_DURATION_AS_DAYS,
      },
      {
        name: 'How often are the values queried?',
        id: 'requests',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 1,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
    ],
  },
  {
    name: 'CloudWatch',
    info: '',
    id: 'reporting',
    calculation: true,
    price: 0,
    input: [
      {
        name: 'How many Dashboards will be used?',
        id: 'dashboards',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 1,
          step: 1,
        },
      },
      {
        name: 'Number of Metrics (includes detailed and custom metrics)',
        id: 'metrics',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
    ],
  },
  // {
  //   name: 'Virtualization',
  //   info: '',
  //   id: 'virtualization',
  //   calculation: false,
  //   price: 0,
  //   input: [
  //     {
  //       name: 'Will there be virtualization?',
  //       id: 'virtualization',
  //       type: 'boolean',
  //       selected: true,
  //       options: {
  //         true: 'Yes',
  //         false: 'No',
  //       },
  //     },
  //     {
  //       name: 'How much vCPUs will be used?',
  //       id: 'cpu',
  //       type: 'number',

  //       selected: 0,
  //       unitSelected: 1,
  //       numberSelected: 0,
  //       options: {
  //         min: 1,
  //         step: 1,
  //       },
  //     },
  //     {
  //       name: 'How much RAM will be used?',
  //       id: 'ram',
  //       type: 'number',

  //       selected: 0,
  //       unitSelected: 1,
  //       numberSelected: 0,
  //       options: {
  //         min: 1,
  //         step: 1,
  //       },
  //     },
  //   ],
  // },
  {
    name: 'Frontend',
    info: '',
    id: 'frontend',
    calculation: true,
    price: 0,
    input: [
      {
        name: 'Will there be a frontend?',
        id: 'frontend',
        type: 'boolean',
        selected: true,
        options: {
          true: 'Yes',
          false: 'No',
        },
      },
      {
        name: 'How complex is the frontend?',
        id: 'complexity',
        type: 'selection',

        selected: 'simple',
        options: [
          {
            name: 'Simple',
            value: 'simple',
          },
          {
            name: 'Moderate',
            value: 'moderate',
          },
          {
            name: 'Complex',
            value: 'complex',
          },
          {
            name: 'Very Complex',
            value: 'very-complex',
          },
        ],
      },
      {
        name: 'How often is the frontend viewed?',
        description: 'How often per Hour.',
        id: 'viewed',
        type: 'number',

        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_HOUR[0].value,
        numberSelected: 0,
        options: {
          min: 1,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_HOUR,
      },
    ],
  },
  {
    name: 'Backend',
    info: '',
    id: 'backend',
    calculation: true,
    price: 0,
    input: [
      {
        name: 'How often is a function executed?',
        id: 'time',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 1,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'How many function do exist?',
        id: 'requests',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 1,
          step: 1,
        },
      },
      {
        name: 'How complex are the functions?',
        id: 'complexity',
        type: 'selection',
        selected: 'simple',
        options: [
          {
            name: 'Simple',
            value: 'simple',
          },
          {
            name: 'Moderate',
            value: 'moderate',
          },
          {
            name: 'Complex',
            value: 'complex',
          },
          {
            name: 'Very Complex',
            value: 'very-complex',
          },
        ],
      },
      {
        name: 'Will there be a notification service?',
        id: 'notifications',
        type: 'selection',

        selected: '',
        options: [
          {
            name: 'None',
            value: 'none',
          },
          {
            name: 'Simple Queue Service',
            value: 'simple-queue-service',
          },
          {
            name: 'Simple Email Service',
            value: 'simple-email-service',
          },
          {
            name: 'Simple Notification Service',
            value: 'simple-notification-service',
          },
        ],
      },
    ],
  },
];
