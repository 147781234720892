import '@sick-davinci/core-elements/lib/header2/Header';
import '@sick-davinci/core-elements/lib/header2/header-button/HeaderButton';
import '@sick-davinci/core-elements/lib/header2/header-popdown/HeaderPopdown';

import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FunctionButton as CelHeaderFunctionButton } from '@sick-davinci/core-elements/lib/header/model';
import { icon_feedback } from '@sick-davinci/material-icons/action/feedback.icon';
import { icon_help } from '@sick-davinci/material-icons/action/help.icon';
import { icon_home } from '@sick-davinci/material-icons/action/home.icon';
import { icon_more_vert } from '@sick-davinci/material-icons/navigation/more_vert.icon';
import { OAuthService } from 'angular-oauth2-oidc';
import { DialogService } from 'primeng/dynamicdialog';

import { AppInitializerService } from '../../core/services/app-initializer.service';
import { ToastService } from '../../core/toast/toast.service';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';

@Component({
  selector: 'cloud-cost-calculation-tool-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  // shown in the header of the application
  appName = 'SICK Cloud Cost Tool';
  loggedIn = false;

  headerButtons: Array<CelHeaderFunctionButton> = [
    {
      id: 'home',
      iconSrc: icon_home,
      title: 'Home',
    },
    {
      id: 'help',
      iconSrc: icon_help,
      title: 'Help',
    },
    {
      id: 'feedback',
      iconSrc: icon_feedback,
      title: 'Feedback',
    },
    {
      id: 'more',
      iconSrc: icon_more_vert,
      title: 'More',
    },
  ];

  constructor(
    private router: Router,
    private authService: OAuthService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private http: HttpClient,
    appInitializer: AppInitializerService,
  ) {
    appInitializer.loggedIn$.subscribe((loggedIn) => (this.loggedIn = loggedIn));
  }

  headerButtonClicked(button: CelHeaderFunctionButton): void {
    switch (button.id) {
      case 'home':
        this.router.navigate(['/']);
        break;
      case 'help':
        this.dialogService.open(InformationDialogComponent, {
          header: 'Help Information',
          width: '50%',
          contentStyle: {
            'max-height': '80vh',
            'overflow-y': 'scroll',
          },
        });
        break;
      case 'feedback':
        this.dialogService.open(FeedbackDialogComponent, {
          header: 'Give Feedback',
          width: '50%',
          contentStyle: {
            'max-height': '80vh',
            'overflow-y': 'scroll',
          },
        });
        break;
      case 'more':
        break;
      default:
        this.toastService.add({ summary: `Button ${button.id} clicked`, severity: 'info' });
        break;
    }
  }

  loadLink(link: string): void {
    window.open(link, '_blank');
  }

  onLoginClicked(): void {
    this.authService.initLoginFlow();
  }

  onLogoutClicked(): void {
    this.authService.logOut();
  }
}
