import { CloudServiceSection, UNIT_SIZE_AS_GB_PER_MONTH } from '@cloud-cost-calculation-tool/base-cloud-services';

export const S3_BUCKET_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'S3 Standard',
    id: 's3-standard',
    calculation: true,
    price: 0,
    info: 'S3 Standard is best used for general-purpose storage of frequently accessed data.',
    input: [
      {
        name: 'S3 Standard storage',
        id: 'storage',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
      {
        name: 'PUT, COPY, POST, LIST requests to S3 Standard',
        id: 'write-requests',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
      },
      {
        name: 'GET, SELECT, and all other requests from S3 Standard',
        id: 'read-requests',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
      },
      {
        name: 'Data returned by S3 Select',
        id: 'returned',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
      {
        name: 'Data scanned by S3 Select',
        id: 'scanned',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
    ],
  },
];
