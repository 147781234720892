import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AddAwsProjectComponent } from './components/addProject/add-aws-project/add-aws-project.component';
import { ProjectHandlerService } from './project-handler.service';
import { ProjectNotificationService } from './project-notification.service';

@NgModule({
  imports: [CommonModule],
  providers: [ProjectHandlerService, ProjectNotificationService],
  declarations: [AddAwsProjectComponent],
  exports: [AddAwsProjectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProjectAnalysisModule {}
