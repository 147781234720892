<div class="content">
  <cloud-cost-calculation-tool-analysis-navigation
    class="content__header"
    [projectList]="projects"
    (addProject)="addProject()"
    (editModeClicked)="changeEditMode()"
    (addElementClicked)="addElement()"
    (changeDashboard)="changeDashboard($event)"
    (editClicked)="editProject()"
    (changeProject)="changeProject($event)"
  ></cloud-cost-calculation-tool-analysis-navigation>

  <davinci-callout *ngIf="status === 'empty'" class="content__banner">
    No Projects found. Please add your Project.
  </davinci-callout>
  <davinci-spinner *ngIf="status === 'loading'" class="spinner" below label="Loading..."></davinci-spinner>
  <div class="content__body">
    <dashboard-builder-default-dashboard
      *ngIf="status === 'dashboard' && dashboard"
      class="content__body"
      #dbb
      [(rootNode)]="dashboard"
      [settings]="settings"
      [editMode]="editMode"
    ></dashboard-builder-default-dashboard>
  </div>
</div>
