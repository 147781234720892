import { cloneDeep } from 'lodash-es';
import { Subject } from 'rxjs';

import { BASIC_SECTIONS_CONFIGURATION } from './globals/basic-sections';
import {
  CloudServiceSection,
  CloudServicesProvider,
  ICloudServiceConfiguartionInformation,
  IServiceConfiguration,
  IServiceConfigurationElement,
  ServiceOptionInput,
} from './types';

export abstract class AbstractCloudServiceBase {
  private $sections: Array<CloudServiceSection>;
  private $price = 0;

  currentPrice: Subject<number> = new Subject<number>();
  id: string;
  name: string;
  description?: string;
  cloudProvider: CloudServicesProvider;
  protected abstract priceItems: unknown;

  // This will be implemented by the abstract provider
  abstract updatePriceInformations(): Promise<void>;

  // This will be implemented by the service and called from updatePriceInformations from the provider
  protected abstract calculatePrices(): Promise<void>;

  // This will fetch current prices if needed
  protected abstract fetchPrices(): Promise<void>;

  protected abstract setupClient(): void;

  constructor(args: ICloudServiceConfiguartionInformation, serviceConfiguration: Array<IServiceConfiguration>) {
    this.id = args.id;
    this.name = args.name;
    this.description = args.description;
    this.cloudProvider = args.provider;
    this.$sections = [...cloneDeep(BASIC_SECTIONS_CONFIGURATION), ...args.sections];
    this.getInput('basic', 'region').options = args.regions as Array<{
      value: string;
      name: string;
    }>;
    this.setupClient();
    this.applyServiceConfiguration(serviceConfiguration);
    this.updateSelectionForSpecifications();
    this.fetchPrices().then(() => {
      this.calculatePrices();
    });
  }

  get sections(): Array<CloudServiceSection> {
    return this.$sections;
  }

  protected set sections(sections: Array<CloudServiceSection>) {
    this.$sections = sections;
  }

  get price(): number {
    return this.$price;
  }

  private set price(price: number) {
    this.$price = price;
  }

  private getSection(sectionId: string): CloudServiceSection {
    const section = this.sections.find((section) => section.id === sectionId);
    if (!section) {
      throw new Error(`Section ${sectionId} not found`);
    }
    return section;
  }

  protected getInput(sectionId: string, inputId: string): ServiceOptionInput {
    const section = this.getSection(sectionId);
    const input = section?.input.find((section) => section.id === inputId);
    if (!input) {
      throw new Error(`Input ${inputId} not found in section ${sectionId}`);
    }
    return input;
  }

  getSelected(sectionId: string, inputId: string): string | number | boolean {
    const input = this.getInput(sectionId, inputId);
    return input.selected;
  }

  // This will be called from the service to update the prices
  protected updatePrices(prices: Array<number>): void {
    this.updateSectionPrices(prices);
    this.updateTotalPrice();
  }

  private updateSectionPrices(prices: Array<number>): void {
    let priceIndex = 0;
    this.sections.forEach((section) => {
      if (section.calculation) {
        section.price = prices[priceIndex];
        priceIndex++;
      }
    });
    if (priceIndex !== prices.length) {
      throw new Error('Price array length does not match section count');
    }
    return;
  }

  private updateTotalPrice(): void {
    this.price = 0;
    this.sections.forEach((section) => {
      this.price += section.price;
    });
    this.currentPrice.next(this.price);
    return;
  }

  private updateSelectionForSpecifications(): void {
    this.sections.forEach((section) => {
      section.input.forEach((input) => {
        if (
          input.type === 'number' &&
          ((input.options.min && input.numberSelected < input.options.min) ||
            (input.options.max && input.numberSelected > input.options.max))
        ) {
          input.numberSelected = input.options.min || input.options.max || 0;
        }
        if (input.type === 'number' && input.units && input.unitSelected) {
          input.selected = input.numberSelected * input.unitSelected;
        }
        if (input.type === 'selection' && input.options.length > 0 && input.selected === '') {
          input.selected = input.options[0].value;
        }
      });
    });
  }

  private applyServiceConfiguration(serviceConfigurationElements: Array<IServiceConfiguration>): void {
    serviceConfigurationElements.forEach((serviceConfig) => {
      const input = this.getInput(serviceConfig.sectionId, serviceConfig.inputId);

      if (input.type === 'number' && input.units && input.unitSelected) {
        input.numberSelected = serviceConfig.value as number;
        input.unitSelected = serviceConfig.unit as number;
      } else {
        input.selected = serviceConfig.value;
      }
    });
  }

  extractServiceConfiguration(): IServiceConfigurationElement {
    const serviceConfigurationElement: IServiceConfigurationElement = {
      serviceId: this.id,
      serviceName: this.name,
      serviceDescription: this.description,
      serviceConfiguration: [],
    };
    this.sections.forEach((section) => {
      section.input.forEach((input) => {
        if (input.type === 'number' && input.units && input.unitSelected) {
          serviceConfigurationElement.serviceConfiguration.push({
            sectionId: section.id,
            inputId: input.id,
            unit: input.unitSelected,
            value: input.numberSelected,
          });
        } else {
          serviceConfigurationElement.serviceConfiguration.push({
            sectionId: section.id,
            inputId: input.id,
            value: input.selected,
          });
        }
      });
    });
    return serviceConfigurationElement;
  }
}
