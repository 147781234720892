import { DefaultSickCloudService } from '../default-sick-service.class';

export class AssetHubService extends DefaultSickCloudService {
  get priceFetchingConfigurations(): { [key: string]: string } {
    return {};
  }

  async calculatePrices(): Promise<void> {
    let price = 0;
    const tier = this.getSelected('subscription-tier', 'tier');
    if (tier === 'SICKAssetHub-Free') {
      price = 0;
    } else if (tier === 'SICKAssetHub-Standard') {
      price = 199;
    } else if (tier === 'SICKAssetHub-Enterprise') {
      price = 999;
    }
    this.updatePrices([price]);
  }
}
