import { IPriceElement } from '@cloud-cost-calculation-tool/base-cloud-services';
import { CostElement } from '@cloud-cost-calculation-tool/project-analysis';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';

export const exportCostElementAsCsv = (ar: Array<CostElement>, name = 'costs'): void => {
  const parsingArray = ar.map((el) => ({ timestamp: new Date(el.startTime), costs: el.costs }));
  createCsvFromArray(parsingArray, name);
};

const createCsvFromArray = (ar: Array<unknown>, name: string): void => {
  const parser = new Parser();
  const csv = parser.parse(ar);
  const blob = new Blob([csv], { type: 'text/csv' });
  saveAs(blob, name + '.csv');
};

export const exportEstimationAsCsv = (ar: Array<IPriceElement>, name = 'estimation'): void => {
  let parsingArray: Array<{ service: string; section: string; option: string; value: unknown }> = [];
  for (const el of ar) {
    for (const section of el.cloudService.sections) {
      const estimationVariables = section.input.map((input) => ({
        service: el.name,
        section: section.name,
        option: input.name,
        value: input.selected,
      }));
      parsingArray = parsingArray.concat(estimationVariables);
    }
  }
  parsingArray.push({
    service: 'Total',
    section: '',
    option: '',
    value: ar.reduce((acc, element) => acc + element.cloudService.price, 0),
  });
  createCsvFromArray(parsingArray, name);
};
