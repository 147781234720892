import { RootNode } from '@sick-curie/dashboard-builder';

export const getIndexForHeaderText = (dashboard: RootNode, headerText: string): number => {
  return dashboard.children.findIndex((child) => child.element.properties.header === headerText);
};

export const setHeaderText = (dashboard: RootNode, index: number, headerText: string): void => {
  dashboard.children[index].element.properties.header = headerText;
};

export const setDashboardElementValue = (dashboard: RootNode, index: number, value: number): void => {
  dashboard.children[index].child.element.properties['value'] = value;
};

export const setDashboardElementValues = (dashboard: RootNode, index: number, value: Array<Array<number>>): void => {
  dashboard.children[index].child.element.properties['values'] = value;
};

export const setDashboardElementXAxis = (dashboard: RootNode, index: number, xAxis: Array<string>): void => {
  dashboard.children[index].child.element.properties['xaxis'] = xAxis;
};
