import { Component } from '@angular/core';

import { AppInitializerService } from './core/services/app-initializer.service';

@Component({
  selector: 'cloud-cost-calculation-tool-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SICK Cloud Cost Tool';
  startApp = false;

  constructor(private init: AppInitializerService) {
    init.loggedIn$.subscribe((loggedIn) => (this.startApp = loggedIn));
  }
}
