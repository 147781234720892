import { Component, OnInit } from '@angular/core';
import {
  INotificationAlarm,
  IProjectTopic,
  ProjectHandlerService,
  ProjectNotificationService,
} from '@cloud-cost-calculation-tool/project-analysis';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map } from 'rxjs';

import { ToastService } from '../../core/toast/toast.service';
import { ManageNotificationAlarmComponent } from './components/dialogs/manage-notification-alarm/manage-notification-alarm.component';

@Component({
  selector: 'cloud-cost-calculation-tool-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  topics: Array<IProjectTopic> = [];
  notificationAlarms: Array<INotificationAlarm> = [];
  dialogRef!: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,
    private toastService: ToastService,
    private projectNotificationService: ProjectNotificationService,
    private projectHandlerService: ProjectHandlerService,
  ) {}

  ngOnInit(): void {
    this.projectHandlerService.projectList
      .pipe(
        map((projects) => {
          const projectTopics: Array<IProjectTopic> = [];
          projects.forEach((project) => {
            project.providers.forEach((provider) => {
              projectTopics.push({
                id: project.id + provider.id,
                project: project.name,
                provider: provider.name,
                name: `${project.name} - ${provider.name}`,
              });
            });
          });
          return projectTopics;
        }),
      )
      .subscribe((projectTopics: Array<IProjectTopic>) => {
        this.topics = projectTopics;
      });
    this.projectNotificationService.notificationList.subscribe((alarms: Array<INotificationAlarm>) => {
      this.notificationAlarms = alarms;
    });
  }

  addEditAlarm(alarm?: INotificationAlarm): void {
    this.dialogRef = this.dialogService.open(ManageNotificationAlarmComponent, {
      header: 'Manage Notification Alarm',
      width: '50%',
      contentStyle: {
        'max-height': '80vh',
        overflow: 'auto',
      },
      data: {
        topics: this.topics,
        notificationAlarm: alarm,
      },
    });
    this.dialogRef.onClose.subscribe((added: boolean) => {
      if (added) {
        this.toastService.add({ severity: 'success', summary: 'Notfication Alarm added/updated' });
      } else {
        this.toastService.add({ severity: 'warn', summary: 'Process canceled!' });
      }
    });
  }

  deleteAlarm(alarm: INotificationAlarm): void {
    this.projectNotificationService.deleteNotification(alarm.id);
    this.toastService.add({ severity: 'success', summary: 'Notfication Alarm deleted' });
  }
}
