import { DefaultSickCloudService } from '../default-sick-service.class';

export class LiveConnectService extends DefaultSickCloudService {
  get priceFetchingConfigurations(): { [key: string]: string } {
    return {};
  }

  async calculatePrices(): Promise<void> {
    let price = 0;
    const tier = this.getSelected('subscription-type', 'type');
    if (tier === 'LiveConnect-Free') {
      price = 0;
    } else if (tier === 'SICKAssetHub-LiveConnect-Core') {
      price = 50;
    }
    this.updatePrices([price]);
  }
}
