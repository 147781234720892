import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { features, Page } from '../../config/features';

@Component({
  selector: 'cloud-cost-calculation-tool-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  navigation: Array<Page> = features;

  constructor(public router: Router) {}
}
