import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Message } from 'primeng/api';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ToastService {
  private toast$ = new Subject<Message>();

  private close$ = new Subject<void>();

  constructor(@Optional() @SkipSelf() prior: ToastService) {
    if (prior) {
      return prior;
    }
  }

  // public toast = this.toast$.asObservable();
  // public close = this.close$.asObservable();

  get toast(): Observable<Message> {
    return this.toast$.asObservable();
  }
  get close(): Observable<void> {
    return this.close$.asObservable();
  }

  add(message: Message): void {
    this.toast$.next(message as Message);
  }

  closeToasts(): void {
    this.close$.next();
  }
}
