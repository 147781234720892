import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPriceElement, IPriceEstimationProject, IPricingBackendObject } from '@cloud-cost-calculation-tool/base-cloud-services';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';

import { BACKEND_PATHS } from '../../../../apps/frontend/src/app/config/globals';
import { CloudServicesFactoryService } from './cloud-services-factory.service';

@Injectable()
export class PriceEstimationFetcherService {
  private userId: string;
  protected projectsMap: Map<string, IPriceEstimationProject> = new Map<string, IPriceEstimationProject>();
  protected selectedEstimationProject!: string;

  public projectMapSubject: Subject<Map<string, IPriceEstimationProject>> = new Subject<Map<string, IPriceEstimationProject>>();
  public selectedEstimationProjectSubject: Subject<string> = new Subject<string>();

  constructor(
    private httpClient: HttpClient,
    private authService: OAuthService,
    private cloudServiceFactoryService: CloudServicesFactoryService,
  ) {
    const user: { email?: string } = this.authService.getIdentityClaims();
    this.userId = user.email ? user.email : '';
    //  create inital map entry
    this.fetchProjects().then(async (projects) => {
      this.setPriceEstimations(projects);
    });
  }

  public changeProject(id: string): void {
    this.selectedEstimationProject = id;
    this.selectedEstimationProjectSubject.next(this.selectedEstimationProject);
  }

  protected async updateAndSetPriceEstimations(): Promise<void> {
    const projectsMap = await this.updatePriceEstimations();
    await this.setPriceEstimations(projectsMap);
  }

  private async setPriceEstimations(projectsMap: Map<string, IPriceEstimationProject>): Promise<void> {
    if (projectsMap.size === 0) {
      this.projectsMap = new Map<string, IPriceEstimationProject>().set('default', {
        name: 'My Estimation List',
        id: 'default',
        elements: [],
      });
      // Because nothing in backend => set Default Estimation as first Entry and call same function again
      await this.updateAndSetPriceEstimations();
    } else {
      this.projectsMap = projectsMap;
      // If selected is not present, selected first estimation
      // Otherwise publish selected and list
      if (!this.projectsMap.has(this.selectedEstimationProject)) {
        this.selectedEstimationProject = this.projectsMap.keys().next().value;
      }
      this.selectedEstimationProjectSubject.next(this.selectedEstimationProject);
      this.projectMapSubject.next(this.projectsMap);
    }
  }

  private async fetchProjects(): Promise<Map<string, IPriceEstimationProject>> {
    // return promise that resolves to the fetched projects
    return new Promise<Map<string, IPriceEstimationProject>>((resolve, reject) => {
      this.httpClient
        .get(BACKEND_PATHS.ESTIMATIONS, { params: { name: this.userId } })
        .subscribe((response: { data?: Array<IPricingBackendObject> }) => {
          const backendObject: Array<IPricingBackendObject> = response.data || [];
          resolve(this.createMapFromBackendObject(backendObject));
        });
    });
  }

  // Delete a single estimation from the list of all estimtion
  private async updatePriceEstimations(): Promise<Map<string, IPriceEstimationProject>> {
    // return Promise with the new List of Estimations
    return new Promise<Map<string, IPriceEstimationProject>>((resolve, reject) => {
      this.httpClient
        .put(BACKEND_PATHS.ESTIMATIONS, {
          name: this.userId,
          data: JSON.stringify(this.createBackendObject()),
        })
        .subscribe((response: { data?: string }) => {
          const backendObject = JSON.parse(response.data || '[]');
          resolve(this.createMapFromBackendObject(backendObject));
        });
    });
  }

  private createBackendObject(): Array<IPricingBackendObject> {
    const backendObject: Array<IPricingBackendObject> = [];
    // Get all values
    const estimationProjectsArray = Array.from(this.projectsMap.values());
    estimationProjectsArray.forEach((project) => {
      backendObject.push({
        name: project.name,
        id: project.id,
        services: project.elements.map((service) => {
          return service.cloudService.extractServiceConfiguration();
        }),
      });
    });
    return backendObject;
  }

  private createMapFromBackendObject(backendObject: Array<IPricingBackendObject>): Map<string, IPriceEstimationProject> {
    const map = new Map<string, IPriceEstimationProject>();
    backendObject.forEach((project) => {
      map.set(project.id, {
        name: project.name,
        id: project.id,
        elements: project.services.map((service) => {
          return {
            name: service.serviceName,
            uuid: service.serviceId,
            description: service.serviceDescription,
            // Create Cloud Service for config and price loading
            cloudService: this.cloudServiceFactoryService.createCloudServiceFactory(
              service.serviceId,
              service.serviceConfiguration,
            ),
          };
        }),
      });
    });
    // iterate over map to set Name and Description correct
    map.forEach((project) => {
      project.elements = project.elements.map((element) => {
        return this.setPriceEstimationName(element);
      });
    });
    return map;
  }

  protected setPriceEstimationName(element: IPriceElement): IPriceElement {
    // Check if custom name is present
    if (element.cloudService.getSelected('basic', 'custom-name')) {
      element.description = ((element.name + ' (' + element.cloudService.getSelected('basic', 'region')) as string) + ')';
      element.name = element.cloudService.getSelected('basic', 'custom-name') as string;
    } else {
      element.description = element.cloudService.getSelected('basic', 'region') as string;
    }
    return element;
  }
}
