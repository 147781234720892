import { CloudServiceSection, UNIT_AMOUNT_PER_MONTH } from '@cloud-cost-calculation-tool/base-cloud-services';

export const AURORA_SERVERLESS_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Aurora Serverless settings',
    id: 'instance',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Database Type',
        id: 'databaseType',
        type: 'selection',
        selected: 'MySQL',
        options: [
          {
            name: 'MySQL',
            value: 'MySQL',
          },
          {
            name: 'PostgreSQL',
            value: 'PostgreSQL',
          },
        ],
      },
      {
        name: 'Aurora Serverless Version',
        id: 'version',
        type: 'selection',
        selected: 'Aurora:ServerlessUsage',
        options: [
          {
            name: 'Aurora Serverless v1.0',
            value: 'Aurora:ServerlessUsage',
          },
          {
            name: 'Aurora Serverless v2.0',
            value: 'Aurora:ServerlessV2Usage',
          },
        ],
      },
      {
        name: 'Number of Aurora Capacity Units (ACUs)',
        id: 'aurora-capacity-units',
        type: 'number',
        selected: 1,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 1,
        options: {
          min: 1,
          max: 256,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
    ],
  },
];
