import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { StepsModule } from 'primeng/steps';

import { SharedModule } from '../../../shared/shared.module';
import { ConfigurationWindowComponent } from './configuration-window.component';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';

@NgModule({
  declarations: [ConfigurationWindowComponent, HeaderBarComponent, FooterBarComponent],
  imports: [SharedModule, StepsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ConfigurationWindowComponent],
})
export class ConfigurationWindowModule {}
