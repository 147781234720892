import { CloudServiceSection } from '@cloud-cost-calculation-tool/base-cloud-services';

export const ASSET_HUB_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Subscription Tier',
    id: 'subscription-tier',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Tier',
        description: '',
        id: 'tier',
        type: 'selection',
        selected: '',
        options: [
          {
            name: 'Free Tier',
            value: 'SICKAssetHub-Free',
          },
          {
            name: 'Standard Tier',
            value: 'SICKAssetHub-Standard',
          },
          {
            name: 'Enterprise Tier',
            value: 'SICKAssetHub-Enterprise',
          },
        ],
      },
    ],
  },
];
