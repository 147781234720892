export interface Page {
  id: string;
  label: string;
  route: string;
  image: string;
}

export const features: Array<Page> = [
  {
    id: 'estimation',
    route: '/estimation',
    label: 'APP.NAVIGATION.ESTIMATION',
    image: 'assets/images/estimation.png',
  },
  {
    id: 'analysis',
    route: '/analysis',
    label: 'APP.NAVIGATION.MONITORING',
    image: 'assets/images/monitoring.png',
  },
  {
    id: 'notifications',
    route: '/notifications',
    label: 'APP.NAVIGATION.ALARMS',
    image: 'assets/images/notifications.png',
  },
];
