/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { BACKEND_PATHS } from '../../config/globals';
import { ToastService } from '../../core/toast/toast.service';

@Component({
  selector: 'cloud-cost-calculation-tool-add-project-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent {
  feedback = '';
  mail = '';

  constructor(
    public ref: DynamicDialogRef,
    private http: HttpClient,
    private toastService: ToastService,
    private authService: OAuthService,
  ) {
    const user: { email?: string } = this.authService.getIdentityClaims();
    this.mail = user.email ? user.email : '';
  }

  cancel(): void {
    this.ref.close(false);
  }

  async send(): Promise<void> {
    try {
      this.http.post(BACKEND_PATHS.FEEDBACK, { feedback: this.feedback, mail: this.mail }).subscribe((response) => {
        if (response) {
          this.toastService.add({
            summary: 'Feedback sent',
            severity: 'success',
          });
          this.ref.close({ shouldSend: true, feedback: this.feedback });
        } else {
          this.toastService.add({
            summary: 'Feedback could not be sent',
            severity: 'error',
          });
        }
      });
    } catch (error) {
      this.toastService.add({
        summary: 'Feedback could not be sent',
        severity: 'error',
      });
    }
  }
}
