<div class="content">
  <div class="content__section">
    <davinci-text-field
      class="content__section--input"
      label="Project Name"
      [value]="project.name"
      (change)="changeNickname($event)"
    ></davinci-text-field>
  </div>
  <div>
    <davinci-callout type="warning"
      >Currently only AWS-Provider are supported. If you want to monitor different Cloud Provider, please sent us
      feedback!</davinci-callout
    >
    <davinci-callout
      >Please note, that the AWS user needs the rights to read the CostExplorer Service. Historic and Predicted
      Prices</davinci-callout
    >
    <div class="content__list-header">
      <h3>Providers*</h3>
      <davinci-button type="bare" (click)="newProvider()">Add Provider</davinci-button>
    </div>
    <cloud-cost-calculation-tool-add-aws-project
      *ngFor="let provider of project.providers; let i = index"
      (deleteProviderEvent)="deleteProvider($event)"
      (updateProviderEvent)="updateProvider($event)"
      [provider]="provider"
    >
    </cloud-cost-calculation-tool-add-aws-project>
  </div>

  <p>*Nickname and at least one Provider is mandatory</p>
</div>
<div class="actions">
  <davinci-button (click)="cancel()">Abbrechen</davinci-button>
  <davinci-button (click)="addProject()" [disabled]="!this.inputValid()">Hinzufügen</davinci-button>
</div>
