import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseCloudServicesModule, CLOUD_SERVICE_INJECTION } from '@cloud-cost-calculation-tool/base-cloud-services';

import { FUNCTIONS } from './injection-configuration';

@NgModule({
  imports: [CommonModule, BaseCloudServicesModule],
  providers: [{ provide: CLOUD_SERVICE_INJECTION, useValue: FUNCTIONS, multi: true }],
})
export class AzureServicesModule {}
