import { Injectable } from '@angular/core';
import { AbstractCloudServiceBase, IServiceConfiguration } from '@cloud-cost-calculation-tool/base-cloud-services';
import { cloneDeep } from 'lodash-es';

import { CloudServicesFetcherService } from './cloud-services-fetcher.service';

@Injectable()
export class CloudServicesFactoryService extends CloudServicesFetcherService {
  createCloudServiceFactory(id: string, serviceConfiguration: Array<IServiceConfiguration>): AbstractCloudServiceBase {
    const defaultConfiguration = cloneDeep(this.defaultConfigurationMap.get(id));
    const cloudService = this.cloudServicesMap.get(id);
    if (!cloudService || !defaultConfiguration) {
      throw new Error('Cloud Service not found');
    }
    return new cloudService(defaultConfiguration, serviceConfiguration);
  }
}
