import '@sick-davinci/basic-elements/lib/text-field/TextField';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AwsProviderProjectTags, CloudProjectProvider } from '@cloud-cost-calculation-tool/project-analysis';

@Component({
  selector: 'cloud-cost-calculation-tool-add-aws-project',
  templateUrl: './add-aws-project.component.html',
  styleUrls: ['./add-aws-project.component.scss'],
})
export class AddAwsProjectComponent {
  @Output()
  updateProviderEvent = new EventEmitter<CloudProjectProvider>();

  @Output()
  deleteProviderEvent = new EventEmitter<CloudProjectProvider>();

  @Input()
  provider!: CloudProjectProvider;

  nickname = '';
  accountKey = '';
  secret = '';
  tags: Array<AwsProviderProjectTags> = [];

  ngOnChanges(): void {
    if (!this.provider) {
      return;
    }
    this.nickname = this.provider.name;
    this.accountKey = this.provider.settings.accountKey;
    this.secret = this.provider.settings.accountSecret;
    this.tags = this.provider.settings.tags;
  }

  changeNickname(event: Event): void {
    this.nickname = (event as CustomEvent).detail;
    this.updateProviderData();
  }

  changeKey(event: Event): void {
    this.accountKey = (event as CustomEvent).detail;
    this.updateProviderData();
  }

  changeSecret(event: Event): void {
    this.secret = (event as CustomEvent).detail;
    this.updateProviderData();
  }

  newTag(): void {
    this.tags.push({ key: '', value: '' });
    this.updateProviderData();
  }

  removeTag(index: number): void {
    this.tags.splice(index, 1);
    this.updateProviderData();
  }

  changeTagKey(event: Event, index: number): void {
    const data = (event as CustomEvent).detail;
    this.tags[index].key = data;
    this.updateProviderData();
  }

  changeTagValue(event: Event, index: number): void {
    const data = (event as CustomEvent).detail;
    this.tags[index].value = data;
    this.updateProviderData();
  }

  deleteProvider(): void {
    this.deleteProviderEvent.emit(this.provider);
  }

  updateProviderData(): void {
    this.updateProviderEvent.emit({
      ...this.provider,
      name: this.nickname,
      settings: {
        accountKey: this.accountKey,
        accountSecret: this.secret,
        tags: this.tags,
        metrics: [],
      },
    });
  }
}
