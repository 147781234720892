import { CloudServicesProvider } from '../types';

export const DEFAULT_CLOUD_SERVICES_PROVIDERS: { [key: string]: CloudServicesProvider } = {
  SIMPLE: {
    name: 'Simple Calculation',
    id: 'simple',
    description: 'Simple Estimation',
  },
  AWS: {
    name: 'Amazon Web Services',
    id: 'aws',
    description: 'Amazon Web Services',
  },
  GCP: {
    name: 'Google Cloud Platform',
    id: 'gcp',
    description: 'Google Cloud Platform',
  },
  AZURE: {
    name: 'Microsoft Azure',
    id: 'azure',
    description: 'Microsoft Azure',
  },
  ALIBABA: {
    name: 'Alibaba Cloud',
    id: 'alibaba',
    description: 'Alibaba Cloud',
  },
  SICKCOM: {
    name: 'Digital Service Catalog',
    id: 'sickcom',
    description: 'Digital Service Catalog',
  },
};
