<div class="section-card" *ngIf="section">
  <div class="section-card__header">
    <h3>{{ section.name }}</h3>
    <davinci-button *ngIf="section.info !== ''" [pTooltip]="section.info" icon="action/help" type="bare"></davinci-button>
  </div>
  <p>{{ section.description }}</p>
  <div class="section-card__content">
    <div class="section-card__content--item input-item" *ngFor="let inputItem of section.input">
      <div class="input-item__title">
        <h4>{{ inputItem.name }}</h4>
        <p>{{ inputItem.description }}</p>
      </div>
      <davinci-numeric-field
        class="input-item__input"
        *ngIf="inputItem.type === 'number' && !inputItem.units"
        [value]="inputItem.selected"
        [min]="inputItem.options.min"
        [max]="inputItem.options.max"
        [ticks]="inputItem.options.step"
        (input)="onOptionChange($event, inputItem)"
      ></davinci-numeric-field>
      <davinci-drop-down
        class="input-item__input"
        *ngIf="inputItem.type === 'selection'"
        (change)="onOptionChange($event, inputItem)"
        [value]="inputItem.selected"
      >
        <davinci-option *ngFor="let item of inputItem.options" [value]="item.value">{{ item.name }}</davinci-option>
      </davinci-drop-down>
      <davinci-text-field
        class="input-item__input"
        *ngIf="inputItem.type === 'text'"
        (change)="onOptionChange($event, inputItem)"
        [placeholder]="inputItem.options.placeholder"
        [value]="inputItem.selected"
      >
      </davinci-text-field>
      <davinci-toggle-group
        class="input-item__input"
        [value]="inputItem.selected"
        *ngIf="inputItem.type === 'boolean'"
        (change)="onOptionChange($event, inputItem)"
      >
        <davinci-button toggle [value]="false">{{ inputItem.options.false }}</davinci-button>
        <davinci-button toggle [value]="true">{{ inputItem.options.true }}</davinci-button>
      </davinci-toggle-group>
      <div *ngIf="inputItem.type === 'number' && inputItem.units" class="input-item__divided">
        <davinci-numeric-field
          [value]="inputItem.numberSelected"
          [min]="inputItem.options.min"
          [ticks]="inputItem.options.step"
          (input)="onNumberInputChange($event, inputItem)"
        ></davinci-numeric-field>
        <davinci-drop-down (change)="onSpecificationInputChange($event, inputItem)" [value]="inputItem.unitSelected">
          <davinci-option *ngFor="let item of inputItem.units" [value]="item.value">{{ item.name }}</davinci-option>
        </davinci-drop-down>
      </div>
    </div>
    <h5 class="section-card__footer" *ngIf="section.calculation">Estimation: {{ section.price | currency }}</h5>
  </div>
</div>
