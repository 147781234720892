import { DefaultSickCloudService } from '../default-sick-service.class';

export class MonitoringBoxService extends DefaultSickCloudService {
  get priceFetchingConfigurations(): { [key: string]: string } {
    return {};
  }

  async calculatePrices(): Promise<void> {
    let price = 0;
    const tier = this.getSelected('subscription-type', 'type');
    if (tier === 'MonitoringBox-UpTo10') {
      price = 375;
    } else if (tier === 'MonitoringBox-UpTo5') {
      price = 225;
    } else if (tier === 'MonitoringBox-UpTo3') {
      price = 150;
    }
    this.updatePrices([price]);
  }
}
