import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
// Components
import { EstimationContainerComponent } from './components/estimation/estimation-container/estimation-container.component';
import { EstimationElementComponent } from './components/estimation/estimation-element/estimation-element.component';
import { NameEstimationProjectDialogComponent } from './components/estimation/name-estimation-project-dialog/name-estimation-project-dialog.component';
import { ConfigurationWindowModule } from './configuration-window/configuration-window.module';
import { EstimationComponent } from './estimation.component';

@NgModule({
  declarations: [
    EstimationComponent,
    EstimationContainerComponent,
    EstimationElementComponent,
    NameEstimationProjectDialogComponent,
  ],
  imports: [SharedModule, ConfigurationWindowModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EstimationModule {}
