import '@sick-davinci/core-elements/lib/navigation/Navigation';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CloudProjectProvider, Project, ProjectHandlerService } from '@cloud-cost-calculation-tool/project-analysis';
import { ToastService } from 'apps/frontend/src/app/core/toast/toast.service';

@Component({
  selector: 'cloud-cost-calculation-tool-analysis-navigation',
  templateUrl: './analysis-navigation.component.html',
  styleUrls: ['./analysis-navigation.component.scss'],
})
export class AnalysisNavigationComponent {
  @Input()
  projectList: Array<Project> = [];

  @Output()
  addElementClicked = new EventEmitter<void>();

  @Output()
  editModeClicked = new EventEmitter<void>();

  @Output()
  addProject = new EventEmitter<void>();

  @Output()
  changeDashboard = new EventEmitter<string>();

  @Output()
  editClicked = new EventEmitter<void>();

  @Output()
  changeProject = new EventEmitter<string>();

  cloudProvider: Array<{ id: string; name: string }> = [];
  providerId = 'overview';
  projectId = '';

  constructor(private toastService: ToastService, private projectHandlerService: ProjectHandlerService) {
    this.projectHandlerService.selectedProject.subscribe((project) => {
      if (!project) {
        return;
      }
      this.projectId = project.project.id;
      this.cloudProvider = project.project.providers.map((p: CloudProjectProvider) => ({ id: p.id, name: p.name }));
      if (project.project.providers.length >= 2) {
        this.cloudProvider = [{ id: 'overview', name: 'Overview' }, ...this.cloudProvider];
      }
      this.updateDashboard(this.cloudProvider[0].id);
    });
  }

  onAddElementClicked(): void {
    this.toastService.add({ severity: 'success', summary: 'Add Element', detail: 'Element added' });
    this.addElementClicked.emit();
  }

  toggleEditMode(): void {
    this.toastService.add({ severity: 'success', summary: 'Edit Mode', detail: 'Edit mode toggled' });
    this.editModeClicked.emit();
  }

  changeTimeLine(): void {
    this.toastService.add({ severity: 'success', summary: 'Timeline', detail: 'Timeline changed' });
  }

  updateDashboard(id: string): void {
    this.changeDashboard.emit(id);
    this.providerId = id;
  }

  projectSelected(e: Event): void {
    const id: string = (e as CustomEvent).detail;
    if (id === 'addProject') {
      this.addProject.emit();
      return;
    }
    this.changeProject.emit(id);
  }

  editProject(): void {
    this.editClicked.emit();
  }

  deleteProject(): void {
    if (!this.projectId) {
      return;
    }
    this.projectHandlerService.deleteProject(this.projectId);
    this.toastService.add({ severity: 'success', summary: 'Delete Project', detail: 'Project deleted' });
  }

  exportCurrentData(): void {
    this.projectHandlerService.exportCurrentProvider();
    this.toastService.add({ severity: 'success', summary: 'Export', detail: 'Data exported' });
  }
}
