import { CloudServiceSection, UNIT_AMOUNT_PER_MONTH, UNIT_SIZE_AS_GB } from '@cloud-cost-calculation-tool/base-cloud-services';

export const CLOUD_WATCH_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Metrics',
    id: 'metrics',
    calculation: true,
    price: 0,
    description: 'The calculations below exclude free tier discounts.',
    info: 'Metric pricing is prorated by the hour and our calculation assumes customers are sending custom metrics at least once an hour each day of the month.',
    input: [
      {
        name: 'Number of Metrics (includes detailed and custom metrics)',
        id: 'number-of-metrics',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
    ],
  },
  {
    name: 'APIs',
    id: 'apis',
    calculation: true,
    price: 0,
    description: 'The calculations below exclude free tier discounts.',
    info: 'You can request up to five statistics for the same metric in a single GetMetricData API request. Additional statistics are billed as an additional metric.',
    input: [
      {
        name: 'GetMetricData: Number of metrics requested',
        id: 'getmetricdata-number-of-metrics-requested',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
      {
        name: 'GetMetricWidgetImage: Number of metrics requested',
        id: 'getmetricwidgetimage-number-of-metrics-requested',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
      {
        name: 'Number of other API requests',
        description:
          'GetMetricStatistics, ListMetrics, PutMetricData, GetDashboard, ListDashboards, PutDashboard and DeleteDashboards requests are the other request types which are billed at the same price.',
        id: 'number-of-other-api-requests',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
    ],
  },
  {
    name: 'Logs',
    id: 'logs',
    calculation: true,
    price: 0,
    description: 'The calculations below exclude free tier discounts.',
    info: 'The Amazon CloudWatch Logs service allows you to collect and store logs from your resources, applications, and services in near real-time. There are three main categories of logs 1) Vended logs. 2) Logs that are published by AWS services. Currently over 30 AWS services publish logs to CloudWatch. 3) Custom logs.',
    input: [
      {
        name: 'Standard Logs: Data Ingested',
        id: 'standard-logs-data-ingested',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
      {
        name: 'Logs Delivered to CloudWatch Logs: Data Ingested',
        id: 'logs-delivered-to-cloudwatch-logs-data-ingested',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
      {
        name: 'Log Storage/Archival (Standard and Vended Logs)',
        id: 'archive-logs',
        type: 'selection',
        selected: '',
        options: [
          {
            name: 'No Storage/Archival',
            value: 'no',
          },
          {
            name: 'Store Logs: Assuming 1 month retention',
            value: 'store',
          },
        ],
      },
      {
        name: 'Logs Delivered to S3: Data Ingested',
        id: 'logs-delivered-to-s3-data-ingested',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
      {
        name: 'Logs Delivered to S3: Format Converted to Apache Parquet',
        id: 'logs-converted-to-apache-parquet',
        type: 'selection',
        selected: 'disabled',
        options: [
          {
            name: 'Disabled',
            value: 'disabled',
          },
          {
            name: 'Enabled',
            value: 'enabled',
          },
        ],
      },
    ],
  },
  {
    name: 'Dashboards and Alarms',
    id: 'dashboards-and-alarms',
    calculation: true,
    price: 0,
    description: 'The calculations below exclude free tier discounts.',
    info: 'Dashboards enable you to create re-usable graphs and visualize your cloud resources and applications in a unified view. Alarms allow you to set a threshold on metrics and trigger an action.',
    input: [
      {
        name: 'Number of Dashboards',
        id: 'dashboards',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000,
          step: 1,
        },
      },
      {
        name: 'Number of Standard Resolution Alarm Metrics',
        description: 'Standard Resolution Alarms (60 seconds).',
        id: 'standard-resolution-alarm-metrics',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000,
          step: 1,
        },
      },
      {
        name: 'Number of High Resolution Alarm Metrics',
        description: 'High Resolution Alarm (10 seconds).',
        id: 'high-resolution-alarm-metrics',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
      {
        name: 'Number of composite alarms',
        description:
          'Composite alarms can combine any type of CloudWatch alarm. Metric alarms are billed based on the number of metrics per alarm, while composite alarms are billed per alarm unit.',
        id: 'composite-alarms',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
    ],
  },
  {
    name: 'Canaries',
    id: 'canaries',
    calculation: true,
    price: 0,
    description: 'The calculations below exclude free tier discounts.',
    info: 'Canaries in CloudWatch Synthetics are configurable scripts that follow the same routes and perform the same actions as a customer. They give you an outside-in view of your customers’ experiences, and your service’s availability from their point of view.',
    input: [
      {
        name: 'Number of Canary runs',
        description: '',
        id: 'canary-runs',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
    ],
  },
  {
    name: 'Lambda Insights',
    id: 'lambda-insights',
    calculation: true,
    price: 0,
    description: 'The calculations below exclude free tier discounts.',
    info: 'All CloudWatch metrics are prorated on an hourly basis. If your function is invoked less than once per hour, your function will only be billed for the hours that it is invoked. There are no minimum fees or mandatory service usage. If the function is not invoked, you do not pay.',
    input: [
      {
        name: 'Number of Lambda functions',
        id: 'lambda-functions',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
      {
        name: 'Number of requests per function',
        id: 'requests-per-function',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
    ],
  },
];
