import {
  Filter,
  GetAttributeValuesCommand,
  GetAttributeValuesCommandOutput,
  GetProductsCommand,
  GetProductsCommandInput,
  GetProductsCommandOutput,
  PricingClient,
} from '@aws-sdk/client-pricing';
import { AbstractCloudServiceBase } from '@cloud-cost-calculation-tool/base-cloud-services';
import * as AWS from 'aws-sdk';
import { v4 } from 'uuid';

import { AwsPriceDimension, AwsPriceItem } from './types';

export abstract class DefaultAwsCloudService extends AbstractCloudServiceBase {
  abstract get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } };

  private selectedRegion = '';
  private client!: PricingClient;

  protected get LOCATION_FILTER(): Filter {
    return {
      Field: 'regionCode',
      Type: 'TERM_MATCH',
      Value: this.getSelected('basic', 'region') as string,
    };
  }

  protected priceItems: { [key: string]: AwsPriceItem } = {};

  protected setupClient(): void {
    this.client = new PricingClient({
      region: 'us-east-1',
      credentials: new AWS.Credentials({
        accessKeyId: 'AKIARCN2YOBC52FARAEO',
        secretAccessKey: '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',
      }),
    });
  }

  async updatePriceInformations(): Promise<void> {
    if (this.selectedRegion !== this.getSelected('basic', 'region')) {
      this.selectedRegion = this.getSelected('basic', 'region') as string;
      await this.fetchPrices();
    }
    this.calculatePrices();
    return;
  }

  protected async fetchPrices(): Promise<void> {
    await this.fetchServiceSpecificValues();
    await this.fetchDataFromConfiguration();
  }

  protected async fetchServiceSpecificValues(): Promise<void> {
    return;
  }

  private async fetchDataFromConfiguration(): Promise<void> {
    const promiseArray: Array<Promise<GetProductsCommandOutput>> = [];
    const configurationKeys = Object.keys(this.priceFetchingConfigurations);
    configurationKeys.forEach((configurationKey) => {
      const params: GetProductsCommandInput = {
        ServiceCode: this.priceFetchingConfigurations[configurationKey].awsServiceCode,
        Filters: this.priceFetchingConfigurations[configurationKey].filter,
      };
      promiseArray.push(this.fetchPromise(params));
    });
    const fetchArray: Array<GetProductsCommandOutput> = await Promise.all(promiseArray);

    this.addOutputToPriceList(fetchArray, configurationKeys);
  }

  protected async fetchPromise(params: GetProductsCommandInput): Promise<GetProductsCommandOutput> {
    return this.client.send(new GetProductsCommand(params));
  }

  protected async getParamOptions(field: string, serviceCode: string): Promise<GetAttributeValuesCommandOutput> {
    if (!this.client) {
      this.setupClient();
    }
    return await this.client.send(new GetAttributeValuesCommand({ ServiceCode: serviceCode, AttributeName: field }));
  }

  protected calculatePrice(key: string, value: number): number {
    const priceItem: AwsPriceItem = this.priceItems[key];
    const onDemandTerms = priceItem.terms.OnDemand;
    const priceArray: Array<AwsPriceDimension> = [];
    if (!onDemandTerms) throw new Error('OnDemand Terms not found');
    Object.keys(onDemandTerms).forEach((onDemandTermKey) => {
      const termDescription = onDemandTerms[onDemandTermKey];
      if (!termDescription.priceDimensions) throw new Error('priceDimensions not found');
      Object.keys(termDescription.priceDimensions).forEach((priceDimensionKey) => {
        priceArray.push(termDescription.priceDimensions[priceDimensionKey]);
      });
    });
    const price: number = this.priceWithTiers(priceArray, value);
    return price;
  }

  protected findPriceItemAndCalculatePrice(
    attribute: string,
    attributeValue: string,
    value: number,
    exclude?: string,
    exclude2?: string,
  ): number {
    const priceItemKey = this.filterProductList(attribute, attributeValue, exclude, exclude2);
    if (!priceItemKey) {
      return 0;
    }
    return this.calculatePrice(priceItemKey, value);
  }

  private priceWithTiers(priceArray: Array<AwsPriceDimension>, value: number): number {
    // convert begin, end and price to number
    const mappedPrices = this.convertPriceArray(priceArray);
    // sort priceArray by begin
    let price = 0;
    let i = 0;
    let remainingValue = value;
    while (i < mappedPrices.length) {
      if (remainingValue > mappedPrices[i].end) {
        const maxValue = mappedPrices[i].end - mappedPrices[i].begin;
        price += mappedPrices[i].price * maxValue;
        remainingValue -= maxValue;
      } else if (remainingValue > mappedPrices[i].begin) {
        price += mappedPrices[i].price * remainingValue;
        remainingValue = 0;
      }
      i++;
    }
    return price;
  }

  private convertPriceArray(priceArray: Array<AwsPriceDimension>): Array<{ begin: number; end: number; price: number }> {
    const mappedPrices = priceArray.map((item) => {
      const begin = item.beginRange ? (item.beginRange === 'Inf' ? Infinity : parseInt(item.beginRange, 10)) : 0;
      const end = item.endRange ? (item.endRange === 'Inf' ? Infinity : parseInt(item.endRange, 10)) : 0;
      return {
        begin: begin,
        end: end,
        price: parseFloat(item.pricePerUnit['USD']),
      };
    });
    return mappedPrices.sort((a, b) => a.begin - b.begin);
  }

  private addOutputToPriceList(fetchArray: Array<GetProductsCommandOutput>, keys: Array<string>): void {
    fetchArray.forEach((product, index) => {
      if (product.PriceList) {
        if (product.PriceList.length > 1) {
          product.PriceList.forEach((priceItem) => {
            this.priceItems[v4()] = JSON.parse(priceItem as string);
          });
        } else {
          const key = keys[index] ? keys[index] : v4();
          this.priceItems[key] = JSON.parse(product.PriceList[0] as string);
        }
      }
    });
  }

  private filterProductList(attribute: string, value: string, exclude?: string, exclude2?: string): string | undefined {
    let key = undefined;
    Object.keys(this.priceItems).forEach((priceItemKey) => {
      if (
        this.priceItems[priceItemKey].product.attributes[attribute].includes(value) &&
        (!exclude || !this.priceItems[priceItemKey].product.attributes[attribute].includes(exclude)) &&
        (!exclude2 || !this.priceItems[priceItemKey].product.attributes[attribute].includes(exclude2))
      ) {
        key = priceItemKey;
      }
    });
    return key;
  }
}
