import { DefaultSickCloudService } from '../default-sick-service.class';

export class RoleManagementService extends DefaultSickCloudService {
  get priceFetchingConfigurations(): { [key: string]: string } {
    return {};
  }

  async calculatePrices(): Promise<void> {
    this.updatePrices([]);
  }
}
