import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';

export class ApiGatewayService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    return {
      complete: {
        awsServiceCode: 'AmazonApiGateway',
        filter: [this.LOCATION_FILTER],
      },
    };
  }

  async calculatePrices(): Promise<void> {
    const httpPrice = this.findPriceItemAndCalculatePrice('operation', 'ApiGatewayHttpApi', this.retrieveHttpRequests());
    const restPrice = this.findPriceItemAndCalculatePrice('operation', 'ApiGatewayRequest', this.retrieveRestRequests());
    const wsPrice = this.findPriceItemAndCalculatePrice('usagetype', 'ApiGatewayMessage', this.retrieveWsRequests());
    const wsMinutePrice = this.findPriceItemAndCalculatePrice('usagetype', 'ApiGatewayMinute', this.retrieveWsMinutes());

    this.updatePrices([httpPrice, restPrice, wsPrice + wsMinutePrice]);
  }

  private retrieveHttpRequests(): number {
    const messagesPerRequest = Math.ceil((this.getSelected('http-api', 'duration') as number) / 512);
    const requestsPerMonth: number = this.getSelected('http-api', 'requests') as number;
    const requests = requestsPerMonth * messagesPerRequest;
    return requests;
  }

  private retrieveRestRequests(): number {
    const requests = this.getSelected('rest-api', 'requests') as number;
    return requests;
  }

  private retrieveWsRequests(): number {
    // Get messages per request
    const messagesPerRequest = Math.ceil((this.getSelected('websocket-api', 'messages') as number) / 32);
    const requestsPerMonth: number = this.getSelected('websocket-api', 'size') as number;
    const requests = requestsPerMonth * messagesPerRequest;
    return requests;
  }

  private retrieveWsMinutes(): number {
    // Convert time to minutes and the multiple my amount per month
    const connectionMinutesPerMonth =
      (this.getSelected('websocket-api', 'duration') as number) *
      60 *
      (this.getSelected('websocket-api', 'duration-rate') as number);
    return connectionMinutesPerMonth;
  }
}
