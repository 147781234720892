import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { ManageNotificationAlarmComponent } from './components/dialogs/manage-notification-alarm/manage-notification-alarm.component';
import { NotificationAlarmListItemComponent } from './components/notification-alarm-list-item/notification-alarm-list-item.component';
import { NotificationsComponent } from './notifications.component';

@NgModule({
  declarations: [NotificationsComponent, ManageNotificationAlarmComponent, NotificationAlarmListItemComponent],
  imports: [SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotificationsModule {}
