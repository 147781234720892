<div class="content">
  <h2 class="content__header">Basic Usage</h2>
  You can use this application without an subscribtion. All you need is an SICK ID. You can log in and then select your feature
  that you want to use.<br />You can also export the data that is displayed by clicking the export button on the screen. If you
  need help you can always look for the help icons, indicated by a questionmark. Those give you additional information on what you
  see and how you can interact with the application.
  <h2 class="content__header">Features</h2>
  At the moment the application consists of two features, the estimation and the analysis.<br />With the estimation you can create
  an estimation for your services and it will give you the real prices fetched from the cloud provider based on your configuration
  (e.q. amount of requests or amount of data stored).<br />The analysis feature is able to show the historic and estimaed costs
  for an AWS project. You can give your Project an name and add as many Accounts to it as you wish. Account costs can be filtered
  by tags. This helps to only monitor the costs that are made by the specific project (if you have multiple projects in one
  account)
  <h3 class="content__header">Estimation</h3>
  The estimation is divided in two sections, the service (left) and the estimation (right) section.<br />The estimation section
  shows you all your configured services, their configuration and their costs. You can download, edit and delete that list.<br />The
  service section gives you a list of services but you are also able to filter and search for services.<br />If you select an
  service it will show all configuration options on the service section side. You can always give your service a custom name and
  then configure it to fit your needs. There is also always a real-time estimation of what the service would cost with this
  specific configuration each month.
  <h3 class="content__header">Analysis</h3>
  The analysis Feature is providing detailed information on your AWS Cloud costs.<br />Each Project consists of a name and a
  variable amount of providers. Providers are identified by key and secret, but you can also set tags that should be filtered by.
  Each provider has their own dashboard, but if there is also a summary dashboard that combines all costs from all providers that
  you have added.<br />Furthermore, you can export the data for a specific provider/dashboard or change the configuration. There
  is no limit of projects. You can track as many as you want and select the project that you want to investigate with the
  dropdown.
  <h3 class="content__header">Notifications</h3>
  You can also set up notifications for your projects. You can set a threshold and a notification type. If the threshold is met
  the notification will be sent. You can also set a time interval for the notifications. This means that you will only get
  notified once the threshold is passed in that specific time range. <br />
  You can invite other users to your project and they will also get notified if the threshold is passed.
  <h3 class="content__header">Limitations</h3>
  <ol>
    <li>
      Currently you are only able to monitor AWS Projects/Account. If you need another Service Provider let us know throught the
      Feedback functionality. We are happy to please you!
    </li>
    <li>
      The Estimation is only available for selected AWS Services and for the Digital Service Catalog. If you would like to
      calculate another Service just let us know through the Feedback functionality. We are happy to please you!
    </li>
  </ol>
</div>
<div class="actions">
  <davinci-button (click)="cancel()">Close</davinci-button>
</div>
