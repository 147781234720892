import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'cloud-cost-calculation-tool-name-estimation-project-dialog',
  templateUrl: './name-estimation-project-dialog.component.html',
  styleUrls: ['./name-estimation-project-dialog.component.scss'],
})
export class NameEstimationProjectDialogComponent {
  name = 'New Estimation Project';

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.name = this.config.data.name;
  }

  changeNickname(event: Event): void {
    this.name = (event as CustomEvent).detail;
  }

  cancel(): void {
    this.ref.close({ create: false });
  }

  saveName(): void {
    this.ref.close({ create: true, name: this.name });
  }
}
