import {
  CloudServiceSection,
  UNIT_AMOUNT_PER_MONTH,
  UNIT_SIZE_AS_GB_PER_MONTH,
  UNIT_SIZE_AS_KB,
} from '@cloud-cost-calculation-tool/base-cloud-services';

export const SIMPLE_EMAIL_SERVICE_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Service settings',
    id: 'service-settings',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Email messages sent from EC2',
        id: 'email-messages-sent-from-ec2',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'Data sent from EC2',
        id: 'data-sent',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
      {
        name: 'Email messages sent from email client',
        id: 'email-messages-sent-from-client',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'Data sent from email client',
        id: 'data-sent-from-email-client',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
      {
        name: 'Email messages received',
        id: 'email-messages-received',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'Average size of email received',
        id: 'average-size',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_KB[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_KB,
      },
      {
        name: 'Number of dedicated IP addresses',
        id: 'dedicated-ip-addresses',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          max: 100000,
          step: 1,
        },
      },
    ],
  },
];
