import { Filter } from '@aws-sdk/client-pricing';
import { CloudServiceSection, UNIT_SIZE_AS_GB_PER_MONTH } from '@cloud-cost-calculation-tool/base-cloud-services';

export const AWS_DEFAULT_DATA_TRANSFER_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Data Transfer',
    id: 'data-transfer',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Inbound Data Transfer',
        description: 'Enter the data you expect to transfer into your Service.',
        id: 'inbound',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
      {
        name: 'Outbound Data Transfer',
        description: 'Enter the data you expect to transfer out of your Service.',
        id: 'outbound',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
    ],
  },
];

export const AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER: { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } = {
  inboundTransfer: {
    awsServiceCode: 'AWSDataTransfer',
    filter: [{ Field: 'usagetype', Type: 'TERM_MATCH', Value: 'DataTransfer-In-Bytes' }],
  },
  outboundTransfer: {
    awsServiceCode: 'AWSDataTransfer',
    filter: [{ Field: 'usagetype', Type: 'TERM_MATCH', Value: 'DataTransfer-Out-Bytes' }],
  },
};

export const inboundIds = ['data-transfer', 'inbound'];
export const outboundIds = ['data-transfer', 'outbound'];
