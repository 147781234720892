export const timeToMonthString = (time: number, estimated = false): string => {
  const date = new Date(time);
  return `${date.getMonth() + 1}/${date.getFullYear()}${estimated ? '*' : ''}`;
};

export const timeToDayString = (time: number, estimated = false): string => {
  const date = new Date(time);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}${estimated ? '*' : ''}`;
};

export const timeToHourString = (time: number, estimated = false): string => {
  const date = new Date(time);
  return `${date.getHours()} ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}${estimated ? '*' : ''}`;
};
