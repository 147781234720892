import { Environment } from './environment-model';

export const environment: Environment = {
  production: false,

  oidc: {
    clientId: 'cloud-cost-tool',
    issuer: 'https://id.test.sick.com/auth/realms/sickservices',
    responseType: 'code',
    scope: 'openid profile email offline_access',
    redirectUri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
    logoutUrl: `${window.location.origin}`,
    showDebugInformation: true,
  },
  backend: {
    url: 'https://31cb364h3h.execute-api.eu-central-1.amazonaws.com/dev',
  },
};
