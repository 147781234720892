import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Granularity } from '@aws-sdk/client-cost-explorer';
import { OAuthService } from 'angular-oauth2-oidc';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { BACKEND_PATHS } from '../../../../apps/frontend/src/app/config/globals';
import { buildDashboard, dashboards } from './dashboards';
import { exportCostElementAsCsv } from './helper';
import { AwsService } from './providers/aws.service';
import { CostElement, CurrentProject, DisplayInformationElement, DisplayInformationGroup, Project } from './types';

@Injectable()
export class ProjectHandlerService {
  private projects$: Array<Project> = [];
  private userId: string;

  private currentProject: CurrentProject | undefined;

  private currentProjectObservable = new BehaviorSubject<CurrentProject | undefined>(undefined);

  private projectListObservable = new BehaviorSubject<Array<Project>>([]);

  get selectedProject(): Observable<CurrentProject | undefined> {
    return this.currentProjectObservable.asObservable();
  }

  get projects(): Array<Project> {
    return this.projects$;
  }

  private set projects(projects: Array<Project>) {
    this.projects$ = projects;
    this.projectListObservable.next(this.projects$);
  }

  get projectList(): Observable<Array<Project>> {
    return this.projectListObservable.asObservable();
  }

  constructor(private httpClient: HttpClient, private authService: OAuthService) {
    const user: { email?: string } = this.authService.getIdentityClaims();
    this.userId = user.email ? user.email : '';
    this.fetchProjects();
  }

  private async fetchProjects() {
    this.httpClient
      .get(BACKEND_PATHS.MONITORING, { params: { name: this.userId } })
      .subscribe((response: { data?: Array<Project> }) => {
        if (!response.data) {
          this.projects = [];
          this.selectProject('');
        } else {
          this.projects = response.data;
          this.selectProject(this.projects$[0].id);
        }
      });
  }

  projectAmount(): number {
    return this.projects$.length;
  }

  async addProject(project: Project): Promise<Project> {
    this.projects = [...this.projects, project];
    await this.httpClient
      .put(BACKEND_PATHS.MONITORING, { name: this.userId, data: JSON.stringify(this.projects) })
      .subscribe((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        return project;
      });
    return project;
  }

  async selectProject(id: string): Promise<void> {
    // Clear selection
    this.currentProjectObservable.next(undefined);

    // Set new project
    const project: Project | undefined = this.projects$.find((p) => p.id === id);
    if (project) {
      this.currentProject = await this.buildCurrentProject(project);
    }
    this.currentProjectObservable.next(this.currentProject);
  }

  async deleteProject(id: string): Promise<void> {
    this.projects$ = this.projects$.filter((project) => project.id !== id);
    this.httpClient
      .put(BACKEND_PATHS.MONITORING, {
        name: this.userId,
        data: JSON.stringify(this.projects$),
      })
      .subscribe((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
      });
    if (this.projectAmount() === 0) {
      await this.selectProject('');
    } else if (this.currentProject && this.currentProject.project.id === id) {
      await this.selectProject(this.projects$[0].id);
    }
  }

  updateProviderId(id: string): void {
    if (!this.currentProject) {
      return;
    }
    this.currentProject.currentProviderId = id;
  }

  async buildCurrentProject(project: Project): Promise<CurrentProject> {
    const currentProject: CurrentProject = {
      project: project,
      currentProviderId: '',
      displayInformation: {},
    };
    try {
      currentProject.displayInformation = await this.fetchDisplayInformationCosts(project);
      currentProject.displayInformation = this.buildDashboards(currentProject.displayInformation);

      if (Object.keys(currentProject.displayInformation).length >= 2) {
        this.calculateOverviewValues(currentProject.displayInformation);
      }
      currentProject.currentProviderId = Object.keys(currentProject.displayInformation)[0];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return currentProject;
  }

  async fetchDisplayInformationCosts(project: Project): Promise<DisplayInformationGroup> {
    const displayInformation: DisplayInformationGroup = {};

    const fetchingService = new AwsService();
    for (const provider of project.providers) {
      // Set providerspecifig Values
      displayInformation[provider.id] = {
        dashboard: cloneDeep(dashboards[provider.provider]),
        provider: provider.provider,
        costs: {
          monthly: [],
          daily: [],
          weekly: [],
        },
      };
      fetchingService.registerAccount(provider.settings);
      fetchingService.setAndFilter(provider.settings.tags);
      // Fetch all Values
      [
        displayInformation[provider.id].costs.monthly,
        displayInformation[provider.id].costs.daily,
        displayInformation[provider.id].costs.weekly,
      ] = await Promise.all([
        fetchingService.calculateCosts(
          new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000),
          new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
          Granularity.MONTHLY,
        ),
        fetchingService.calculateCosts(
          new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
          new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000),
          Granularity.DAILY,
        ),
        fetchingService.calculateCosts(
          new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
          new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000),
          Granularity.DAILY,
        ),
      ]);
    }
    return displayInformation;
  }

  buildDashboards(displayInformation: DisplayInformationGroup): DisplayInformationGroup {
    Object.keys(displayInformation).forEach((providerId) => {
      displayInformation[providerId].dashboard = buildDashboard[displayInformation[providerId].provider](
        displayInformation[providerId],
      );
    });
    return displayInformation;
  }

  calculateOverviewValues(displayInformation: DisplayInformationGroup): DisplayInformationGroup {
    const overview: DisplayInformationElement = {
      dashboard: cloneDeep(dashboards['overview']),
      provider: 'overview',
      costs: {
        monthly: [],
        daily: [],
        weekly: [],
      },
    };
    Object.keys(displayInformation).forEach((providerId) => {
      displayInformation[providerId].costs.monthly.forEach((cost) => {
        this.addCostsToCostArray(overview.costs.monthly, cost);
      });
      displayInformation[providerId].costs.daily.forEach((cost) => {
        this.addCostsToCostArray(overview.costs.daily, cost);
      });
      displayInformation[providerId].costs.weekly.forEach((cost) => {
        this.addCostsToCostArray(overview.costs.weekly, cost);
      });
    });
    overview.dashboard = buildDashboard['overview'](overview);
    displayInformation['overview'] = overview;
    return displayInformation;
  }

  private addCostsToCostArray(costArray: Array<CostElement>, cost: CostElement): void {
    const index = costArray.findIndex((e) => e.startTime === cost.startTime);
    if (index === -1) {
      costArray.push(cloneDeep(cost));
    } else {
      costArray[index].costs = parseInt('' + cost.costs, 10) + parseInt('' + costArray[index].costs, 10);
    }
  }

  async exportCurrentProvider(): Promise<void> {
    if (!this.currentProject) {
      return;
    }
    exportCostElementAsCsv(
      this.currentProject.displayInformation[this.currentProject.currentProviderId].costs.monthly,
      'monthly',
    );
    exportCostElementAsCsv(this.currentProject.displayInformation[this.currentProject.currentProviderId].costs.daily, 'daily');
    exportCostElementAsCsv(this.currentProject.displayInformation[this.currentProject.currentProviderId].costs.weekly, 'groups');
  }
}
