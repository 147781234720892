export interface Project {
  id: string;
  name: string;
  providers: Array<CloudProjectProvider>;
}

export type CloudProjectProvider = AwsProjectProvider;

export type ProviderString = 'aws' | 'azure' | 'gcp' | 'other';

export interface BaseProjectProvider {
  name: string;
  provider: ProviderString;
  id: string;
  settings: unknown;
}

export interface AwsProjectProvider extends BaseProjectProvider {
  provider: 'aws';
  settings: AwsProviderProjectSettings;
}

export interface AwsProviderProjectSettings {
  accountKey: string;
  accountSecret: string;
  tags: Array<AwsProviderProjectTags>;
  metrics: Array<{ key: string; value: string }>;
}

export interface AwsProviderProjectTags {
  key: string;
  value: string;
}
