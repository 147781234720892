<div class='configuration-window-container'>
  <cloud-cost-calculation-tool-header-bar [step]='sectionStep'
                                          (verticalModeSelectedChangeEvent)='verticalModeSelectedChanged($event)'></cloud-cost-calculation-tool-header-bar>
  <div class='configuration-window-container__list' *ngIf='selectedService; else noServiceSelected'>
    <cloud-cost-calculation-tool-section-card
      [section]='section'
      *ngFor='let section of shownSections; let i = index'
      (optionChangeEvent)='updateSelectionAndCalculation(section.id)'
    >
    </cloud-cost-calculation-tool-section-card>
  </div>
  <ng-template #noServiceSelected>
    <davinci-callout class='configuration-window-container__empty'>
      No Service Selected. Please select a service from the list.
    </davinci-callout>
  </ng-template>
  <cloud-cost-calculation-tool-footer-bar (leftClickedEvent)='leftClicked()' (rightClickedEvent)='rightClicked()'
                                          [showPrice]='selectedService !== undefined' [priceAmount]='currentPrice'
                                          [left]='left' [right]='right'></cloud-cost-calculation-tool-footer-bar>
</div>
