import { CloudServicesProvider } from './cloud-services-provider';

export interface ICloudService {
  id: string;
  name: string;
  description?: string;
  cloudProvider: CloudServicesProvider;

  updatePriceInformations(): Promise<void>;

  get sections(): Array<CloudServiceSection>;

  get price(): number;

  getSelected(sectionId: string, inputId: string): string | number | boolean;
}

export interface ICloudServiceConfiguartionInformation {
  sections: Array<CloudServiceSection>;
  regions: Array<ICloudServiceRegion>;
  name: string;
  id: string;
  description?: string;
  provider: CloudServicesProvider;
}

export interface ICloudServiceRegion {
  value: string;
  name: string;
}

export interface IServiceConfigurationElement {
  serviceId: string;
  serviceName: string;
  serviceDescription?: string;
  serviceConfiguration: Array<IServiceConfiguration>;
}

export interface IServiceConfiguration {
  sectionId: string;
  inputId: string;
  unit?: number;
  value: string | number | boolean;
}

export interface CloudServiceSection {
  name: string;
  id: string;
  description?: string;
  info: string;
  calculation: boolean;
  price: number;
  input: Array<ServiceOptionInput>;
}

export type ServiceOptionInput =
  | ServiceOptionBooleanInput
  | ServiceOptionSelectionInput
  | ServiceOptionNumberInput
  | ServiceOptionTextInput;

export interface ServiceOptionInputInterface {
  name: string;
  id: string;
  description?: string;
  type: 'boolean' | 'number' | 'selection' | 'text';
  selected: string | number | boolean;
}

export interface ServiceOptionBooleanInput extends ServiceOptionInputInterface {
  type: 'boolean';
  selected: boolean;
  options: {
    true: string;
    false: string;
  };
}

export interface ServiceOptionTextInput extends ServiceOptionInputInterface {
  type: 'text';
  selected: string;
  options: {
    placeholder: string;
  };
}

export interface ServiceOptionSelectionInput extends ServiceOptionInputInterface {
  type: 'selection';
  options: Array<{
    name: string;
    value: string;
    [key: string]: string;
  }>;
}

export interface ServiceOptionNumberInput extends ServiceOptionInputInterface {
  type: 'number';
  selected: number;
  options: {
    min?: number;
    max?: number;
    step: number;
  };
  unitSelected: number | undefined;
  numberSelected: number;
  units?: Array<SpecificationInput>;
}

export interface SpecificationInput {
  name: string;
  value: number;
}
