import { DefaultSickCloudService } from '../default-sick-service.class';

export class DynamicDataDisplayService extends DefaultSickCloudService {
  get priceFetchingConfigurations(): { [key: string]: string } {
    return {};
  }

  async calculatePrices(): Promise<void> {
    let price = 0;
    const tier = this.getSelected('subscription-type', 'type');
    if (tier === 'DynamicDataDisplay-Free') {
      price = 0;
    } else if (tier === 'DynamicDataDisplay-BUSINESS') {
      price = 100;
    } else if (tier === 'DynamicDataDisplay-MULTIUSER') {
      price = 100;
    }
    this.updatePrices([price]);
  }
}
