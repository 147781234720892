import { Filter } from '@aws-sdk/client-pricing';
import { HOURS_PER_MONTH } from '@cloud-cost-calculation-tool/base-cloud-services';

import { DefaultAwsCloudService } from '../default-aws-service.class';

export class DocumentDbService extends DefaultAwsCloudService {
  private selectedInstance: string | undefined = undefined;

  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AmazonDocDB';
    return {
      instance: {
        awsServiceCode: serviceCode,
        filter: [
          this.LOCATION_FILTER,
          {
            Field: 'instanceType',
            Type: 'TERM_MATCH',
            Value: this.getSelected('instance', 'instance-class') as string,
          },
        ],
      },
      io: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usageType', Type: 'TERM_MATCH', Value: 'StorageIOUsage' }],
      },
      storage: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usageType', Type: 'TERM_MATCH', Value: 'StorageUsage' }],
      },
      backup: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usageType', Type: 'TERM_MATCH', Value: 'BackupUsage' }],
      },
    };
  }

  protected override async fetchServiceSpecificValues(): Promise<void> {
    const output = await this.getParamOptions('instanceType', 'AmazonDocDB');

    const instanceInput = this.getInput('instance', 'instance-class');
    instanceInput.options = output.AttributeValues?.map((item) => {
      return { name: item.Value, value: item.Value };
    }) as Array<{
      name: string;
      value: string;
    }>;
    if (this.selectedInstance !== this.getSelected('instance', 'instance-class')) {
      instanceInput.selected = instanceInput.options[0].value;
      this.selectedInstance = instanceInput.options[0].value as string;
    }
    return;
  }

  protected async calculatePrices(): Promise<void> {
    if (this.selectedInstance !== this.getSelected('instance', 'instance-class')) {
      this.selectedInstance = this.getSelected('instance', 'instance-class') as string;
      await this.fetchPrices();
    }

    const instancePrice = this.calculatePrice('instance', this.retrieveQuantity());
    const ioPrice = this.calculatePrice('io', this.retrieveIOs());
    const storagePrice = this.calculatePrice('storage', this.retrieveStorage());
    const backupPrice = this.calculatePrice('backup', this.retrieveBackupStorage());

    this.updatePrices([instancePrice, ioPrice + storagePrice, backupPrice]);
  }

  private retrieveQuantity(): number {
    //  quantity * hours per month;
    return (this.getSelected('instance', 'quantity') as number) * HOURS_PER_MONTH;
  }

  private retrieveStorage(): number {
    return this.getSelected('storage-and-ios', 'storage') as number;
  }

  private retrieveIOs(): number {
    return (this.getSelected('storage-and-ios', 'ios') as number) * 1000000;
  }

  private retrieveBackupStorage(): number {
    return this.getSelected('backup-storage', 'storage') as number;
  }
}
