/* eslint-disable no-underscore-dangle */
import { Component } from '@angular/core';
import { CloudProjectProvider, Project, ProjectHandlerService } from '@cloud-cost-calculation-tool/project-analysis';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { v4 } from 'uuid';

@Component({
  selector: 'cloud-cost-calculation-tool-add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss'],
})
export class AddProjectDialogComponent {
  project: Project;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private projectHandlerService: ProjectHandlerService,
  ) {
    this.project = config.data
      ? config.data
      : {
          id: v4(),
          name: '',
          providers: [],
        };
  }

  changeNickname(event: Event): void {
    this.project.name = (event as CustomEvent).detail;
  }

  newProvider(): void {
    this.project.providers.push({
      provider: 'aws',
      id: v4(),
      name: '',
      settings: {
        accountKey: '',
        accountSecret: '',
        tags: [],
        metrics: [],
      },
    });
  }

  deleteProvider(provider: CloudProjectProvider): void {
    this.project.providers = this.project.providers.filter((p) => p !== provider);
  }

  cancel(): void {
    this.ref.close(false);
  }

  async addProject(): Promise<void> {
    const project: Project = await this.projectHandlerService.addProject(this.project);
    this.projectHandlerService.selectProject(project.id);
    this.ref.close(true);
  }

  inputValid(): boolean {
    return this.project.providers.length > 0 && this.providersComplete() && this.project.name !== '';
  }

  providersComplete(): boolean {
    return this.project.providers.every((p) => p.name && p.settings.accountKey && p.settings.accountSecret);
  }

  updateProvider(provider: CloudProjectProvider): void {
    const oldProvider = this.project.providers.find((p) => p.id === provider.id);
    if (oldProvider) {
      oldProvider.name = provider.name;
      oldProvider.settings = provider.settings;
    }
  }
}
