import { CloudServiceSection, UNIT_AMOUNT_PER_MONTH } from '@cloud-cost-calculation-tool/base-cloud-services';

export const SIMPLE_NOTIFICATION_SERVICE_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Service settings',
    id: 'service-settings',
    calculation: true,
    price: 0,
    info: 'Amazon SNS has no upfront costs and you can pay as you go. You pay based on the number of notifications you publish, the number of notifications you deliver, and any additional API calls for managing topics and subscriptions.',
    input: [
      {
        name: 'Requests',
        id: 'requests',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'HTTP/HTTPS Notifications',
        id: 'http-https-notifications',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'EMAIL/EMAIL-JSON Notifications',
        id: 'email-json-notifications',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'SQS Notifications',
        id: 'sqs-notifications',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'AWS Lambda',
        id: 'aws-lambda',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'Amazon Kinesis Data Firehose',
        id: 'kinesis-data-firehose',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
    ],
  },
  {
    name: 'Additional Notification settings',
    id: 'additional-notification-settings',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Mobile Push Notifications',
        id: 'mobile-push-notifications',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
    ],
  },
];
