import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';

export class LambdaService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AWSLambda';
    return {
      storage: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'group', Type: 'TERM_MATCH', Value: 'AWS-Lambda-Storage-Duration' }],
      },
      request: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'group', Type: 'TERM_MATCH', Value: 'AWS-Lambda-Requests' }],
      },
      compute: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'group', Type: 'TERM_MATCH', Value: 'AWS-Lambda-Duration' }],
      },
    };
  }

  async calculatePrices(): Promise<void> {
    const computePrice = this.calculatePrice('compute', this.retrieveCompute());
    const requestPrice = this.calculatePrice('request', this.retrieveRequests());
    const storagePrice = this.calculatePrice('storage', this.retrieveStorage());

    this.updatePrices([computePrice + requestPrice + storagePrice]);
    return;
  }

  private retrieveComputeSeconds(): number {
    return (this.retrieveRequests() as number) * (this.getSelected('service-settings', 'duration') as number) * 0.001;
  }

  private retrieveCompute(): number {
    // Allocated Memory * Compute Seconds
    return (this.getSelected('service-settings', 'memory') as number) * this.retrieveComputeSeconds();
  }

  private retrieveStorage(): number {
    // Billage Storage: Allocated Storage - 0.5GB
    const billageStorage =
      (this.getSelected('service-settings', 'ephemeral-storage') as number) - 0.5 > 0
        ? (this.getSelected('service-settings', 'ephemeral-storage') as number) - 0.5
        : 0;
    // billageStorage * Compute Seconds
    return billageStorage * this.retrieveComputeSeconds();
  }

  private retrieveRequests(): number {
    return this.getSelected('service-settings', 'requests') as number;
  }
}
