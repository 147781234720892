import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentProject, Project, ProjectHandlerService } from '@cloud-cost-calculation-tool/project-analysis';
import { DefaultDashboardComponent } from '@sick-curie/dashboard-builder-default-dashboard';
import {
  DashboardCompactType,
  DashboardDisplayGrid,
  DashboardDisplayType,
  DashboardSettings,
  RootNode,
} from '@sick-curie/dashboard-builder-models';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ToastService } from '../../core/toast/toast.service';
import { AddProjectDialogComponent } from './components/add-project-dialog/add-project-dialog.component';

@Component({
  selector: 'cloud-cost-calculation-tool-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnDestroy {
  @ViewChild('dbb') dbb!: DefaultDashboardComponent;

  currentProject: CurrentProject | undefined;
  projects: Array<Project> = [];
  dialogRef!: DynamicDialogRef;
  status: 'empty' | 'dashboard' | 'loading' | 'error' = 'empty';

  // the settings
  settings: DashboardSettings = {
    width: 1920,
    height: 1080,
    displayType: DashboardDisplayType.Auto,
    gridConfig: {
      pushItems: true,
      compactType: DashboardCompactType.None,
      displayGrid: DashboardDisplayGrid.OnDragAndResize,
    },
    generalPalette: 'primary',
  };

  // whether the dashboard is in edit mode (true) or read-only mode (false)
  editMode = false;

  constructor(
    private dialogService: DialogService,
    private projectHandlerService: ProjectHandlerService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  get dashboard(): RootNode | undefined {
    return this.currentProject?.displayInformation[this.currentProject?.currentProviderId].dashboard;
  }

  set dashboard(dashboard: RootNode | undefined) {
    //  You could get Dashboard information here,
    //  for example the changed notes if you want to modify the dashboard
  }

  ngOnInit(): void {
    this.projectHandlerService.selectedProject.subscribe((currentProject) => {
      this.currentProject = currentProject;
      if (!this.currentProject) {
        this.status = 'loading';
      } else if (Object.keys(this.currentProject.displayInformation).length === 0) {
        this.status = 'error';
      } else {
        this.status = 'dashboard';
      }
      this.setProjects();
    });
  }

  setProjects(): void {
    this.projects = this.projectHandlerService.projects;
    if (this.projects.length === 0) {
      this.status = 'empty';
    }
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  addProject(data?: Project): void {
    this.dialogRef = this.dialogService.open(AddProjectDialogComponent, {
      header: 'Add new Project',
      width: '50%',
      contentStyle: {
        'max-height': '80vh',
        overflow: 'auto',
      },
      data,
    });
    this.dialogRef.onClose.subscribe((added: boolean) => {
      if (added) {
        this.toastService.add({ severity: 'success', summary: 'Project added/updated' });
      } else if (this.projectHandlerService.projectAmount() === 0) {
        this.toastService.add({ severity: 'warn', summary: 'Project Adding Canceled. No Item, returning to Home.' });
        this.router.navigate(['/']);
      }
    });
  }

  changeEditMode(): void {
    this.editMode = !this.editMode;
  }

  addElement(): void {
    this.dbb.openAddElementDialog();
  }

  changeDashboard(id: string): void {
    this.projectHandlerService.updateProviderId(id);
  }

  editProject(): void {
    this.addProject(this.projects.find((p) => p.id === this.currentProject?.project.id));
  }

  changeProject(id: string): void {
    this.projectHandlerService.selectProject(id);
  }
}
