import { Granularity } from 'aws-sdk/clients/costexplorer';

export interface CostElement {
  type: Granularity;
  costs: number;
  estimated: boolean;
  startTime: number;
  start: Date;
  end: Date;
}
