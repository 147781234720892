import { CommonModule, CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

import { SectionCardComponent } from './components/section-card/section-card.component';

@NgModule({
  declarations: [SectionCardComponent],
  imports: [CommonModule, TranslateModule, TooltipModule],
  exports: [TranslateModule, CommonModule, TooltipModule, SectionCardComponent, FormsModule],
  providers: [CurrencyPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
