<div class='notification-list-item'>
  <div class='notification-list-item__information'>
    <h3 class='notification-list-item__information--header'>{{notificationAlarm.name}}</h3>
    <h5 class='notification-list-item__information--subheader'>{{notificationAlarm.topic.name}}</h5>
    <div class='notification-alarm'>
      <div [pTooltip]='"Threshold below " + notificationAlarm.alarm.belowThreshold + "$"'
           class='notification-alarm__information-set'>
        <davinci-icon style='fill: red' icon='action/trending_down'></davinci-icon>
        <p>{{notificationAlarm.alarm.belowThreshold}} $</p>
      </div>
      <div [pTooltip]='"Threshold above " + notificationAlarm.alarm.aboveThreshold + "$"'
           class='notification-alarm__information-set'>
        <davinci-icon style='fill: red' icon='action/trending_up'></davinci-icon>
        <p>{{notificationAlarm.alarm.aboveThreshold}} $</p>
      </div>
      <div [pTooltip]='"In the last " + notificationAlarm.alarm.daysRange + " days"'
           class='notification-alarm__information-set'>
        <davinci-icon icon='action/history'></davinci-icon>
        <p>{{notificationAlarm.alarm.daysRange}} d</p>
      </div>
    </div>
    <h6 class='notification-list-item__information--subscribers'>{{notificationAlarm.subscribers.length}}
      Subscriber(s)</h6>
  </div>
  <div class='notification-list-item__actions'>
    <davinci-button pTooltip='Edit Alarm' (click)='edit()' class='notification-list-item__actions--button' type='bare'
                    icon='image/edit'></davinci-button>
    <davinci-button pTooltip='Delete Alarm' (click)='delete()' class='notification-list-item__actions--button'
                    type='bare'
                    icon='action/delete'></davinci-button>
  </div>
</div>
