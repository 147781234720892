import { CloudServiceSection, UNIT_AMOUNT_PER_MONTH, UNIT_SIZE_AS_GB } from '@cloud-cost-calculation-tool/base-cloud-services';

export const LAMBDA_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Service settings',
    id: 'service-settings',
    calculation: true,
    price: 0,
    info: 'With AWS Lambda, you pay only for what you use. You are charged based on the number of requests for your functions and the duration, the time it takes for your code to execute.',
    input: [
      {
        name: 'Number of requests',
        id: 'requests',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'Duration of each request (in ms)',
        id: 'duration',
        description: 'Duration is calculated from the time your code begins executing until it returns or otherwise terminates.',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          max: 900000,
          step: 1,
        },
      },
      {
        name: 'Amount of memory allocated',
        id: 'memory',
        description: 'Enter the amount between 128 MB and 10 GB',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 10,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
      {
        name: 'Amount of ephemeral storage allocated',
        id: 'ephemeral-storage',
        description:
          'Enter the amount between 512 MB and 10,240 MB. The first 512 MB are at no additional charge, you only pay for any additional storage that you configure for the function.',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 10,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
    ],
  },
];
