import { Component } from '@angular/core';
import { IPriceElement, IPriceEstimationProject } from '@cloud-cost-calculation-tool/base-cloud-services';
import { PriceEstimationProjectsHandlerService } from '@cloud-cost-calculation-tool/cloud-services-manager';
import { ToastService } from 'apps/frontend/src/app/core/toast/toast.service';
import { DialogService } from 'primeng/dynamicdialog';

import { NameEstimationProjectDialogComponent } from '../name-estimation-project-dialog/name-estimation-project-dialog.component';

@Component({
  selector: 'cloud-cost-calculation-tool-estimation-container',
  templateUrl: './estimation-container.component.html',
  styleUrls: ['./estimation-container.component.scss'],
})
export class EstimationContainerComponent {
  selectectedEstimationProject!: IPriceEstimationProject;
  estimationProjects: Array<IPriceEstimationProject> = [];

  priceElements: Array<IPriceElement> = [];
  totalCosts = 0;

  constructor(
    private priceEstimationProjectsHandlerService: PriceEstimationProjectsHandlerService,
    private toastService: ToastService,
    private dialogService: DialogService,
  ) {
    this.priceEstimationProjectsHandlerService.projectMapSubject.subscribe((projects) => {
      this.estimationProjects = Array.from(projects.values());
      this.setProjectAndElements(this.priceEstimationProjectsHandlerService.getSelectedEstimationProject());
    });
    this.priceEstimationProjectsHandlerService.selectedEstimationProjectSubject.subscribe((id) => {
      const project = this.estimationProjects.find((project) => project.id === id);
      if (project) {
        this.setProjectAndElements(project);
      }
    });
  }

  changeEstimationProjectSelection(event: Event): void {
    const project = (event as CustomEvent).detail as string;
    this.priceEstimationProjectsHandlerService.changeProject(project);
  }

  createEstimation(name?: string): void {
    this.dialogService
      .open(NameEstimationProjectDialogComponent, {
        header: 'Set Estimation Project Name',
        width: '50%',
        contentStyle: {
          'max-height': '80vh',
          overflow: 'auto',
        },
        data: {
          name: name || 'New Estimation Project',
        },
      })
      .onClose.subscribe((data: { create: boolean; name?: string }) => {
        if (data.create) {
          this.priceEstimationProjectsHandlerService.addPriceCalculationProject(data.name || 'New Estimation project');
          this.toastService.add({ severity: 'info', summary: 'Estimation created' });
        } else {
          this.toastService.add({ severity: 'info', summary: 'Estimation creation canceled' });
        }
      });
  }

  editEstimation(): void {
    this.dialogService
      .open(NameEstimationProjectDialogComponent, {
        header: 'Edit Estimation Project Name',
        width: '50%',
        contentStyle: {
          'max-height': '80vh',
          overflow: 'auto',
        },
        data: {
          name: this.selectectedEstimationProject!.name,
        },
      })
      .onClose.subscribe(async (data: { create: boolean; name?: string }) => {
        if (data.create) {
          await this.priceEstimationProjectsHandlerService.updateProjectName(
            this.selectectedEstimationProject!.id,
            data.name || 'Estimation project',
          );
          this.toastService.add({ severity: 'info', summary: 'Estimation name updated' });
        } else {
          this.toastService.add({ severity: 'info', summary: 'Estimation name change canceled' });
        }
      });
  }

  deleteEstimations(): void {
    this.priceEstimationProjectsHandlerService.removeProject(this.selectectedEstimationProject.id);
    this.toastService.add({ severity: 'info', summary: 'All Estimations deleted' });
  }

  downloadEstimation(): void {
    this.priceEstimationProjectsHandlerService.downloadEstimation();
    this.toastService.add({ severity: 'info', summary: 'Estimation downloaded' });
  }

  private setProjectAndElements(project: IPriceEstimationProject): void {
    this.selectectedEstimationProject = project;
    this.priceElements = project.elements;
    project.elements.forEach((e) => {
      e.cloudService.currentPrice.subscribe(() => (this.totalCosts = this.priceEstimationProjectsHandlerService.getTotalCosts()));
    });
  }
}
