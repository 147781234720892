import { HttpClient } from '@angular/common/http';
import { AbstractCloudServiceBase } from '@cloud-cost-calculation-tool/base-cloud-services';

export abstract class DefaultSickCloudService extends AbstractCloudServiceBase {
  abstract get priceFetchingConfigurations(): { [key: string]: string };

  private selectedRegion = '';
  private readonly client!: HttpClient;

  protected priceItems: { [key: string]: string } = {};

  async updatePriceInformations(): Promise<void> {
    if (this.selectedRegion !== this.getSelected('basic', 'region')) {
      await this.fetchPrices();
    }
    this.calculatePrices();
    return;
  }

  protected async fetchPrices(): Promise<void> {
    return;
  }

  protected override setupClient(): void {
    //  Setup Client for DSC
  }
}
