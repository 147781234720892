<div class='navigation'>
  <div class='navigation__project'>
    <davinci-core-navigation class='navigation__tabs' type='tree'>
      <davinci-core-navigation-item
        (navigate)='updateDashboard(provider.id)'
        *ngFor='let provider of cloudProvider'
        [active]='provider.id === providerId'
      >{{ provider.name }}</davinci-core-navigation-item
      >
    </davinci-core-navigation>
  </div>
  <div class='navigation__actions'>
    <!-- <davinci-button (click)="onAddElementClicked()" type="bare" icon="action/add">Add</davinci-button>
    <davinci-button (click)="toggleEditMode()" type="bare" icon="action/star">EditMode</davinci-button> -->
    <!--    <p-calendar-->
    <!--      [(ngModel)]='rangeDates'-->
    <!--      [minDate]='minDate'-->
    <!--      [maxDate]='maxDate'-->
    <!--      selectionMode='range'-->
    <!--      [readonlyInput]='true'-->
    <!--      inputId='range'-->
    <!--    ></p-calendar>-->
    <davinci-drop-down (change)='projectSelected($event)'
                       [value]='projectId'>
      <davinci-option *ngFor='let project of projectList' [value]='project.id'>{{ project.name }}</davinci-option>
      <davinci-option value='addProject'>New Project +</davinci-option>
    </davinci-drop-down>
    <davinci-button (click)='editProject()' type='bare' icon='image/edit' pTooltip='Edit Project'></davinci-button>
    <davinci-button (click)='deleteProject()' type='bare' icon='action/delete'
                    pTooltip='Delete Project'></davinci-button>
    <davinci-button (click)='exportCurrentData()' type='bare' icon='file/file_download'
                    pTooltip='Download Current Dashboard Information'></davinci-button>
  </div>
</div>
