<div class="content">
  <h2 class="content__header">We want to improve!</h2>
  <p class="content_text">
    There is a bug, you just want to mention something or you have a feature request? Please use the feedback form below!
  </p>
  <davinci-text-area
    class="content__text-area"
    name="text"
    ngDefaultControl
    [(ngModel)]="feedback"
    placeholder="Please enter your feedback here..."
  ></davinci-text-area>
</div>
<div class="actions">
  <davinci-button type="bare" (click)="cancel()">Close</davinci-button>
  <davinci-button (click)="send()">Send Feedback</davinci-button>
</div>
