import { CloudServiceSection, UNIT_AMOUNT_PER_MONTH } from '@cloud-cost-calculation-tool/base-cloud-services';

export const FUNCTIONS_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Service settings',
    id: 'service-settings',
    calculation: true,
    price: 0,
    info: 'With Azure Functions, you pay only for what you use. You are charged based on the number of requests for your functions and the duration, the time it takes for your code to execute.',
    input: [
      {
        name: 'Number of requests',
        id: 'requests',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
      {
        name: 'Duration of each request (in ms)',
        id: 'duration',
        description: 'Duration is calculated from the time your code begins executing until it returns or otherwise terminates.',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          max: 900000,
          step: 1,
        },
      },
      {
        name: 'Amount of memory allocated',
        id: 'memory',
        description: 'Select the amount',
        type: 'selection',
        selected: '128',
        options: [
          {
            name: '128 MB',
            value: '128',
          },
          {
            name: '256 MB',
            value: '256',
          },
          {
            name: '384 MB',
            value: '384',
          },
          {
            name: '512 MB',
            value: '512',
          },
          {
            name: '640 MB',
            value: '640',
          },
          {
            name: '768 MB',
            value: '768',
          },
          {
            name: '896 MB',
            value: '896',
          },
          {
            name: '1024 MB',
            value: '1024',
          },
          {
            name: '1152 MB',
            value: '1152',
          },
          {
            name: '1280 MB',
            value: '1280',
          },
          {
            name: '1408 MB',
            value: '1408',
          },
          {
            name: '1536 MB',
            value: '1536',
          },
        ],
      },
    ],
  },
];
