import { Component, Input } from '@angular/core';
import { IPriceElement } from '@cloud-cost-calculation-tool/base-cloud-services';
import {
  CloudServicesHandlerService,
  PriceEstimationProjectsHandlerService,
} from '@cloud-cost-calculation-tool/cloud-services-manager';
import { ToastService } from 'apps/frontend/src/app/core/toast/toast.service';

@Component({
  selector: 'cloud-cost-calculation-tool-estimation-element',
  templateUrl: './estimation-element.component.html',
  styleUrls: ['./estimation-element.component.scss'],
})
export class EstimationElementComponent {
  @Input()
  element!: IPriceElement;

  constructor(
    private priceEstimationProjectsHandlerService: PriceEstimationProjectsHandlerService,
    private toastService: ToastService,
    private cloudServicesHandlerService: CloudServicesHandlerService,
  ) {}

  onEdit(): void {
    this.cloudServicesHandlerService.changeService(
      this.element.cloudService.id,
      this.element.cloudService.extractServiceConfiguration().serviceConfiguration,
    );
    this.priceEstimationProjectsHandlerService.removePriceCalculation(this.element.uuid);
    this.toastService.add({ severity: 'info', summary: 'Edit Estimation' });
  }

  onDelete(): void {
    this.priceEstimationProjectsHandlerService.removePriceCalculation(this.element.uuid);
    this.toastService.add({ severity: 'info', summary: 'Estimation deleted' });
  }
}
