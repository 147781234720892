import { HttpClient, HttpParams, HttpXhrBackend } from '@angular/common/http';
import { AbstractCloudServiceBase } from '@cloud-cost-calculation-tool/base-cloud-services';
import { BACKEND_PATHS } from 'apps/frontend/src/app/config/globals';

import {
  DEFAULT_CURRENCY_CODE,
  IAPIResponse,
  IAzurePrice,
  IRetailPrice,
  IRetailPriceFilter,
  PRICE_TYPES,
  SUPPORTED_FILTER_KEYS,
} from './types';

export abstract class DefaultAzureCloudService extends AbstractCloudServiceBase {
  abstract get priceFetchingConfigurations(): {
    [key: string]: {
      filter: IRetailPriceFilter;
    };
  };

  private selectedRegion = '';
  private client!: HttpClient;

  protected get LOCATION_FILTER(): IRetailPriceFilter {
    return {
      armRegionName: this.getSelected('basic', 'region') as string,
    };
  }

  protected priceItems: { [key: string]: IAzurePrice } = {};

  protected setupClient(): void {
    this.client = new HttpClient(
      new HttpXhrBackend({
        build: () => new XMLHttpRequest(),
      }),
    );
  }

  async updatePriceInformations(): Promise<void> {
    if (this.selectedRegion !== this.getSelected('basic', 'region')) {
      this.selectedRegion = this.getSelected('basic', 'region') as string;
      await this.fetchPrices();
    }
    this.calculatePrices();
    return;
  }

  protected async fetchPrices(): Promise<void> {
    await this.fetchServiceSpecificValues();
    await this.fetchDataFromConfiguration();
  }

  protected async fetchServiceSpecificValues(): Promise<void> {
    return;
  }

  private async fetchDataFromConfiguration(): Promise<void> {
    const promiseArray: Array<Promise<Array<IRetailPrice>>> = [];
    const filterArray: Array<IRetailPriceFilter> = [];
    const configurationKeys = Object.keys(this.priceFetchingConfigurations);
    configurationKeys.forEach((configurationKey) => {
      filterArray.push(this.priceFetchingConfigurations[configurationKey].filter);
    });
    for (const filter of filterArray) {
      if (this._validateFilterObject(filter)) {
        const filterString = this._generateFilter(filter);
        promiseArray.push(this._getRetailPrices(filterString));
      }
    }
    const retailPricesArray = await Promise.all(promiseArray);
    // eslint-disable-next-line no-console
    console.log(retailPricesArray, configurationKeys);
    this.addOutputToPriceList(retailPricesArray, configurationKeys);
  }

  protected calculatePrice(key: string, value: number): number {
    const priceItem = this.priceItems[key];
    if (!priceItem) {
      return 0;
    }
    let price = 0;
    let remainingValue = value;
    for (const tier of priceItem.tiers) {
      if (remainingValue > tier.endRange) {
        const maxValue = tier.endRange - tier.startRange;
        price += tier.unitPrice * maxValue;
        remainingValue -= maxValue;
      } else {
        price += tier.unitPrice * remainingValue;
        remainingValue = 0;
      }
    }
    return price;
  }

  // protected findPriceItemAndCalculatePrice(
  //   attribute: string,
  //   attributeValue: string,
  //   value: number,
  //   exclude?: string,
  //   exclude2?: string,
  // ): number {
  //   return 0;
  // }

  private _validateFilterObject(filterObject: IRetailPriceFilter): boolean {
    for (const key of Object.keys(filterObject)) {
      if (!SUPPORTED_FILTER_KEYS.includes(key)) {
        throw new Error(`Unsupported filter key "${key}". Supported keys are ${SUPPORTED_FILTER_KEYS.toString()}.`);
      }
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      if (key === 'priceType' && !PRICE_TYPES.includes(filterObject[key]!)) {
        throw new Error(
          `Unsupported filter value "${filterObject[key]!}" of "${key}". Supported values are ${PRICE_TYPES.toString()}.`,
        );
      }
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    }
    return true;
  }

  private _generateFilter(filterObject: IRetailPriceFilter): string {
    let filterString = '';
    for (const key of Object.keys(filterObject)) {
      filterString += `${key} eq '${String(filterObject[key])}' and `;
    }
    return filterString.replace(/ and $/, '');
  }

  private async _getRetailPrices(
    filterString: string,
    currencyCode: string = DEFAULT_CURRENCY_CODE,
  ): Promise<Array<IRetailPrice>> {
    const params: HttpParams = new HttpParams().set('currencyCode', currencyCode).set('$filter', filterString);
    return await this._fetcherPromise(params);
  }

  private async _fetcherPromise(params: HttpParams): Promise<Array<IRetailPrice>> {
    let retailPrices: Array<IRetailPrice> = [];
    return new Promise((resolve, reject) => {
      this.client
        .get<{ message: string; data?: { statusCode: number; body: string } }>(BACKEND_PATHS.AZURE, { params })
        .subscribe((response) => {
          // eslint-disable-next-line no-console
          console.log(response);
          if (!response.data) {
            reject(new Error('No response from API.'));
            return;
          }
          if (response.data.statusCode !== 200) {
            reject(new Error(`API responded with status code ${response.data.statusCode}.`));
          }
          const azureResponse = JSON.parse(response.data.body) as IAPIResponse;
          retailPrices = retailPrices.concat(azureResponse.Items);
          if (azureResponse.NextPageLink) {
            // eslint-disable-next-line no-console
            console.warn('NextPageLink is not implemented yet.', azureResponse.NextPageLink);
            // cut url to avoid CORS error
            // response.NextPageLink = response.NextPageLink.replace('https://prices.azure.com:443/api/retail/prices', '');

            // this._fetcherPromise(response.NextPageLink).then((nextPageResponse) => {
            //   retailPrices.concat(nextPageResponse);
            //   resolve(retailPrices);
            // });
          } else {
            resolve(retailPrices);
          }
        });
    });
  }

  private addOutputToPriceList(fetchArray: Array<Array<IRetailPrice>>, keys: Array<string>): void {
    const prices: { [key: string]: IAzurePrice } = {};
    keys.forEach((key) => {
      prices[key] = {
        tiers: [],
      };
    });
    fetchArray.forEach((priceArray, index) => {
      priceArray.forEach((priceItem) => {
        const key = keys[index];
        if (prices[key].tiers.length === 0) {
          prices[key].tiers.push({
            unitPrice: priceItem.unitPrice || 0,
            startRange: priceItem.tierMinimumUnits || 0,
            endRange: Infinity,
          });
        } else {
          const min = priceItem.tierMinimumUnits || 0;
          prices[key].tiers.push({
            unitPrice: priceItem.unitPrice || 0,
            startRange: min,
            endRange: Infinity,
          });
          // change last tier endRange to to startRange of current tier if there is a element before
          prices[key].tiers[prices[key].tiers.length - 2].endRange = min;
        }
      });
    });
    this.priceItems = prices;
  }
}
