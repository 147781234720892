import { DisplayInformationElement } from '@cloud-cost-calculation-tool/project-analysis';
import { RootNode } from '@sick-curie/dashboard-builder';

import { alibaba } from './alibaba';
import { aws, calculateAwsDashboard } from './aws';
import { azure } from './azure';
import { dsc } from './dsc';
import { gcp } from './google';
import { calculateOverviewDashboard, overview } from './overview';

export const dashboards: { [key: string]: RootNode } = {
  alibaba,
  aws,
  azure,
  gcp,
  dsc,
  overview,
};

export const buildDashboard: {
  [key: string]: (informationElement: DisplayInformationElement) => RootNode;
} = {
  aws: calculateAwsDashboard,
  overview: calculateOverviewDashboard,
};
