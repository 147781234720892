import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CloudServicesProvider, ICloudServiceMenuItem } from '@cloud-cost-calculation-tool/base-cloud-services';
import { CloudServicesHandlerService } from '@cloud-cost-calculation-tool/cloud-services-manager';
import { cloneDeep } from 'lodash-es';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'cloud-cost-calculation-tool-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent {
  @Input()
  step!: number;

  @Output()
  verticalModeSelectedChangeEvent = new EventEmitter<boolean>();

  verticalModeSelected = false;

  unfilteredServiceList: Array<{ label: string; value: CloudServicesProvider; services: Array<{ id: string; name: string }> }> =
    [];
  filteredServiceList: Array<{ label: string; value: CloudServicesProvider; services: Array<{ id: string; name: string }> }> = [];
  items: Array<MenuItem> = [];

  constructor(private cloudServicesHandlerService: CloudServicesHandlerService) {
    this.unfilteredServiceList = this.buildDropdownMenuItems(
      this.cloudServicesHandlerService.cloudServices,
      this.cloudServicesHandlerService.cloudProviders,
    );
    this.filteredServiceList = cloneDeep(this.unfilteredServiceList);
    this.cloudServicesHandlerService.serviceObservable.subscribe((service) => {
      if (service) {
        this.items = service?.sections.map((section) => {
          return { label: section.name };
        });
      } else {
        this.dropDownValue = '';
        this.items = [];
      }
    });
  }

  changeVerticalModeSelected(e: Event): void {
    this.verticalModeSelected = (e as CustomEvent).detail;
    this.verticalModeSelectedChangeEvent.emit(this.verticalModeSelected);
  }

  dropDownValue = '';

  async cloudServiceSelectedHandler(event: Event): Promise<void> {
    this.filteredServiceList = cloneDeep(this.unfilteredServiceList);
    await this.cloudServicesHandlerService.changeService((event as CustomEvent).detail);
  }

  changeSelectionInput(e: Event): void {
    this.dropDownValue = (e as CustomEvent).detail;
    const filteredServices = this.cloudServicesHandlerService.cloudServices.filter((service) =>
      service.name.toLowerCase().includes(this.dropDownValue.toLowerCase()),
    );
    this.filteredServiceList = this.buildDropdownMenuItems(filteredServices, this.cloudServicesHandlerService.cloudProviders);
  }

  private buildDropdownMenuItems(
    services: Array<ICloudServiceMenuItem>,
    providers: Array<CloudServicesProvider>,
  ): Array<{ label: string; value: CloudServicesProvider; services: Array<{ id: string; name: string }> }> {
    const newDropdown: Array<{ label: string; value: CloudServicesProvider; services: Array<{ id: string; name: string }> }> = [];
    providers.forEach((provider) => {
      newDropdown.push({
        label: provider.name,
        value: provider,
        services: [],
      });
      services.forEach((service) => {
        if (provider.id === service.provider.id) {
          newDropdown[newDropdown.length - 1].services.push({ id: service.id, name: service.name });
        }
      });
    });
    return newDropdown;
  }
}
