import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseCloudServicesModule, CLOUD_SERVICE_INJECTION } from '@cloud-cost-calculation-tool/base-cloud-services';

import {
  API_GATEWAY,
  AURORA_INSTANCE,
  AURORA_SERVERLESS,
  CLOUD_FRONT,
  CLOUD_WATCH,
  DOCUMENT_DB,
  IOT_CORE,
  LAMBDA,
  S3_BUCKET,
  SIMPLE_EMAIL_SERVICE,
  SIMPLE_ESTIMATION,
  SIMPLE_NOTIFICATION_SERVICE,
  SIMPLE_QUEUE_SERVICE,
} from './injection-configuration';

@NgModule({
  imports: [CommonModule, BaseCloudServicesModule],
  providers: [
    { provide: CLOUD_SERVICE_INJECTION, useValue: API_GATEWAY, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: AURORA_INSTANCE, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: AURORA_SERVERLESS, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: CLOUD_FRONT, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: CLOUD_WATCH, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: DOCUMENT_DB, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: IOT_CORE, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: LAMBDA, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: S3_BUCKET, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: SIMPLE_EMAIL_SERVICE, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: SIMPLE_NOTIFICATION_SERVICE, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: SIMPLE_QUEUE_SERVICE, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: SIMPLE_ESTIMATION, multi: true },
  ],
})
export class AwsServicesModule {}
