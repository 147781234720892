import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';
import { AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER, inboundIds, outboundIds } from '../service-configurations';

export class SimpleQueueServiceService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AWSQueueService';
    return {
      standardQueue: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Requests-RBP' }],
      },
      fifoQueue: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Requests-FIFO-RBP' }],
      },
      ...AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER,
    };
  }

  protected async calculatePrices(): Promise<void> {
    const standardQueuePrice = this.calculatePrice('standardQueue', this.retrieveStandardQueueRequests());
    const fifoQueuePrice = this.calculatePrice('fifoQueue', this.retrieveFifoQueueRequests());
    const dataTransferInPrice = this.calculatePrice('inboundTransfer', this.retrieveDataTransferIn());
    const dataTransferOutPrice = this.calculatePrice('outboundTransfer', this.retrieveDataTransferOut());

    this.updatePrices([standardQueuePrice + fifoQueuePrice, dataTransferInPrice + dataTransferOutPrice]);
  }

  private retrieveStandardQueueRequests(): number {
    // Requests as million per month
    return (this.getSelected('service-settings', 'standard-queue-requests') as number) * 1000000;
  }

  private retrieveFifoQueueRequests(): number {
    // Requests as million per month
    return (this.getSelected('service-settings', 'fifo-queue-requests') as number) * 1000000;
  }

  private retrieveDataTransferIn(): number {
    return this.getSelected(inboundIds[0], inboundIds[1]) as number;
  }

  private retrieveDataTransferOut(): number {
    return this.getSelected(outboundIds[0], outboundIds[1]) as number;
  }
}
