import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class AppInitializerService {
  private isLoggedIn$ = new BehaviorSubject(false);

  constructor(private authService: OAuthService) {}

  get loggedIn$(): Observable<boolean> {
    return this.isLoggedIn$.pipe(distinctUntilChanged());
  }

  async initialize(): Promise<unknown> {
    this.authService.configure(environment.oidc);
    this.authService.setupAutomaticSilentRefresh();
    const setLoginState = () =>
      this.isLoggedIn$.next(this.authService.hasValidIdToken() && this.authService.hasValidAccessToken());

    if (!this.authService.hasValidIdToken()) {
      return this.authService.loadDiscoveryDocumentAndLogin().then(setLoginState);
    } else {
      return this.authService.loadDiscoveryDocument().then(setLoginState);
    }
  }
}
