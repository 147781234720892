import { CloudServiceSection } from '../types';

export const BASIC_SECTIONS_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Basic Information',
    info: '',
    id: 'basic',
    calculation: false,
    price: 0,
    input: [
      {
        name: 'Custom Name',
        id: 'custom-name',
        type: 'text',
        description: 'Optional',

        selected: '',
        options: {
          placeholder: 'Enter a custom name for this service',
        },
      },
      {
        name: 'Region',
        id: 'region',
        type: 'selection',
        description: 'Optional',
        selected: '',
        options: [],
      },
    ],
  },
];
