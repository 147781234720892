import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';
import { AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER, inboundIds, outboundIds } from '../service-configurations';

export class S3BucketService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AmazonS3';
    return {
      storage: {
        awsServiceCode: serviceCode,
        filter: [
          this.LOCATION_FILTER,
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'TimedStorage-ByteHrs' },
          { Field: 'volumeType', Type: 'TERM_MATCH', Value: 'Standard' },
        ],
      },
      standardRequests: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Requests-Tier1' }],
      },
      otherRequests: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Requests-Tier2' }],
      },
      dataReturned: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Select-Returned-Bytes' }],
      },
      dataScanned: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Select-Scanned-Bytes' }],
      },
      ...AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER,
    };
  }

  protected async calculatePrices(): Promise<void> {
    const storagePrice = this.calculatePrice('storage', this.retrieveStorage());
    const standardRequestsPrice = this.calculatePrice('standardRequests', this.retrieveStandardRequests());
    const otherRequestsPrice = this.calculatePrice('otherRequests', this.retrieveOtherRequests());
    const dataReturnedPrice = this.calculatePrice('dataReturned', this.retrieveDataReturned());
    const dataScannedPrice = this.calculatePrice('dataScanned', this.retrieveDataScanned());

    const dataTransferInPrice = this.calculatePrice('inboundTransfer', this.retrieveDataTransferIn());
    const dataTransferOutPrice = this.calculatePrice('outboundTransfer', this.retrieveDataTransferOut());

    this.updatePrices([
      storagePrice + standardRequestsPrice + otherRequestsPrice + dataReturnedPrice + dataScannedPrice,
      dataTransferInPrice + dataTransferOutPrice,
    ]);
  }

  private retrieveStorage(): number {
    return this.getSelected('s3-standard', 'storage') as number;
  }

  private retrieveStandardRequests(): number {
    return this.getSelected('s3-standard', 'write-requests') as number;
  }

  private retrieveOtherRequests(): number {
    return this.getSelected('s3-standard', 'read-requests') as number;
  }

  private retrieveDataReturned(): number {
    return this.getSelected('s3-standard', 'returned') as number;
  }

  private retrieveDataScanned(): number {
    return this.getSelected('s3-standard', 'scanned') as number;
  }

  private retrieveDataTransferIn(): number {
    return this.getSelected(inboundIds[0], inboundIds[1]) as number;
  }

  private retrieveDataTransferOut(): number {
    return this.getSelected(outboundIds[0], outboundIds[1]) as number;
  }
}
