<!-- <davinci-core-header
  [appName]='appName'
  [customFunctions]='headerButtons'
  menu
  apps
  about
  language
  (button-click)='headerButtonClicked($event)'
>
</davinci-core-header>

<div class='flyout-click-catcher' [class.active]='flyoutMenu.visible' (click)='hideFlyout()'></div>
<davinci-flyout-menu #menu class='flyout-menu' external-control [open]='flyoutMenu.visible'
                     [ngStyle]='flyoutMenu.style'>
  <davinci-flyout-menu-item
    *ngFor='let item of headerFlyoutItems'
    [label]='item.label | translate'
    (menu-click)='item.callback()'
  >
  </davinci-flyout-menu-item>
</davinci-flyout-menu> -->
<davinci-core-header2 [appName]="appName">
  <davinci-core-header-button
    *ngFor="let headerButton of headerButtons"
    [id]="'header-' + headerButton.id"
    [icon]="headerButton.iconSrc"
    [title]="headerButton.title"
    (click)="headerButtonClicked(headerButton)"
  ></davinci-core-header-button>
</davinci-core-header2>

<davinci-core-header-popdown target="#header-more">
  <davinci-flyout-menu-item
    [label]="'APP.TOOLBAR.MENU.IMPRINT' | translate"
    (menu-click)="loadLink('https://www.sick.com/de/en/imprint/w/imprint/')"
  >
  </davinci-flyout-menu-item>
  <davinci-flyout-menu-item
    [label]="'APP.TOOLBAR.MENU.CONDITIONS' | translate"
    (menu-click)="loadLink('https://www.sick.com/de/en/general-terms-and-conditions/w/tac/')"
  >
  </davinci-flyout-menu-item>
  <davinci-flyout-menu-item
    [label]="'APP.TOOLBAR.MENU.USETERMS' | translate"
    (menu-click)="loadLink('https://www.sick.com/de/en/terms-of-use/w/terms-of-use/')"
  >
  </davinci-flyout-menu-item>
  <davinci-flyout-menu-item
    [label]="'APP.TOOLBAR.MENU.DATA' | translate"
    (menu-click)="loadLink('https://www.sick.com/de/en/privacy-policy/w/dataprotection/')"
  >
  </davinci-flyout-menu-item>
  <davinci-flyout-menu-item [label]="'APP.TOOLBAR.MENU.SICK' | translate" (menu-click)="loadLink('https://www.sick.com/de/en/')">
  </davinci-flyout-menu-item>
  <davinci-flyout-menu-item *ngIf="!loggedIn" label="Sign into SICK ID" (menu-click)="onLoginClicked()">
  </davinci-flyout-menu-item>
  <davinci-flyout-menu-item *ngIf="loggedIn" label="Sign out" (menu-click)="onLogoutClicked()"> </davinci-flyout-menu-item>
</davinci-core-header-popdown>
