import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';

import { BACKEND_PATHS } from '../../../../apps/frontend/src/app/config/globals';
import { INotificationAlarm } from './types';

@Injectable()
export class ProjectNotificationService {
  private notifications$: Array<INotificationAlarm> = [];
  private userId: string;

  private projectListObservable = new BehaviorSubject<Array<INotificationAlarm>>([]);

  get notifications(): Array<INotificationAlarm> {
    return this.notifications$;
  }

  private set notifications(notifications: Array<INotificationAlarm>) {
    this.notifications$ = notifications;
    this.projectListObservable.next(this.notifications$);
  }

  get notificationList(): Observable<Array<INotificationAlarm>> {
    return this.projectListObservable.asObservable();
  }

  constructor(private httpClient: HttpClient, private authService: OAuthService) {
    const user: { email?: string } = this.authService.getIdentityClaims();
    this.userId = user.email ? user.email : '';
    this.fetchNotifications();
  }

  private async fetchNotifications() {
    this.httpClient
      .get(BACKEND_PATHS.NOTIFICATIONS, { params: { name: this.userId } })
      .subscribe((response: { data?: Array<INotificationAlarm> }) => {
        if (!response.data) {
          this.notifications = [];
        } else {
          this.notifications = response.data;
        }
      });
  }

  projectAmount(): number {
    return this.notifications$.length;
  }

  async addNotification(notification: INotificationAlarm): Promise<void> {
    this.notifications = [...this.notifications, notification];
    this.updateBackenObject();
  }

  async addOrUpdateNotification(notification: INotificationAlarm): Promise<void> {
    const index = this.notifications$.findIndex((item) => item.id === notification.id);
    if (index === -1) {
      return this.addNotification(notification);
    } else {
      this.notifications$[index] = notification;
      this.notifications = this.notifications$;
      this.updateBackenObject();
    }
  }

  async deleteNotification(id: string): Promise<void> {
    this.notifications$ = this.notifications$.filter((notification) => notification.id !== id);
    this.notifications = this.notifications$;
    this.updateBackenObject();
  }

  private async updateBackenObject(): Promise<void> {
    this.httpClient
      .put(BACKEND_PATHS.NOTIFICATIONS, { name: this.userId, data: JSON.stringify(this.notifications) })
      .subscribe((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
      });
  }
}
