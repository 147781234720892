import { ICloudServiceRegion } from '@cloud-cost-calculation-tool/base-cloud-services';

export const AWS_BASIC_REGIONS: Array<ICloudServiceRegion> = [
  { name: 'US East (N. Virginia)', value: 'us-east-1' },
  { name: 'US East (Ohio)', value: 'us-east-2' },
  { name: 'US West (N. California)', value: 'us-west-1' },
  { name: 'US West (Oregon)', value: 'us-west-2' },
  { name: 'Canada (Central)', value: 'ca-central-1' },
  { name: 'EU (Frankfurt)', value: 'eu-central-1' },
  { name: 'EU (Ireland)', value: 'eu-west-1' },
  { name: 'EU (London)', value: 'eu-west-2' },
  { name: 'EU (Paris)', value: 'eu-west-3' },
  { name: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
  { name: 'Asia Pacific (Seoul)', value: 'ap-northeast-2' },
  { name: 'Asia Pacific (Osaka)', value: 'ap-northeast-3' },
  { name: 'Asia Pacific (Singapore)', value: 'ap-southeast-1' },
  { name: 'Asia Pacific (Sydney)', value: 'ap-southeast-2' },
  { name: 'Asia Pacific (Mumbai)', value: 'ap-south-1' },
  { name: 'South America (Sao Paulo)', value: 'sa-east-1' },
];
