import { DefaultAzureCloudService } from '../default-azure-service.class';
import { IRetailPriceFilter } from '../types';

export class FunctionsService extends DefaultAzureCloudService {
  get priceFetchingConfigurations(): {
    [key: string]: {
      filter: IRetailPriceFilter;
    };
  } {
    // const serviceCode = 'AWSLambda';
    return {
      time: {
        filter: {
          ...this.LOCATION_FILTER,
          serviceName: 'Functions',
          productName: 'Functions',
          priceType: 'Consumption',
          meterName: 'Standard Execution Time',
        },
      },
      amount: {
        filter: {
          ...this.LOCATION_FILTER,
          serviceName: 'Functions',
          productName: 'Functions',
          priceType: 'Consumption',
          meterName: 'Standard Total Executions',
        },
      },
    };
  }

  async calculatePrices(): Promise<void> {
    const memoryPrice = this.calculatePrice('time', this.retrieveMemory());
    const requestPrice = this.calculatePrice('amount', this.retrieveRequests());

    this.updatePrices([memoryPrice + requestPrice]);
    return;
  }

  private retrieveMemory(): number {
    // Allocated Memory * Compute Seconds * Requests
    // memory to gb, ms to s
    return (
      ((this.getSelected('service-settings', 'memory') as number) / 1000) *
      ((this.getSelected('service-settings', 'duration') as number) / 1000) *
      (this.getSelected('service-settings', 'requests') as number)
    );
  }

  private retrieveRequests(): number {
    return (this.getSelected('service-settings', 'requests') as number) / 10;
  }
}
