import { InjectionToken } from '@angular/core';

import { AbstractCloudServiceBase } from '../default-cloud-service.class';
import { ICloudServiceConfiguartionInformation, IServiceConfiguration } from './cloud-service';
import { CloudServicesProvider } from './cloud-services-provider';

// create type for classes that extend AbstractCloudServiceBase
export type DefaultCloudService = new (
  args: ICloudServiceConfiguartionInformation,
  serviceConfiguration: Array<IServiceConfiguration>,
) => AbstractCloudServiceBase;

export interface ICloudServiceInjection {
  defaultConfig: ICloudServiceConfiguartionInformation;
  cloudService: DefaultCloudService;
}

export interface ICloudServiceMenuItem {
  name: string;
  id: string;
  provider: CloudServicesProvider;
}

export const CLOUD_SERVICE_INJECTION = new InjectionToken<ICloudServiceInjection>('CLOUD_SERVICE_INJECTION');
