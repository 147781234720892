import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AwsServicesModule } from '@cloud-cost-calculation-tool/aws-services';
import { AzureServicesModule } from '@cloud-cost-calculation-tool/azure-services';
import { SickServicesModule } from '@cloud-cost-calculation-tool/sick-services';

import { CloudServicesFactoryService } from './cloud-services-factory.service';
import { CloudServicesFetcherService } from './cloud-services-fetcher.service';
import { CloudServicesHandlerService } from './cloud-services-handler.service';
import { PriceEstimationProjectsHandlerService } from './price-estimation-projects-handler.service';

@NgModule({
  imports: [CommonModule, AwsServicesModule, SickServicesModule, AzureServicesModule],
  providers: [
    PriceEstimationProjectsHandlerService,
    PriceEstimationProjectsHandlerService,
    CloudServicesHandlerService,
    CloudServicesFactoryService,
    CloudServicesFetcherService,
  ],
})
export class CloudServicesManagerModule {}
