import { CostElement } from '../types';

export abstract class DefaultService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract client: any;

  abstract registerAccount(credentials: unknown): void;

  abstract calculateCosts(startDate: Date, endDate: Date, granularity: string): Promise<Array<CostElement>>;
}
