import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';
import { AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER } from '../service-configurations';

export class SimpleEstimationService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    return {
      api_complete: {
        awsServiceCode: 'AmazonApiGateway',
        filter: [this.LOCATION_FILTER],
      },
      lambda_request: {
        awsServiceCode: 'AWSLambda',
        filter: [this.LOCATION_FILTER, { Field: 'group', Type: 'TERM_MATCH', Value: 'AWS-Lambda-Requests' }],
      },
      lambda_compute: {
        awsServiceCode: 'AWSLambda',
        filter: [this.LOCATION_FILTER, { Field: 'group', Type: 'TERM_MATCH', Value: 'AWS-Lambda-Duration' }],
      },
      aurora_compute: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          {
            Field: 'databaseEngine',
            Type: 'TERM_MATCH',
            Value: 'Aurora MySQL',
          },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:ServerlessUsage' },
        ],
      },
      aurora_storage: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          { Field: 'databaseEngine', Type: 'TERM_MATCH', Value: 'Any' },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:StorageUsage' },
        ],
      },
      // aurora_io: {
      //   awsServiceCode: 'AmazonRDS',
      //   filter: [
      //     this.LOCATION_FILTER,
      //     { Field: 'databaseEngine', Type: 'TERM_MATCH', Value: 'Any' },
      //     { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:StorageIOUsage' },
      //   ],
      // },
      // aurora_backup: {
      //   awsServiceCode: 'AmazonRDS',
      //   filter: [
      //     this.LOCATION_FILTER,
      //     {
      //       Field: 'databaseEngine',
      //       Type: 'TERM_MATCH',
      //       Value: 'Aurora ' + 'Aurora:ServerlessV2Usage',
      //     },
      //     { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:BackupUsage' },
      //   ],
      // },
      s3_storage: {
        awsServiceCode: 'AmazonS3',
        filter: [
          this.LOCATION_FILTER,
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'TimedStorage-ByteHrs' },
          { Field: 'volumeType', Type: 'TERM_MATCH', Value: 'Standard' },
        ],
      },
      s3_standardRequests: {
        awsServiceCode: 'AmazonS3',
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Requests-Tier1' }],
      },
      s3_otherRequests: {
        awsServiceCode: 'AmazonS3',
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Requests-Tier2' }],
      },
      s3_dataReturned: {
        awsServiceCode: 'AmazonS3',
        filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Select-Returned-Bytes' }],
      },
      // s3_dataScanned: {
      //   awsServiceCode: 'AmazonS3',
      //   filter: [this.LOCATION_FILTER, { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Select-Scanned-Bytes' }],
      // },
      ...AWS_DEFAULT_DATA_TRANSFER_FETCHING_FILTER,
      cf_outboundInternet: {
        awsServiceCode: 'AmazonCloudFront',
        filter: [
          {
            Field: 'usagetype',
            Type: 'TERM_MATCH',
            Value: 'US-DataTransfer-Out-Bytes',
          },
        ],
      },
      // cf_outboundOrigin: {
      //   awsServiceCode: 'AmazonCloudFront',
      //   filter: [
      //     {
      //       Field: 'usagetype',
      //       Type: 'TERM_MATCH',
      //       Value: this.getSelected('basic', 'region') + 'DataTransfer-Out-OBytes',
      //     },
      //   ],
      // },
      cf_httpsRequests: {
        awsServiceCode: 'AmazonCloudFront',
        filter: [
          {
            Field: 'usagetype',
            Type: 'TERM_MATCH',
            Value: 'US-Requests-Tier2-HTTPS',
          },
        ],
      },
      cw_complete: {
        awsServiceCode: 'AmazonCloudWatch',
        filter: [this.LOCATION_FILTER],
      },
    };
  }

  async calculatePrices(): Promise<void> {
    const lambdaPrice = this.calculateLambdaPrice();
    const apiGwPrice = this.calculateApiGwPrice();
    const cloudFrontPrice = this.calculateCloudFrontPrice();
    const cloudWatchPrice = this.calculateCloudWatchPrice();
    const sesPrice = this.calculateSESPrice();
    const snsPrice = this.calculateSNSPrice();
    const sqsPrice = this.calculateSQSPrice();
    const dataSelection = this.retrieveDataStorageType();
    let dataStoragePrice = 0;
    if (dataSelection === 'sql') {
      dataStoragePrice = this.calculateAuroraPrice();
    } else {
      dataStoragePrice = this.calculateS3Price();
    }
    // console.log('dataSelection', dataSelection);
    // console.log('lambdaPrice', lambdaPrice);
    // console.log('apiGwPrice', apiGwPrice);
    // console.log('cloudFrontPrice', cloudFrontPrice);
    // console.log('cloudWatchPrice', cloudWatchPrice);
    // console.log('sesPrice', sesPrice);
    // console.log('snsPrice', snsPrice);
    // console.log('sqsPrice', sqsPrice);
    // console.log('dataStoragePrice', dataStoragePrice);
    this.updatePrices([
      dataStoragePrice,
      cloudWatchPrice,
      cloudFrontPrice + apiGwPrice,
      lambdaPrice + sesPrice + snsPrice + sqsPrice,
    ]);
  }

  private calculateLambdaPrice(): number {
    // costs for push from edge and pull from frontend
    const requestsPerMonth = this.retrieveEdgeRequestsPerMonth() + this.retrieveFrontendRequestsPerMonth();
    const secondsPerRequest = this.retrieveBackendComplexityInSeconds();
    const computePrice = this.calculatePrice('lambda_compute', requestsPerMonth * secondsPerRequest);
    const requestPrice = this.calculatePrice('lambda_request', requestsPerMonth);
    return computePrice + requestPrice;
  }

  private calculateApiGwPrice(): number {
    const requestsPerMonth = this.retrieveEdgeRequestsPerMonth() + this.retrieveFrontendRequestsPerMonth();
    const httpPrice = this.findPriceItemAndCalculatePrice('operation', 'ApiGatewayHttpApi', requestsPerMonth);
    // costs for push from edge and pull from frontend

    return httpPrice;
  }

  private calculateAuroraPrice(): number {
    const gbStored = this.retrieveStoredData() / 1024 / 1024;
    // at least 2 per hour -> 2 * 730 hours per Month = 1460
    const computePrice = this.calculatePrice('aurora_compute', 1460);
    const storagePrice = this.calculatePrice('aurora_storage', gbStored);

    return computePrice + storagePrice;
  }

  private calculateS3Price(): number {
    const gbStored = this.retrieveStoredData() / 1024 / 1024;
    const databaseRequests = this.retrieveDatabaseRequestAmount();
    const storedPerMonth = this.retrieveEdgeKbPerMonth();
    const storagePrice = this.calculatePrice('s3_storage', gbStored);
    const standardRequestsPrice = this.calculatePrice('s3_standardRequests', databaseRequests / 2);
    const otherRequestsPrice = this.calculatePrice('s3_otherRequests', databaseRequests / 2);
    const dataReturnedPrice = this.calculatePrice('s3_dataReturned', gbStored / 4);
    const dataTransferInPrice = this.calculatePrice('inboundTransfer', storedPerMonth);

    return storagePrice + standardRequestsPrice + otherRequestsPrice + dataReturnedPrice + dataTransferInPrice;
  }

  private calculateCloudFrontPrice(): number {
    const frontendRequestsPerMonth = this.retrieveFrontendRequestsPerMonth();
    const frontendSizeGb = this.retrieveFrontendSizeMb() / 1024;
    const outboundInternetPrice = this.calculatePrice('cf_outboundInternet', frontendSizeGb * frontendRequestsPerMonth);
    const httpsRequestPrice = this.calculatePrice('cf_httpsRequests', frontendRequestsPerMonth);

    return outboundInternetPrice + httpsRequestPrice;
  }

  private calculateCloudWatchPrice(): number {
    const metricPrice = this.findPriceItemAndCalculatePrice('usagetype', 'CW:MetricMonitorUsage', this.retrieveMetricAmount());
    const dashboard = this.retrieveDashboardAmount();
    if (dashboard < 3) {
      return metricPrice;
    }
    return dashboard * 3 - 9 + metricPrice;
  }

  private calculateSESPrice(): number {
    return 0;
  }

  private calculateSNSPrice(): number {
    return 0;
  }

  private calculateSQSPrice(): number {
    return 0;
  }

  private retrieveEdgeRequestsPerMonth(): number {
    const amountDevices = this.getSelected('edge', 'devices') as number;
    const howOftenPerHour: number = this.getSelected('edge', 'frequency') as number;
    const amountRequestsPerMonth = howOftenPerHour * 24 * 30 * amountDevices;
    return amountRequestsPerMonth;
  }

  private retrieveEdgeKbPerMonth(): number {
    const amountDevices = this.getSelected('edge', 'devices') as number;
    const howOftenPerHour: number = this.getSelected('edge', 'frequency') as number;
    const dataPerRequest: number = this.getSelected('edge', 'data') as number;
    const amountKbPerMonth = howOftenPerHour * 24 * 30 * amountDevices * dataPerRequest;
    return amountKbPerMonth;
  }

  private retrieveStoredData(): number {
    const kbPerDay: number = this.retrieveEdgeKbPerMonth() / 30;
    const amountDays: number = this.getSelected('data', 'data') as number;
    const amountKb = kbPerDay * amountDays;
    return amountKb;
  }

  private retrieveDatabaseRequestAmount(): number {
    const amountRequests = this.getSelected('data', 'requests') as number;
    return amountRequests;
  }

  private retrieveDashboardAmount(): number {
    const amountDashboards = this.getSelected('reporting', 'dashboards') as number;
    return amountDashboards;
  }

  private retrieveMetricAmount(): number {
    const amountMetrics = this.getSelected('reporting', 'metrics') as number;
    return amountMetrics;
  }

  private retrieveFrontendSizeMb(): number {
    const complexity = this.getSelected('frontend', 'complexity') as string;
    switch (complexity) {
      case 'simple':
        return 5;
      case 'medium':
        return 10;
      case 'complex':
        return 15;
      case 'very-complex':
        return 20;
      default:
        return 10;
    }
  }

  private retrieveFrontendRequestsPerMonth(): number {
    const viewsPerHour = this.getSelected('frontend', 'viewed') as number;
    const requestsPerMonth = viewsPerHour * 24 * 30;
    return requestsPerMonth;
  }

  private retrieveBackendComplexityInSeconds(): number {
    const complexity = this.getSelected('backend', 'complexity') as string;
    switch (complexity) {
      case 'simple':
        return 1;
      case 'medium':
        return 2;
      case 'complex':
        return 5;
      case 'very-complex':
        return 10;
      default:
        return 2;
    }
  }

  private retrieveBackendRequestAmount(): number {
    const amountFunctions = this.getSelected('backend', 'requests') as number;
    const requestsPerMonth = this.getSelected('backend', 'time') as number;
    const requests = amountFunctions * requestsPerMonth;
    return requests;
  }

  private retrieveBackendRequestTime(): number {
    const requests = this.retrieveBackendRequestAmount();
    const seconds = this.retrieveBackendComplexityInSeconds();
    const amountSeconds = requests * seconds;
    return amountSeconds;
  }

  private retrieveNotificationService(): string {
    const service = this.getSelected('backend', 'notifications') as string;
    return service;
  }

  private retrieveDataStorageType(): string {
    const data = this.getSelected('data', 'format') as string;
    return data;
  }
}
