<div class='estimation-sidebar'>
  <div class='estimation-sidebar__actions'>
    <davinci-drop-down class='estimation-sidebar__actions--dropdown' [value]='selectectedEstimationProject?.id'
                       (change)='changeEstimationProjectSelection($event)'
                       id='serviceSelection' label='Select Service'>
      <davinci-option *ngFor='let estimationProject of estimationProjects'
                      [value]='estimationProject.id'>{{estimationProject.name}}</davinci-option>
    </davinci-drop-down>
    <davinci-button class='estimation-sidebar__actions--button' type='bare' icon='content/add'
                    (click)='createEstimation()'></davinci-button>
    <davinci-button class='estimation-sidebar__actions--button' type='bare' icon='image/edit'
                    [disabled]='selectectedEstimationProject === undefined'
                    (click)='editEstimation()'></davinci-button>
    <davinci-button class='estimation-sidebar__actions--button' type='bare' icon='action/delete'
                    [disabled]='selectectedEstimationProject === undefined'
                    (click)='deleteEstimations()'></davinci-button>
    <davinci-button class='estimation-sidebar__actions--button' type='bare' icon='file/file_download'
                    [disabled]='selectectedEstimationProject === undefined'
                    (click)='downloadEstimation()'></davinci-button>
  </div>
  <div class='estimation-sidebar__estimation'>
    <cloud-cost-calculation-tool-estimation-element
      *ngFor='let priceElement of priceElements'
      [element]='priceElement'
    ></cloud-cost-calculation-tool-estimation-element>
  </div>
  <div class='estimation-sidebar__footer estimation-summary'>
    <div class='estimation-summary__price'>
      <h3>Total Costs:</h3>
      <h3>{{ totalCosts | currency }}</h3>
    </div>
  </div>
</div>
