import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';

@NgModule({
  declarations: [HeaderComponent, HomeComponent, InformationDialogComponent, FeedbackDialogComponent],
  exports: [HeaderComponent],
  imports: [RouterModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
