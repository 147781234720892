import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';

export class CloudFrontService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AmazonCloudFront';
    return {
      outboundInternet: {
        awsServiceCode: serviceCode,
        filter: [
          {
            Field: 'usagetype',
            Type: 'TERM_MATCH',
            Value: this.getSelected('basic', 'region') + 'DataTransfer-Out-Bytes',
          },
        ],
      },
      outboundOrigin: {
        awsServiceCode: serviceCode,
        filter: [
          {
            Field: 'usagetype',
            Type: 'TERM_MATCH',
            Value: this.getSelected('basic', 'region') + 'DataTransfer-Out-OBytes',
          },
        ],
      },
      httpsRequests: {
        awsServiceCode: serviceCode,
        filter: [
          {
            Field: 'usagetype',
            Type: 'TERM_MATCH',
            Value: this.getSelected('basic', 'region') + 'Requests-Tier2-HTTPS',
          },
        ],
      },
    };
  }

  async calculatePrices(): Promise<void> {
    const outboundInternetPrice = this.calculatePrice('outboundInternet', this.retrieveOutboundInternetAmount());
    const outboundOriginPrice = this.calculatePrice('outboundOrigin', this.retrieveOutboundOriginAmount());
    const httpsRequestPrice = this.calculatePrice('httpsRequests', this.retrieveRequestsAmount());

    this.updatePrices([outboundInternetPrice + outboundOriginPrice + httpsRequestPrice]);
    return;
  }

  private retrieveOutboundInternetAmount(): number {
    return this.getSelected('cloud-front-edge-location', 'out-internet') as number;
  }

  private retrieveOutboundOriginAmount(): number {
    return this.getSelected('cloud-front-edge-location', 'out-origin') as number;
  }

  private retrieveRequestsAmount(): number {
    return this.getSelected('cloud-front-edge-location', 'requests') as number;
  }
}
