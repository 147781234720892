import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseCloudServicesModule, CLOUD_SERVICE_INJECTION } from '@cloud-cost-calculation-tool/base-cloud-services';

import { DscCostsService } from './dsc-costs.service';
import {
  APP_POOL,
  ASSET_HUB,
  D_STUDIO,
  DYNAMIC_DATA_DISPLAY,
  LIVE_CONNECT,
  MONITORING_BOX,
  ROLE_MANAGEMENT,
} from './injection-configuration';

@NgModule({
  imports: [CommonModule, BaseCloudServicesModule],
  providers: [
    { provide: CLOUD_SERVICE_INJECTION, useValue: APP_POOL, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: ASSET_HUB, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: D_STUDIO, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: LIVE_CONNECT, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: MONITORING_BOX, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: ROLE_MANAGEMENT, multi: true },
    { provide: CLOUD_SERVICE_INJECTION, useValue: DYNAMIC_DATA_DISPLAY, multi: true },
    DscCostsService,
  ],
})
export class SickServicesModule {}
