import { CostElement, Project } from '@cloud-cost-calculation-tool/project-analysis';
import { RootNode } from '@sick-curie/dashboard-builder';

export interface CurrentProject {
  project: Project;
  displayInformation: DisplayInformationGroup;
  currentProviderId: string;
}

export interface DisplayInformationGroup {
  [key: string]: DisplayInformationElement;
}

export interface DisplayInformationElement {
  provider: string;
  dashboard: RootNode;
  costs: DisplayInformationCostGroup;
}

export interface DisplayInformationCostGroup {
  monthly: Array<CostElement>;
  weekly: Array<CostElement>;
  daily: Array<CostElement>;
}
