import { Injectable } from '@angular/core';
import {
  AbstractCloudServiceBase,
  IPriceElement,
  IPriceEstimationProject,
} from '@cloud-cost-calculation-tool/base-cloud-services';
import { v4 as uuidV4 } from 'uuid';

import { exportEstimationAsCsv } from '../../../project-analysis/src/lib/helper';
import { PriceEstimationFetcherService } from './price-estimation-fetcher.service';

@Injectable()
export class PriceEstimationProjectsHandlerService extends PriceEstimationFetcherService {
  price = 0;

  async addPriceCalculation(cloudService: AbstractCloudServiceBase): Promise<void> {
    let priceElement: IPriceElement = {
      uuid: uuidV4(),
      name: cloudService.name,
      description: cloudService.getSelected('basic', 'region') as string,
      cloudService,
    };
    priceElement = this.setPriceEstimationName(priceElement);
    await this.addCalculation(priceElement);
  }

  async addCalculation(element: IPriceElement): Promise<void> {
    this.getSelectedEstimationProject().elements.push(element);
    await this.updateAndSetPriceEstimations();
  }

  async addPriceCalculationProject(name: string): Promise<void> {
    const id = uuidV4();
    this.projectsMap.set(id, {
      id,
      name,
      elements: [],
    });
    this.selectedEstimationProject = id;
    await this.updateAndSetPriceEstimations();
  }

  async updateProjectName(id: string, name: string): Promise<void> {
    const project = this.projectsMap.get(id);
    if (!project) {
      return;
    }
    project.name = name;
    await this.updateAndSetPriceEstimations();
  }

  async removePriceCalculation(uuid: string): Promise<void> {
    const index = this.getSelectedEstimationProject().elements.findIndex((element) => element.uuid === uuid);
    if (index === -1) {
      return;
    }
    this.getSelectedEstimationProject().elements.splice(index, 1);
    await this.updateAndSetPriceEstimations();
  }

  async removeCalculations(): Promise<void> {
    this.getSelectedEstimationProject().elements = [];
    await this.updateAndSetPriceEstimations();
  }

  async removeProject(uuid: string): Promise<void> {
    this.projectsMap.delete(uuid);
    await this.updateAndSetPriceEstimations();
  }

  getTotalCosts(): number {
    const price = this.getSelectedEstimationProject().elements.reduce((acc, element) => {
      return acc + element.cloudService.price;
    }, 0);
    return price;
  }

  downloadEstimation(): void {
    exportEstimationAsCsv(this.getSelectedEstimationProject().elements, this.getSelectedEstimationProject().name);
  }

  getSelectedEstimationProject(): IPriceEstimationProject {
    const mapEntry = this.projectsMap.get(this.selectedEstimationProject);
    if (!mapEntry) {
      throw new Error('No estimation project selected');
    }
    return mapEntry;
  }
}
