import { CloudServiceSection } from '@cloud-cost-calculation-tool/base-cloud-services';

export const AURORA_INSTANCE_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Instance',
    id: 'instance',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Database Type',
        id: 'database-type',
        type: 'selection',
        selected: 'MySQL',
        options: [
          {
            name: 'MySQL',
            value: 'MySQL',
          },
          {
            name: 'PostgreSQL',
            value: 'PostgreSQL',
          },
        ],
      },
      {
        name: 'Instance Class',
        id: 'instance-class',
        type: 'selection',
        selected: '',
        options: [],
      },
      {
        name: 'Node',
        id: 'nodes',
        type: 'number',
        selected: 1,
        unitSelected: 1,
        numberSelected: 1,
        options: {
          min: 1,
          max: 1000000,
          step: 1,
        },
      },
    ],
  },
];
