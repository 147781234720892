<div class='content'>
  <div class='content__section'>
    <davinci-text-field
      class='content__section--input'
      label='Alarm Name'
      [value]='notificationAlarm.name'
      (change)='changeNickname($event)'
    ></davinci-text-field>
    <davinci-drop-down class='content__section--input' [value]='notificationAlarm.topic?.id'
                       (change)='changeTopic($event)'>
      <davinci-option *ngFor='let topic of topics' [value]='topic.id'>{{topic.name}}</davinci-option>
    </davinci-drop-down>
  </div>
  <div class='content__trigger'>
    <h3 class='content__list-header'>Alarm Trigger</h3>
    <div class='content__threshold'>
      <div class='content__threshold--header'>
        <davinci-checkbox (change)='changeBelowActivation($event)'
                          [checked]='notificationAlarm.alarm.belowActivated'></davinci-checkbox>
        <h5 class='content__threshold--header-text'>Below</h5>
      </div>
      <div class='content__threshold--header'>
        <davinci-checkbox (change)='changeAboveActivation($event)'
                          [checked]='notificationAlarm.alarm.aboveActivated'></davinci-checkbox>
        <h5 class='content__threshold--header-text'>Above</h5>
      </div>
      <davinci-numeric-field unit='$' [disabled]='!notificationAlarm.alarm.belowActivated'
                             (change)='changeBelowThreshold($event)' min='1'
                             [value]='notificationAlarm.alarm.belowThreshold'></davinci-numeric-field>
      <davinci-numeric-field unit='$' [disabled]='!notificationAlarm.alarm.aboveActivated'
                             (change)='changeAboveThreshold($event)' min='1'
                             [value]='notificationAlarm.alarm.aboveThreshold'></davinci-numeric-field>
    </div>
    <div>
      <h5 class='content__threshold--header-text'>Amount of Days</h5>
    </div>
    <davinci-numeric-field
      [disabled]='!notificationAlarm.alarm.belowActivated && !notificationAlarm.alarm.aboveActivated'
      (change)='changeDaysRange($event)' min='1' max='90'
      [value]='notificationAlarm.alarm.daysRange'></davinci-numeric-field>
    <p>*Nickname, Topic and below or above is mandatory</p>
  </div>
  <div>
    <h3 class='content__list-header'>Subscribers</h3>
    <div class='content__subscriber' *ngFor='let mail of notificationAlarm.subscribers; let i'>
      <p class='content__subscriber--name'>{{mail}}</p>
      <davinci-button type='bare' [disabled]='mail === userMail' class='content__subscriber--button'
                      (click)='removeSubscriber(mail)'>
        Remove
      </davinci-button>
    </div>
    <div class='content__subscriber'>
      <davinci-text-field
        class='content__subscriber--name'
        label='Add new'
        [value]='newMail'
        (change)='updatedMailName($event)'
      ></davinci-text-field>
      <davinci-button class='content__subscriber--button' (click)='addSubscriber()'>Add</davinci-button>

    </div>
  </div>
</div>
<div class='actions'>
  <davinci-button (click)='cancel()'>Cancel</davinci-button>
  <davinci-button (click)='addAlarm()' [disabled]='!this.inputValid()'>Add/Update</davinci-button>
</div>
