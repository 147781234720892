import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '../components/home/home.component';
import { AnalysisComponent } from '../feature/analysis/analysis.component';
import { EstimationComponent } from '../feature/estimation/estimation.component';
import { NotificationsComponent } from '../feature/notifications/notifications.component';
// Components
// Authentification

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'estimation',
    component: EstimationComponent,
  },
  {
    path: 'analysis',
    component: AnalysisComponent,
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
  },

  // Default (404)
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
