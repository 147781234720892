export * from './api-gateway.service.class';
export * from './aurora-instance.service.class';
export * from './aurora-serverless.service.class';
export * from './cloud-front.service.class';
export * from './cloud-watch.service.class';
export * from './document-db.service.class';
export * from './iot-core.service.class';
export * from './lambda.service.class';
export * from './s3-bucket.service.class';
export * from './ses.service.class';
export * from './simple-estimation.service.class';
export * from './sns.service.class';
export * from './sqs.service.class';
