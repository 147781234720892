import { SpecificationInput } from '../types';
import { GB_PER_MB, GB_PER_TB, HOURS_PER_MONTH, KB_PER_BYTE, KB_PER_MB } from './variables';

// Value is # per Month
export const UNIT_AMOUNT_PER_MONTH: Array<SpecificationInput> = [
  {
    name: 'per Second',
    value: 60 * 60 * HOURS_PER_MONTH,
  },
  {
    name: 'per Minute',
    value: 60 * HOURS_PER_MONTH,
  },
  {
    name: 'per Hour',
    value: HOURS_PER_MONTH,
  },
  {
    name: 'per Day',
    value: HOURS_PER_MONTH / 24,
  },
  {
    name: 'per Month',
    value: 1,
  },
];

// Value is per Hour
export const UNIT_AMOUNT_PER_HOUR: Array<SpecificationInput> = [
  {
    name: 'per Second',
    value: 60 * 60,
  },
  {
    name: 'per Minute',
    value: 60,
  },
  {
    name: 'per Hour',
    value: 1,
  },
  {
    name: 'per Day',
    value: 1 / 24,
  },
  {
    name: 'per Month',
    value: 1 / HOURS_PER_MONTH,
  },
];

// Value is seconds
export const UNIT_DURATION_AS_SECONDS: Array<SpecificationInput> = [
  {
    name: 'hours',
    value: 60 * 60,
  },
  {
    name: 'minutes',
    value: 60,
  },
  {
    name: 'seconds',
    value: 1,
  },
];

// Value is days
export const UNIT_DURATION_AS_DAYS: Array<SpecificationInput> = [
  {
    name: 'days',
    value: 1,
  },
  {
    name: 'weeks',
    value: 7,
  },
  {
    name: 'months',
    value: 30,
  },
  {
    name: 'years',
    value: 365,
  },
];

// Value is GB
export const UNIT_SIZE_AS_GB: Array<SpecificationInput> = [
  {
    name: 'MB',
    value: GB_PER_MB,
  },
  {
    name: 'GB',
    value: 1,
  },
  {
    name: 'TB',
    value: GB_PER_TB,
  },
];

// Value is KB
export const UNIT_SIZE_AS_KB: Array<SpecificationInput> = [
  {
    name: 'KB',
    value: 1,
  },
  {
    name: 'Bytes',
    value: KB_PER_BYTE,
  },
  {
    name: 'MB',
    value: KB_PER_MB,
  },
];

// Value is GB
export const UNIT_SIZE_AS_GB_PER_MONTH: Array<SpecificationInput> = [
  {
    name: 'GB per month',
    value: 1,
  },
  {
    name: 'TB per month',
    value: GB_PER_TB,
  },
];
