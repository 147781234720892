/* eslint-disable no-underscore-dangle */
import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'cloud-cost-calculation-tool-add-project-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent {
  constructor(public ref: DynamicDialogRef) {}

  cancel(): void {
    this.ref.close(false);
  }
}
