// Angular
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloudServicesManagerModule } from '@cloud-cost-calculation-tool/cloud-services-manager';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { CoreModule } from './core/core.module';
import { AppInitializerService } from './core/services/app-initializer.service';
import { AnalysisModule } from './feature/analysis/analysis.module';
import { EstimationModule } from './feature/estimation/estimation.module';
import { NotificationsModule } from './feature/notifications/notifications.module';
// SICK
import { InternationalizationModule } from './internationalization/internationalization.module';
import { AppRoutingModule } from './routing/app.routing.module';
// Base
import { SharedModule } from './shared/shared.module';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    // Auth
    OAuthModule.forRoot(),
    // Base
    AppRoutingModule,
    SharedModule,
    CoreModule,
    // SICK
    // initiating with default language: de-DE
    InternationalizationModule.forRoot({ locale_id: 'de-DE' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // Features
    ComponentsModule,
    AnalysisModule,
    NotificationsModule,
    EstimationModule,
    // Libs
    CloudServicesManagerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appInit: { initialize: () => never }) => () => appInit.initialize(),
      multi: true,
      deps: [AppInitializerService],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
