import '@sick-davinci/basic-elements/lib/collapse/Collapse';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CloudServiceSection,
  ServiceOptionInput,
  ServiceOptionNumberInput,
} from '@cloud-cost-calculation-tool/base-cloud-services';

@Component({
  selector: 'cloud-cost-calculation-tool-section-card',
  templateUrl: './section-card.component.html',
  styleUrls: ['./section-card.component.scss'],
})
export class SectionCardComponent {
  @Input()
  section!: CloudServiceSection;

  @Output()
  optionChangeEvent = new EventEmitter<void>();

  onOptionChange(e: Event, inputItem: ServiceOptionInput): void {
    const data = (e as CustomEvent).detail;
    inputItem.selected = data;
    this.optionChangeEvent.emit();
  }

  onSpecificationInputChange(e: Event, inputItem: ServiceOptionNumberInput): void {
    const data = (e as CustomEvent).detail;
    if (!inputItem.units) {
      return;
    }
    inputItem.unitSelected = Number.parseInt(data as string, 10);
    inputItem.selected = inputItem.numberSelected * inputItem.unitSelected;
    this.optionChangeEvent.emit();
  }

  onNumberInputChange(e: Event, inputItem: ServiceOptionNumberInput): void {
    const data = (e as CustomEvent).detail;
    if (!inputItem.unitSelected) {
      return;
    }
    inputItem.numberSelected = data;
    inputItem.selected = inputItem.numberSelected * inputItem.unitSelected;
    this.optionChangeEvent.emit();
  }
}
