export interface IProjectTopic {
  id: string;
  project: string;
  provider: string;
  name: string;
}

export interface INotificationAlarm {
  topic: IProjectTopic;
  name: string;
  id: string;
  alarm: {
    belowActivated: boolean;
    belowThreshold: number;
    aboveActivated: boolean;
    aboveThreshold: number;
    id: string;
    daysRange: number;
  };
  subscribers: Array<string>;
}
