import { Component } from '@angular/core';
import { AbstractCloudServiceBase, CloudServiceSection } from '@cloud-cost-calculation-tool/base-cloud-services';
import {
  CloudServicesHandlerService,
  PriceEstimationProjectsHandlerService,
} from '@cloud-cost-calculation-tool/cloud-services-manager';
import { Subscription } from 'rxjs';

import { ToastService } from '../../../core/toast/toast.service';

@Component({
  selector: 'cloud-cost-calculation-tool-configuration-window',
  templateUrl: './configuration-window.component.html',
  styleUrls: ['./configuration-window.component.scss'],
})
export class ConfigurationWindowComponent {
  selectedService: AbstractCloudServiceBase | undefined = undefined;
  shownSections: Array<CloudServiceSection> = [];

  left: { disabled: boolean; text: string } = { disabled: false, text: 'Cancel' };
  right: { disabled: boolean; text: string } = { disabled: false, text: 'Add' };
  sectionStep = 0;
  verticalList = false;
  currentPrice = 0;
  priceSubject: Subscription | undefined = undefined;

  constructor(
    private priceEstimationProjectsHandlerService: PriceEstimationProjectsHandlerService,
    private toastService: ToastService,
    private cloudServicesHandlerService: CloudServicesHandlerService,
  ) {
    this.cloudServicesHandlerService.serviceObservable.subscribe((service) => {
      this.selectedService = service;
      this.priceSubject = this.selectedService?.currentPrice.subscribe((p) => (this.currentPrice = p));
      this.defineShowedSections(true);
    });
  }

  async clearSelectedService(): Promise<void> {
    await this.cloudServicesHandlerService.changeService();
  }

  async addServiceCalculation(): Promise<void> {
    if (!this.selectedService) return;
    await this.priceEstimationProjectsHandlerService.addPriceCalculation(this.selectedService);
    this.clearSelectedService();
    this.toastService.add({ severity: 'success', summary: 'Service added' });
  }

  async updateSelectionAndCalculation(sectionId: string): Promise<void> {
    // Set sectionStep to latest edited Section
    this.sectionStep = this.selectedService?.sections.findIndex((s) => s.id === sectionId) || 0;
    if (!this.selectedService) return;
    await this.selectedService.updatePriceInformations();
  }

  leftClicked(): void {
    if (!this.selectedService) return;
    if (this.verticalList || this.sectionStep === 0) {
      this.clearSelectedService();
      return;
    }
    this.sectionStep = this.sectionStep - 1;
    this.defineShowedSections();
  }

  rightClicked(): void {
    if (!this.selectedService) return;
    if (this.verticalList || this.sectionStep === this.selectedService.sections.length - 1) {
      this.addServiceCalculation();
      return;
    }
    this.sectionStep = this.sectionStep + 1;
    this.defineShowedSections();
  }

  verticalModeSelectedChanged(isVertical: boolean): void {
    this.verticalList = isVertical;
    this.defineShowedSections();
  }

  private defineShowedSections(initializeNew = false) {
    if (!this.selectedService || this.selectedService.sections.length === 0) {
      this.shownSections = [];
      this.setActionButtons({ disabled: true, text: 'Cancel' }, { disabled: true, text: 'Add' });
      return;
    }
    if (this.verticalList) {
      this.shownSections = this.selectedService.sections;
      this.setActionButtons();
      return;
    }
    if (initializeNew) this.sectionStep = 0;
    this.shownSections = [this.selectedService.sections[this.sectionStep]];
    this.setActionButtons(
      {
        disabled: false,
        text: this.sectionStep === 0 ? 'Cancel' : `Last: ${this.selectedService.sections[this.sectionStep - 1].name}`,
      },
      {
        disabled: false,
        text:
          this.sectionStep === this.selectedService.sections.length - 1
            ? 'Add'
            : `Next: ${this.selectedService.sections[this.sectionStep + 1].name}`,
      },
    );
  }

  private setActionButtons(
    left: { disabled: boolean; text: string } = { disabled: false, text: 'Cancel' },
    right: { disabled: boolean; text: string } = { disabled: false, text: 'Add' },
  ) {
    this.left = left;
    this.right = right;
  }
}
