import { CloudServiceSection, UNIT_SIZE_AS_KB } from '@cloud-cost-calculation-tool/base-cloud-services';

export const IOT_CORE_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Devices',
    id: 'devices',
    calculation: false,
    price: 0,
    info: '',
    input: [
      {
        name: 'Number of devices (MQTT)',
        id: 'message-type',
        description: 'Select the Communication protocol used by the device',
        type: 'boolean',
        selected: false,
        options: {
          true: 'MQTT',
          false: 'HTTP',
        },
      },
      {
        name: 'Number of devices',
        id: 'devices-amount',
        description: 'Enter the number of devices you will utilize.',
        type: 'number',
        selected: 1,
        unitSelected: 1,
        numberSelected: 1,

        options: {
          min: 0,
          max: 10000000000,
          step: 1,
        },
      },
    ],
  },
  {
    name: 'Connectivity (MQTT only)',
    id: 'connectivity',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Connection duration',
        id: 'connectivity-duration',
        description: 'Choose a connection duration or enter the number of connection minutes (min per Month)',
        type: 'number',
        selected: 1,
        unitSelected: 1,
        numberSelected: 1,

        options: {
          min: 0,
          max: 43800,
          step: 1,
        },
      },
    ],
  },
  {
    name: 'Messaging',
    id: 'messaging',
    calculation: true,
    price: 0,
    info: '',
    description:
      'To estimate your monthly costs, enter the number and average size of messages you expect to transmit. You are charged for messages sent in both directions, from a device to AWS IoT Core and from AWS IoT Core to a device. Per message pricing is the same for MQTT, HTTP, and WebSockets.',
    input: [
      {
        name: 'Number of messages for a device',
        id: 'number-of-messages',
        description: 'Enter the number of messages transmitted between a device and AWS IoT Core in a month.',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,

        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
      },
      {
        name: 'Average size of each message',
        id: 'size-of-messages',
        description: 'Messages are metered in 5 KB increments; an 8 KB message is metered as two messages.',
        type: 'number',
        selected: 5,
        unitSelected: UNIT_SIZE_AS_KB[0].value,
        numberSelected: 5,
        options: {
          min: 0,
          max: 128,
          step: 1,
        },
        units: UNIT_SIZE_AS_KB,
      },
    ],
  },
  {
    name: 'Device Shadow',
    id: 'device-shadow',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Number of Shadow operations for a device',
        id: 'number-of-shadow-operations',
        description: 'Enter the number of operations that access or modify the Device Shadow in a month.',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
      },
      {
        name: 'Average size of each record',
        id: 'size-of-messages',
        description:
          'Operations are metered in 1 KB increments; an update to a 1.5 KB Device Shadow record is metered as two operations.',
        type: 'number',
        selected: 1,
        unitSelected: UNIT_SIZE_AS_KB[0].value,
        numberSelected: 1,
        options: {
          min: 0,
          max: 10240,
          step: 1,
        },
        units: UNIT_SIZE_AS_KB,
      },
    ],
  },
  {
    name: 'Registry',
    id: 'registry',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Number of Registry requests',
        id: 'number-of-messages',
        description: 'Enter the number of Registry requests in a month.',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
      },
      {
        name: 'Average size of each message',
        id: 'size-of-messages',
        description: 'Messages are metered in 5 KB increments; an 8 KB message is metered as two messages.',
        type: 'number',
        selected: 1,
        unitSelected: UNIT_SIZE_AS_KB[0].value,
        numberSelected: 1,
        options: {
          min: 0,
          max: 10240,
          step: 1,
        },
        units: UNIT_SIZE_AS_KB,
      },
    ],
  },
  {
    name: 'Rules Engine',
    id: 'rules-engine',
    calculation: true,
    price: 0,
    info: '',
    input: [
      {
        name: 'Number of rules triggered',
        id: 'number-of-rules-triggered',
        description: 'Enter the number of rules triggered per month by a device.',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
      },
      {
        name: 'Average number of actions executed per rule',
        id: 'number-of-actions-executed',
        description: 'Enter the number of rules triggered per month by a device.',
        type: 'number',
        selected: 1,
        unitSelected: 1,
        numberSelected: 1,
        options: {
          min: 1,
          max: 10,
          step: 1,
        },
      },
      {
        name: 'Average size of each message',
        id: 'size-of-messages',
        description:
          'Rules and actions are metered in 5 KB increments; a rule with two actions that is executed for a 7 KB message is metered as two rules and four actions.',
        type: 'number',
        selected: 1,
        unitSelected: UNIT_SIZE_AS_KB[0].value,
        numberSelected: 1,
        options: {
          min: 0,
          max: 10240,
          step: 1,
        },
        units: UNIT_SIZE_AS_KB,
      },
    ],
  },
];
