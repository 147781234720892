import { CloudServiceSection } from '@cloud-cost-calculation-tool/base-cloud-services';

export const SIMPLE_QUEUE_SERVICE_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Service settings',
    id: 'service-settings',
    calculation: true,
    price: 0,
    info: 'Every Amazon SQS action counts as a request. SQS requests are priced for Standard Queue and FIFO Queue requests. You pay as you go with no minimum fee.',
    input: [
      {
        name: 'Standard queue requests',
        id: 'standard-queue-requests',
        description: '(million per monnth)',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
      },
      {
        name: 'FIFO queue requests',
        id: 'fifo-queue-requests',
        description: '(million per monnth)',
        type: 'number',
        selected: 0,
        unitSelected: 1,
        numberSelected: 0,
        options: {
          min: 0,
          step: 1,
        },
      },
    ],
  },
];
