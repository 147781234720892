import { Filter } from '@aws-sdk/client-pricing';

import { DefaultAwsCloudService } from '../default-aws-service.class';

export class IotCoreService extends DefaultAwsCloudService {
  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    const serviceCode = 'AWSIoT';
    return {
      complete: {
        awsServiceCode: serviceCode,
        filter: [this.LOCATION_FILTER],
      },
    };
  }

  async calculatePrices(): Promise<void> {
    const deviceProtocol = this.getSelected('devices', 'message-type');

    let minutePrice = 0;
    if (deviceProtocol === false) {
      minutePrice = this.findPriceItemAndCalculatePrice(
        'usagetype',
        'ConnectionMinutes',
        this.retrieveConnectionMinutes(),
        'WAN',
      );
    }

    const messagePrice = this.findPriceItemAndCalculatePrice('usagetype', 'Messages', this.retrieveMessages(), 'WAN', 'Sidewalk');
    const shadowPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'RegistryAndShadowOperations',
      this.retrieveShadowOperations(),
      'WAN',
    );
    const registryPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'RegistryAndShadowOperations',
      this.retrieveRegistryRequests(),
      'WAN',
    );
    const rulesPrice = this.findPriceItemAndCalculatePrice('usagetype', 'RulesTriggered', this.retrieveRuleTriggers(), 'WAN');
    const actionsPrice = this.findPriceItemAndCalculatePrice(
      'usagetype',
      'ActionsExecuted',
      this.retrieveActionsExecuted(),
      'WAN',
    );

    this.updatePrices([minutePrice, messagePrice, shadowPrice, registryPrice, rulesPrice + actionsPrice]);
    return;
  }

  private retrieveMessages() {
    // 5kb per request => 7kb = 2 requests
    const messagesPerRequest = Math.ceil((this.getSelected('messaging', 'size-of-messages') as number) / 5);
    // messages * messages per month * devices
    const messages =
      messagesPerRequest *
      (this.getSelected('messaging', 'number-of-messages') as number) *
      (this.getSelected('devices', 'devices-amount') as number);
    return messages;
  }

  private retrieveConnectionMinutes() {
    // devices * connection minutes
    return (
      (this.getSelected('devices', 'devices-amount') as number) *
      (this.getSelected('connectivity', 'connectivity-duration') as number)
    );
  }

  private retrieveRuleTriggers() {
    // 5kb per request => 7kb = 2 requests
    const messagesPerRequest = Math.ceil((this.getSelected('rules-engine', 'size-of-messages') as number) / 5);
    // messages * rules triggered per month * devices
    return (
      messagesPerRequest *
      (this.getSelected('rules-engine', 'number-of-rules-triggered') as number) *
      (this.getSelected('devices', 'devices-amount') as number)
    );
  }

  private retrieveActionsExecuted() {
    // 5kb per request => 7kb = 2 requests
    const messagesPerRequest = Math.ceil((this.getSelected('rules-engine', 'size-of-messages') as number) / 5);
    // Actions executed per month * rules triggered per month * messages * devices
    return (
      (this.getSelected('rules-engine', 'number-of-actions-executed') as number) *
      (this.getSelected('rules-engine', 'number-of-rules-triggered') as number) *
      messagesPerRequest *
      (this.getSelected('devices', 'devices-amount') as number)
    );
  }

  private retrieveRegistryRequests() {
    // 1kb per request => 7kb = 7 requests
    const messagesPerRequest = Math.ceil((this.getSelected('registry', 'size-of-messages') as number) / 1);
    // messages * messages per month
    return messagesPerRequest * (this.getSelected('registry', 'number-of-messages') as number);
  }

  private retrieveShadowOperations() {
    // 1kb per request => 7kb = 7 requests
    const messagesPerRequest = Math.ceil((this.getSelected('device-shadow', 'size-of-messages') as number) / 1);
    // messages * messages per month * devices
    return (
      messagesPerRequest *
      (this.getSelected('device-shadow', 'number-of-shadow-operations') as number) *
      (this.getSelected('devices', 'devices-amount') as number)
    );
  }
}
