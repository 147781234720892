import { Filter, GetProductsCommandInput } from '@aws-sdk/client-pricing';
import { HOURS_PER_MONTH } from '@cloud-cost-calculation-tool/base-cloud-services';

import { DefaultAwsCloudService } from '../default-aws-service.class';
import {
  backupStorageIds,
  baselineIoIds,
  peakIoDurationIds,
  peakIoIds,
  storageAmountIds,
} from '../service-configurations/default-database.configuration';

export class AuroraInstanceService extends DefaultAwsCloudService {
  private selectedType = this.getSelected('instance', 'database-type');
  private selectedInstance = this.getSelected('instance', 'instance-class');

  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    return {
      instance: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          {
            Field: 'databaseEngine',
            Type: 'TERM_MATCH',
            Value: 'Aurora ' + this.getSelected('instance', 'database-type'),
          },
          { Field: 'productFamily', Type: 'TERM_MATCH', Value: 'Database Instance' },
          {
            Field: 'instanceType',
            Type: 'TERM_MATCH',
            Value: this.getSelected('instance', 'instance-class') as string,
          },
        ],
      },
      storage: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          { Field: 'databaseEngine', Type: 'TERM_MATCH', Value: 'Any' },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:StorageUsage' },
        ],
      },
      io: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          { Field: 'databaseEngine', Type: 'TERM_MATCH', Value: 'Any' },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:StorageIOUsage' },
        ],
      },
      backup: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          {
            Field: 'databaseEngine',
            Type: 'TERM_MATCH',
            Value: 'Aurora ' + this.getSelected('instance', 'database-type'),
          },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:BackupUsage' },
        ],
      },
    };
  }

  protected override async fetchServiceSpecificValues(): Promise<void> {
    const params: GetProductsCommandInput = {
      ServiceCode: 'AmazonRDS',
      Filters: [
        {
          Field: 'databaseEngine',
          Type: 'TERM_MATCH',
          Value: 'Aurora ' + this.getSelected('instance', 'database-type'),
        },
        { Field: 'productFamily', Type: 'TERM_MATCH', Value: 'Database Instance' },
      ],
    };
    const instanceValues = await this.fetchPromise(params);
    const inputField = this.getInput('instance', 'instance-class');
    inputField.options = [];
    let options: Array<{ value: string; name: string }> = [];
    instanceValues.PriceList?.forEach((instance) => {
      const instanceObject = JSON.parse(instance as string);
      options.push({
        value: instanceObject.product.attributes.instanceType,
        name: `${instanceObject.product.attributes.instanceType} (vCPU: ${instanceObject.product.attributes.vcpu}, Memory: ${instanceObject.product.attributes.memory} GiB, Network Performance: ${instanceObject.product.attributes.networkPerformance})`,
      });
    });
    // sort options by name
    options = options.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    inputField.options = options;
    inputField.selected = options[0].value;
    this.selectedInstance = options[0].value;
    return;
  }

  protected async calculatePrices(): Promise<void> {
    if (
      this.selectedType !== this.getSelected('instance', 'database-type') ||
      this.selectedInstance !== this.getSelected('instance', 'instance-class')
    ) {
      this.selectedType = this.getSelected('instance', 'database-type');
      this.selectedInstance = this.getSelected('instance', 'instance-class');
      await this.fetchPrices();
    }

    const instancePrice = this.calculatePrice('instance', this.retrieveInstanceRunningTime());
    const storagePrice = this.calculatePrice('storage', this.retrieveStorageAmount());
    const baselineIoPrice = this.calculatePrice('io', this.retrieveBaselineIos());
    const peakIoPrice = this.calculatePrice('io', this.retrievePeakIos());
    const backupStoragePrice = this.calculatePrice('backup', this.retrieveBackupStorage());

    this.updatePrices([instancePrice, storagePrice + baselineIoPrice + peakIoPrice, backupStoragePrice]);
  }

  private retrieveInstanceRunningTime(): number {
    // Instances * (Hours per Month)
    return (this.getSelected('instance', 'nodes') as number) * HOURS_PER_MONTH;
  }

  private retrieveStorageAmount(): number {
    return this.getSelected(storageAmountIds[0], storageAmountIds[1]) as number;
  }

  private retrieveBaselineIos(): number {
    const peakDuration = this.getSelected(peakIoDurationIds[0], peakIoDurationIds[1]) as number;
    const baselineHoursPerMonth = HOURS_PER_MONTH - peakDuration;
    const baselineIos = this.getSelected(baselineIoIds[0], baselineIoIds[1]) as number;
    return baselineHoursPerMonth * baselineIos;
  }

  private retrievePeakIos(): number {
    const peakIos = this.getSelected(peakIoIds[0], peakIoIds[1]) as number;
    const peakDuration = this.getSelected(peakIoDurationIds[0], peakIoDurationIds[1]) as number;
    return peakIos * peakDuration;
  }

  private retrieveBackupStorage(): number {
    const [sectionId, inputId] = backupStorageIds;
    return this.getSelected(sectionId, inputId) as number;
  }
}
