import { Injectable } from '@angular/core';
import {
  CostExplorerClient,
  GetCostAndUsageCommand,
  GetCostAndUsageCommandInput,
  GetCostAndUsageCommandOutput,
  GetCostForecastCommand,
  GetCostForecastCommandInput,
  GetCostForecastCommandOutput,
  Granularity,
} from '@aws-sdk/client-cost-explorer';
import { Expression } from '@aws-sdk/client-cost-explorer/dist-types/models/models_0';

import { AwsProviderProjectSettings, AwsProviderProjectTags, CostElement } from '../types';
import { DefaultService } from './default.service';

@Injectable({
  providedIn: 'root',
})
export class AwsService extends DefaultService {
  client!: CostExplorerClient;
  // client!: CostAndUsageReportServiceClient;
  filter?: Expression;

  private static buildTimePeriod(startDate: Date, endDate: Date, granularity: Granularity): { Start: string; End: string } {
    const start = AwsService.buildDateString(startDate, granularity);
    const end = AwsService.buildDateString(endDate, granularity);
    return {
      Start: start,
      End: end,
    };
  }

  private static buildDateString(date: Date, granularity: Granularity): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    let dateString = `${year}-${month}-${day}`;
    if (granularity === 'HOURLY') {
      const hour = ('0' + date.getHours()).slice(-2);
      dateString += `T${hour}:00:00Z`;
    }
    return dateString;
  }

  registerAccount(settings: AwsProviderProjectSettings): void {
    // TODO: Check for value and set for correct id
    // TODO: Error handling
    this.client = new CostExplorerClient({
      credentials: {
        accessKeyId: settings.accountKey as string,
        secretAccessKey: settings.accountSecret as string,
      },
      region: 'us-east-1',
    });
  }

  setAndFilter(tags: Array<AwsProviderProjectTags>): void {
    if (tags.length === 0) {
      return;
    }
    this.filter = this.getAndFilterExpression(tags);
  }

  getAndFilterExpression(tags: Array<AwsProviderProjectTags>): Expression {
    if (tags.length > 1) {
      const andExpression: Expression = { And: [] };
      tags.forEach((tag) => {
        andExpression.And?.push({
          Tags: {
            Key: tag.key,
            Values: [tag.value],
          },
        });
      });
      return andExpression;
    } else if (tags.length === 1) {
      return {
        Tags: {
          Key: tags[0].key,
          Values: [tags[0].value],
        },
      };
    }
    return {};
  }

  setOrFilter(tagsCollection: Array<Array<AwsProviderProjectTags>>): void {
    const orExpression: Expression = { Or: [] };
    tagsCollection.forEach((tags) => {
      orExpression.Or?.push(this.getAndFilterExpression(tags));
    });
  }

  async calculateCosts(startDate: Date, endDate: Date, granularity: Granularity): Promise<Array<CostElement>> {
    const costArray: Array<CostElement> = [];
    if (endDate.getTime() > new Date().getTime()) {
      const historicParams = this.buildHistoricParams(startDate, new Date(), granularity);
      const estimatedParams = this.buildEstimatedParams(new Date(), endDate, granularity);
      const historicCommand = new GetCostAndUsageCommand(historicParams);
      const estimatedCommand = new GetCostForecastCommand(estimatedParams);
      try {
        const [historicData, estimatedData]: [GetCostAndUsageCommandOutput, GetCostForecastCommandOutput] = await Promise.all([
          this.client.send(historicCommand),
          this.client.send(estimatedCommand),
        ]);
        historicData.ResultsByTime = historicData.ResultsByTime?.slice(0, -1);
        this.convertHistoricCosts(costArray, historicData, granularity);
        this.convertEstimatedCosts(costArray, estimatedData, granularity);
      } catch (error) {
        throw new Error(error as string);
        // error handling.
      }
    } else {
      const historicParams = this.buildHistoricParams(startDate, endDate, granularity);
      const historicCommand = new GetCostAndUsageCommand(historicParams);
      try {
        const historicData: GetCostAndUsageCommandOutput = await this.client.send(historicCommand);
        this.convertHistoricCosts(costArray, historicData, granularity);
      } catch (error) {
        throw new Error(error as string);
        // error handling.
      }
    }
    return costArray;
  }

  async calculateHistoricCosts(startDate: Date, endDate: Date, granularity: Granularity): Promise<GetCostAndUsageCommandOutput> {
    const historicParams = this.buildHistoricParams(startDate, endDate, granularity);
    const command = new GetCostAndUsageCommand(historicParams);
    try {
      return await this.client.send(command);
      // process data.
    } catch (error) {
      throw new Error(error as string);
      // error handling.
    }
  }

  async calculateEstimatedCosts(
    startDate: Date,
    endDate: Date,
    granularity: Granularity,
  ): Promise<GetCostForecastCommandOutput | undefined> {
    const estimatedParams = this.buildEstimatedParams(startDate, endDate, granularity);
    const command = new GetCostForecastCommand(estimatedParams);
    try {
      return await this.client.send(command);
      // process data.
    } catch (error) {
      return undefined;
      // throw new Error(error as string);
      // error handling.
    }
  }

  buildHistoricParams(startDate: Date, endDate: Date, granularity: Granularity): GetCostAndUsageCommandInput {
    return {
      TimePeriod: AwsService.buildTimePeriod(startDate, endDate, granularity),
      Granularity: granularity,
      Metrics: ['UnblendedCost'],
      Filter: this.filter,
    };
  }

  buildEstimatedParams(startDate: Date, endDate: Date, granularity: Granularity): GetCostForecastCommandInput {
    return {
      TimePeriod: AwsService.buildTimePeriod(startDate, endDate, granularity),
      Granularity: granularity,
      Metric: 'BLENDED_COST',
      Filter: this.filter,
    };
  }

  private convertHistoricCosts(
    costArray: Array<CostElement>,
    historicCosts: GetCostAndUsageCommandOutput,
    granularity: Granularity,
  ): Array<CostElement> {
    if (!historicCosts || !historicCosts.ResultsByTime) {
      return costArray;
    }
    historicCosts.ResultsByTime.forEach((result) => {
      if (
        !result.TimePeriod ||
        !result.TimePeriod.Start ||
        !result.Total ||
        !result.Total['UnblendedCost'] ||
        !result.Total['UnblendedCost'].Amount
      ) {
        return;
      }
      costArray.push({
        start: new Date(result.TimePeriod.Start as string),
        end: new Date(result.TimePeriod.End as string),
        estimated: false,
        startTime: new Date(result.TimePeriod.Start as string).getTime(),
        costs: result.Total['UnblendedCost'].Amount as unknown as number,
        type: granularity,
      });
    });
    return costArray;
  }

  private convertEstimatedCosts(
    costArray: Array<CostElement>,
    estimatedCosts: GetCostForecastCommandOutput,
    granularity: Granularity,
  ): Array<CostElement> {
    if (!estimatedCosts || !estimatedCosts.ForecastResultsByTime) {
      return costArray;
    }
    estimatedCosts.ForecastResultsByTime.forEach((result) => {
      if (!result.TimePeriod || !result.TimePeriod.Start || !result.MeanValue) {
        return;
      }
      costArray.push({
        start: new Date(result.TimePeriod.Start as string),
        end: new Date(result.TimePeriod.End as string),
        estimated: true,
        startTime: new Date(result.TimePeriod.Start as string).getTime(),
        costs: result.MeanValue as unknown as number,
        type: granularity,
      });
    });
    return costArray;
  }
}
