import { CloudServiceSection, UNIT_AMOUNT_PER_HOUR, UNIT_SIZE_AS_GB } from '@cloud-cost-calculation-tool/base-cloud-services';

export const storageAmountIds = ['database-storage', 'storage-amount'];
export const baselineIoIds = ['database-storage', 'baseline-io-rate'];
export const peakIoIds = ['database-storage', 'peak-io-rate'];
export const peakIoDurationIds = ['database-storage', 'duration-of-peak-io-activity'];
export const backupStorageIds = ['backup-storage', 'storage-amount'];

export const DEFAULT_DATABASE_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'Database Storage',
    id: 'database-storage',
    calculation: true,
    price: 0,
    info: 'Storage consumed by your Amazon Aurora database is billed in per GB-month increments and IOs consumed are billed in per million request increments.',
    input: [
      {
        name: 'Storage Amount',
        id: 'storage-amount',
        description: 'Total Storage Size of the Aurora Cluster',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 100000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
      {
        name: 'Baseline IO rate',
        id: 'baseline-io-rate',
        description: 'Enter the number of IOs per second that your workload consumes during off-peak periods.',
        type: 'number',
        selected: 1,
        unitSelected: UNIT_AMOUNT_PER_HOUR[0].value,
        numberSelected: 1,
        options: {
          min: 0.0166,
          max: 1000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_HOUR,
      },
      {
        name: 'Peak IO rate',
        id: 'peak-io-rate',
        description: 'Enter the maximum number of IOs per second your workload consumes during peak periods.',
        type: 'number',
        selected: 1,
        unitSelected: UNIT_AMOUNT_PER_HOUR[0].value,
        numberSelected: 1,
        options: {
          min: 0.0166,
          max: 1000000000,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_HOUR,
      },
      {
        name: 'Duration of peak IO activity',
        id: 'duration-of-peak-io-activity',
        description:
          'Enter the number of hours per month when your cluster or instance workload operates at peak. (Hours per Month)',
        type: 'number',
        selected: 1,
        unitSelected: 1,
        numberSelected: 1,
        options: {
          min: 0,
          max: 730,
          step: 1,
        },
      },
    ],
  },
  {
    name: 'Backup Storage',
    id: 'backup-storage',
    calculation: true,
    price: 0,
    info: "Backup storage for Amazon Aurora is the storage associated with your automated database backups and any customer-initiated DB cluster snapshots.There is no additional charge for backup storage of up to 100% of your total Aurora database storage for each Aurora DB cluster. There is also no additional charge for backup storage if your backup retention period is 1 day and you don't have any snapshots beyond the retention period.",
    input: [
      {
        name: 'Additional backup storage',
        id: 'storage-amount',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB[0].value,
        numberSelected: 0,
        options: {
          min: 0,
          max: 1000000000000,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB,
      },
    ],
  },
];
