export const SUPPORTED_FILTER_KEYS: Array<string> = [
  'armRegionName',
  'location',
  'meterId',
  'meterName',
  'productId',
  'skuId',
  'productName',
  'skuName',
  'serviceName',
  'serviceId',
  'serviceFamily',
  'priceType',
  'armSkuName',
];

export const PRICE_TYPES: Array<string> = ['DevTestConsumption', 'Consumption', 'Reservation'];

export const DEFAULT_CURRENCY_CODE = 'USD';

export interface IStringKeyObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IAzurePrice {
  tiers: Array<IAzurePriceTier>;
}

export interface IAzurePriceTier {
  unitPrice: number;
  startRange: number;
  endRange: number;
}

export interface IRetailPrice {
  location?: string;
  armRegionName?: string;
  serviceFamily?: string;
  serviceName?: string;
  serviceId?: string;
  productName?: string;
  productId?: string;
  skuName?: string;
  armSkuName?: string;
  skuId?: string;
  meterName?: string;
  meterId?: string;
  type?: 'DevTestConsumption' | 'Consumption' | 'Reservation';
  reservationTerm?: string;
  currencyCode?: string;
  tierMinimumUnits?: number;
  retailPrice?: number;
  unitPrice?: number;
  unitOfMeasure?: string;
  effectiveStartDate?: string;
  isPrimaryMeterRegion?: boolean;
}

export interface IAPIResponse {
  BillingCurrency: string;
  CustomerEntityId: string;
  CustomerEntityType: string;
  Items: Array<IRetailPrice>;
  NextPageLink: string | null;
  Count: number;
}

export interface IRetailPriceFilter extends IStringKeyObject {
  armRegionName?: string;
  location?: string;
  meterId?: string;
  meterName?: string;
  productId?: string;
  skuId?: string;
  productName?: string;
  skuName?: string;
  serviceName?: string;
  serviceId?: string;
  serviceFamily?: string;
  priceType?: 'DevTestConsumption' | 'Consumption' | 'Reservation';
  armSkuName?: string;
}
