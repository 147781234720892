import { Filter } from '@aws-sdk/client-pricing';
import { HOURS_PER_MONTH } from '@cloud-cost-calculation-tool/base-cloud-services';

import { DefaultAwsCloudService } from '../default-aws-service.class';
import {
  backupStorageIds,
  baselineIoIds,
  peakIoDurationIds,
  peakIoIds,
  storageAmountIds,
} from '../service-configurations/default-database.configuration';

export class AuroraServerlessService extends DefaultAwsCloudService {
  private selectedEngine = this.getSelected('instance', 'databaseType');
  private selectedType = this.getSelected('instance', 'version');

  get priceFetchingConfigurations(): { [key: string]: { awsServiceCode: string; filter: Array<Filter> } } {
    return {
      compute: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          {
            Field: 'databaseEngine',
            Type: 'TERM_MATCH',
            Value: 'Aurora ' + this.getSelected('instance', 'databaseType'),
          },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: this.getSelected('instance', 'version') as string },
        ],
      },
      storage: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          { Field: 'databaseEngine', Type: 'TERM_MATCH', Value: 'Any' },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:StorageUsage' },
        ],
      },
      io: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          { Field: 'databaseEngine', Type: 'TERM_MATCH', Value: 'Any' },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:StorageIOUsage' },
        ],
      },
      backup: {
        awsServiceCode: 'AmazonRDS',
        filter: [
          this.LOCATION_FILTER,
          {
            Field: 'databaseEngine',
            Type: 'TERM_MATCH',
            Value: 'Aurora ' + this.getSelected('instance', 'databaseType'),
          },
          { Field: 'usagetype', Type: 'TERM_MATCH', Value: 'Aurora:BackupUsage' },
        ],
      },
    };
  }

  protected async calculatePrices(): Promise<void> {
    if (
      this.selectedEngine !== this.getSelected('instance', 'databaseType') ||
      this.selectedType !== this.getSelected('instance', 'version')
    ) {
      this.selectedEngine = this.getSelected('instance', 'databaseType');
      this.selectedType = this.getSelected('instance', 'version');
      await this.fetchPrices();
    }
    const computePrice = this.calculatePrice('compute', this.retrieveACUs());
    const storagePrice = this.calculatePrice('storage', this.retrieveStorageAmount());
    const baselineIoPrice = this.calculatePrice('io', this.retrieveBaselineIos());
    const peakIoPrice = this.calculatePrice('io', this.retrievePeakIos());
    const backupStoragePrice = this.calculatePrice('backup', this.retrieveBackupStorage());

    this.updatePrices([computePrice, storagePrice + baselineIoPrice + peakIoPrice, backupStoragePrice]);
  }

  private retrieveACUs(): number {
    return this.getSelected('instance', 'aurora-capacity-units') as number;
  }

  private retrieveStorageAmount(): number {
    return this.getSelected(storageAmountIds[0], storageAmountIds[1]) as number;
  }

  private retrieveBaselineIos(): number {
    const peakDuration = this.getSelected(peakIoDurationIds[0], peakIoDurationIds[1]) as number;
    const baselineHoursPerMonth = HOURS_PER_MONTH - peakDuration;
    const baselineIos = this.getSelected(baselineIoIds[0], baselineIoIds[1]) as number;
    return baselineHoursPerMonth * baselineIos;
  }

  private retrievePeakIos(): number {
    const peakIos = this.getSelected(peakIoIds[0], peakIoIds[1]) as number;
    const peakDuration = this.getSelected(peakIoDurationIds[0], peakIoDurationIds[1]) as number;
    return peakIos * peakDuration;
  }

  private retrieveBackupStorage(): number {
    const [sectionId, inputId] = backupStorageIds;
    return this.getSelected(sectionId, inputId) as number;
  }
}
