import { DefaultSickCloudService } from '../default-sick-service.class';

export class DStudioService extends DefaultSickCloudService {
  get priceFetchingConfigurations(): { [key: string]: string } {
    return {};
  }

  async calculatePrices(): Promise<void> {
    const price = 0;
    this.updatePrices([price]);
  }
}
