import {
  CloudServiceSection,
  UNIT_AMOUNT_PER_MONTH,
  UNIT_SIZE_AS_GB_PER_MONTH,
} from '@cloud-cost-calculation-tool/base-cloud-services';

export const CLOUD_FRONT_CONFIGURATION: Array<CloudServiceSection> = [
  {
    name: 'CloudFront Edge Location',
    id: 'cloud-front-edge-location',
    calculation: true,
    price: 0,
    info: 'Amazon CloudFront automatically maps network conditions and intelligently routes your users to the ideal edge location to minimize end-user latency when serving your traffic to the internet.',
    input: [
      {
        name: 'Data transfer out to internet',
        description: 'The first 1,024 GB/month are free. Please manually exclude these from your calculations.',
        id: 'out-internet',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
      {
        name: 'Data transfer out to origin',
        description: 'Data Transfer out of Amazon CloudFront to your origin server, such as POST and PUT requests',
        id: 'out-origin',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_SIZE_AS_GB_PER_MONTH[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_SIZE_AS_GB_PER_MONTH,
      },
      {
        name: 'Number of requests (HTTPS)',
        description:
          'As an example, for an average object size of 200KB, 1GB of data would mean 5000 requests. The first 10 million requests/month are free. Please manually exclude these from your calculations.',
        id: 'requests',
        type: 'number',
        selected: 0,
        unitSelected: UNIT_AMOUNT_PER_MONTH[0].value,
        numberSelected: 0,

        options: {
          min: 0,
          step: 1,
        },
        units: UNIT_AMOUNT_PER_MONTH,
      },
    ],
  },
];

export const CLOUD_FRONT_REGIONS: Array<{
  name: string;
  value: string;
}> = [
  {
    name: 'United States',
    value: 'US-',
  },
  {
    name: 'Europe',
    value: 'EU-',
  },
  {
    name: 'Asia Pacific',
    value: 'AP-',
  },
  {
    name: 'South America',
    value: 'SA-',
  },
  {
    name: 'Canada',
    value: 'CA-',
  },
  {
    name: 'Japan',
    value: 'JP-',
  },
  {
    name: 'Australia',
    value: 'AU-',
  },
  {
    name: 'India',
    value: 'IN-',
  },
  {
    name: 'Middle East',
    value: 'ME-',
  },
  {
    name: 'South Africa',
    value: 'ZA-',
  },
];
